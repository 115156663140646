import React, { useRef, useEffect } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import colors from '@monorepo-zazuu/shared/constants/colors';
import { Container } from './styles';

interface IProps extends SelectProps<OptionTypeBase> {
  name: string;
  containerStyle?: object;
}

const SelectRef: React.FC<IProps> = ({ name, containerStyle, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container style={containerStyle}>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
        styles={{
          menu: (styles) => ({
            ...styles, zIndex: 999, color: colors.gray.dark02, background: colors.gray.light02,
          }),
          option: (styles) => ({ ...styles, background: colors.gray.light02, borderRadius: '5rem' }),
        }}
      />
    </Container>
  );
};

export default SelectRef;
