import colors from './constants/colors';
import AppointmentStatusEnum from './enums/AppointmentStatusEnum';

const convertStatusAppointment = (status: number) => {
  const status_app = {
    color: colors.gray.dark03,
    label: status !== AppointmentStatusEnum.Pago ? (AppointmentStatusEnum[status] || 'Erro') : 'Na fila',
  };

  if (status === 3) {
    status_app.color = colors.suport.blue;
  }

  if (status === 4) {
    status_app.color = colors.secondary.default;
  }

  if (status === 5) {
    status_app.color = colors.secondary.default;
  }

  if (status === 6) {
    status_app.color = colors.secondary.default;
  }

  if (status === 7) {
    status_app.color = colors.secondary.default;
  }

  if (status === AppointmentStatusEnum.Cancelado) {
    status_app.color = colors.suport.alert;
  }

  return status_app;
};

export default convertStatusAppointment;
