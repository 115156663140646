import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const InputImageContainer = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;

  & > input {
    display: none;
    cursor: pointer;
  }

  img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -1rem;
    bottom: -1rem;
    background: ${colors.primary.default};
    padding: 1rem;
    border-radius: 50%;

    & > svg {
      color: ${colors.gray.white};
      margin: auto;
      font-size: 2rem;
    }
  }
`;
