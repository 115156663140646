import styled from 'styled-components';
import CsvDownload from 'react-json-to-csv';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  overflow: unset;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CsvDownloadStyled = styled(CsvDownload)`
  display: flex;
  width: 170px;
  padding: 1rem 2rem;
  color: ${colors.gray.white};
  background: ${colors.secondary.default};
  margin-top: 2rem;
  font-weight: bold;
  text-align: center;
`;

export const ProfessionalsList = styled.div`
  display: block;
`;

export const Card = styled.button<{selected: boolean}>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  color: ${colors.gray.dark01};
  margin-bottom: 8px;

  & > div {
    border: 1px solid ${props => props.selected ? colors.secondary.default : colors.suport.alert};
    background-color: ${props => props.selected ? colors.secondary.default : colors.suport.alert};
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 4px;
  }

  :hover {
    background-color: ${colors.secondary.default};
    color: ${colors.gray.white};
    transition: all 0.2s ease;

    & > div {
      border: 1px solid ${colors.gray.white};
      transition: all 0.2s ease;
    }
  }
`;

export const Groups = styled.div`
  display: block;
  margin-top: 32px;
`;

export const Group = styled.button`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 16px;

  :hover {
    & > div {
      background: ${colors.secondary.default};
      transition: all 0.2s ease;
      color: ${colors.gray.white};
    }
  }
`;

export const CardAppointment = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 10;
  background-color: ${colors.gray.white};
  padding: 8px 16px;
  border-radius: 8px;
  color: ${colors.gray.dark01};
`;
