import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/Auth'
import { ButtonOption, Container } from './styles'

const Dashboard: React.FC = () => {
  const history = useHistory()
  const { user } = useAuth()

  return (
    <Container>
      {!!user.roles?.every(r => r.name !== 'promotor') && (
        <ButtonOption onClick={() => history.push('/reports')}>
          Relatórios
        </ButtonOption>
      )}
      {!!user.roles?.find(r => r.name === 'admin') && (
        <ButtonOption onClick={() => history.push('/calendar')}>
          Controle de Agenda
        </ButtonOption>
      )}
      {!!user.roles?.every(r => r.name !== 'promotor') && (
        <ButtonOption onClick={() => history.push('/trucks-panel')}>
          Painel de Trucks
        </ButtonOption>
      )}
      {!!user.roles?.every(r => r.name !== 'promotor') && (
        <ButtonOption onClick={() => history.push('/financial-reports')}>
          Relatório financeiro
        </ButtonOption>
      )}
    </Container>
  )
}

export default Dashboard
