import React from 'react';
// import { GoPrimitiveDot } from 'react-icons/go';

import colors from '@monorepo-zazuu/shared/constants/colors';

import Text from '../../../../components/Text';

import * as Styles from './styles';

interface IProps {
  appointmentStatus: {
    name: string;
    nameTranslate: string;
    value?: string;
  }[];
}

const ProgressStep: React.FC<IProps> = ({ appointmentStatus }) => (
  <Styles.Content>
    {appointmentStatus.map((status, index) => (
      <>
        <Styles.StepperDotDiv>
          <Styles.StepperDot
            style={{
              backgroundColor: status?.value ? colors.suport.green : colors.gray.light01,
              borderColor: appointmentStatus.filter(
                (status) => status.value !== undefined,
              ).length === index + 1 ? colors.suport.blue : colors.gray.white,
            }}
          />

          {/* <GoPrimitiveDot
            size={40}
            color={status?.value ? colors.suport.green : colors.gray.light01}
          /> */}

          <Styles.TextDiv>
            <Text
              test={status?.value ? status?.nameTranslate : ''}
              color={colors.gray.dark02}
              weight="600"
              marginTop={4}
              marginBottom={4}
              size={10}
            />

            <Text
              test={status?.value || ''}
              color={colors.gray.dark02}
              weight="600"
              size={12}
            />
          </Styles.TextDiv>
        </Styles.StepperDotDiv>

        {index < 4 && (
          <Styles.StepperLine
            style={{
              backgroundColor: appointmentStatus.filter(
                (status) => status.value !== undefined,
              ).length > index + 1 ? colors.suport.green : colors.gray.dark03,
            }}
          />
        )}
      </>
    ))}
  </Styles.Content>
);

export default ProgressStep;
