import styled, { css } from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 2rem 4rem 4rem 4rem;

  @media (max-width: 500px) {
    margin: 2rem 0 calc((100vw / 5) + 4rem) 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  place-content: center;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;

  background: ${colors.gray.white};
  border-radius: 3rem;
  padding: 2rem 4rem;
`;

export const Title = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 3.7rem;
  color: ${colors.secondary.default};
`;

export const Subtitle = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 2.8rem;
  color: ${colors.gray.dark02};

  margin-top: 3rem;
  margin-bottom: 2.4rem;
`;

export const Options = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  background: ${colors.gray.light02};
  border-radius: 3rem;
`;

export const OptionButton = styled.button<{ selected: boolean }>`
  display: flex;
  width: 100%;
  place-content: center;
  font-family: 'Open Sans';
  font-size: 2.1rem;
  transition: all 0.5s ease;

  padding: 1rem;
  border-radius: 3rem;

  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  z-index: ${(props) => (props.selected ? 1 : 0)};
  color: ${(props) => (props.selected ? colors.primary.default : colors.gray.dark02)};
  background: ${(props) => (props.selected ? colors.gray.light02 : colors.gray.light02)};
  border: 2px solid
    ${(props) => (props.selected ? colors.primary.default : colors.gray.light02)};

  & + button {
    margin-left: -5rem;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Button = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 1rem);
  margin-right: 1rem;
  height: 6rem;
  background: ${colors.gray.light02};
  border: 2px solid ${colors.gray.light02};
  color: ${colors.gray.dark01};

  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 120%;

  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 3rem;

  transition: all 0.5s ease;

  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  color: ${(props) => (props.selected ? colors.primary.default : colors.gray.dark02)};
  background: ${(props) => (props.selected ? colors.gray.light02 : colors.gray.light02)};
  border: 2px solid
    ${(props) => (props.selected ? colors.primary.default : colors.gray.light02)};

  :hover {
    background: ${colors.gray.light02};
    border: 2px solid ${colors.primary.default};
    color: ${colors.primary.default};
  }
`;

export const ButtonCalculate = styled.button`
  display: flex;
  place-content: center;
  width: 100%;
  background: ${colors.gray.light02};
  border: none;
  padding: 1.5rem 0;
  border-radius: 3rem;
  margin-top: 2rem;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.dark01};

  :hover {
    background: ${colors.secondary.default};
    color: ${colors.gray.white};
  }
`;

export const Right = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 4rem;

  position: relative;
`;

export const RightImageContainer = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  margin-top: auto;

  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const RightContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  place-content: center;

  width: 100%;
  margin: 0 auto 4rem auto;

  @media (max-width: 860px) {
    grid-template-columns: repeat(auto-fit, 300px);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.gray.white};
  position: relative;

  -webkit-animation: fadein 0.9s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.9s; /* Firefox < 16 */
  -ms-animation: fadein 0.9s; /* Internet Explorer */
  -o-animation: fadein 0.9s; /* Opera < 12.1 */
  animation: fadein 0.9s;

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  text-align: center;

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  padding-bottom: 2rem;
  text-align: left;


  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const Species = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    display: flex;
    place-content: center;
    background-color: ${colors.secondary.default};

    & > svg {
      font-size: 1.6rem;
      color: ${colors.gray.white};
      margin: 0.5rem;
    }
  }

  div + div {
    margin-left: 0.5rem;
  }
`;

export const CardTitle = styled.p<{ background: boolean }>`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.4rem;
  color: ${(props) => (props.background ? colors.gray.white : colors.secondary.default)};
  background-color: ${(props) => (props.background ? colors.secondary.default : 'none')};
  width: 100%;
  text-align:center;

  padding: 1rem 2rem;
`;

export const CardSubtitle = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2rem;
  color: ${colors.gray.dark01};

  padding: 0 2rem;

  margin-top: 1rem;
`;

export const CardComplement = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.6rem;
  color: ${colors.gray.dark01};

  padding: 0 2rem;

  margin: 1rem 0;

  & > span {
    display: block;
  }
`;

export const Text = styled.p`
 display:flex;
  flex-direction: row;
  align-items:center;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.4rem;
  color: ${colors.gray.dark01};

  padding-top: 2rem;
  padding-bottom: 2rem;

  & > p {
    text-decoration: line-through;
  }

  p + p {
    text-decoration: none;
    font-size: 1.8rem;
    color: ${colors.secondary.default};
  }
`;

export const ButtonBuy = styled.button<{ disabled?: boolean }>`
  display: flex;
  width: calc(100% - 4rem);
  place-content: center;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;

  border-radius: 3rem;
  padding: 1rem;

  color: ${(props) => (props.disabled ? colors.secondary.default : colors.gray.white)};
  background: ${(props) => (props.disabled ? 'none' : colors.primary.default)};

  transition: all 0.5s ease;

  ${(props) => !props.disabled
    && css`
      :hover {
        color: ${colors.gray.white};
        background: ${colors.primary.default};
      }
    `};

  @media (max-width: 860px) {
    padding: 2rem;
  }
`;

export const ButtonPlus = styled.button`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;
  color: ${colors.primary.default};
  margin-top: 1rem;
`;

export const Off = styled.div`
  position: absolute;
  top: 2rem;
  right: -2rem;
  padding: 1rem 3rem;
  background: ${colors.suport.warning};
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.8rem;
  color: ${colors.gray.white};

  transform: rotate(45deg);
  border-radius: 3rem;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 4rem;
`;

export const CategoryButton = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${colors.gray.white};
  margin-right: 1rem;
  padding: 1rem 2rem;
  align-items: center;
  border-radius: 3rem;

  transition: background 0.6s ease;

  & > svg {
    color: ${colors.gray.dark01};
    font-size: 2.4rem;
    margin-right: 1rem;

    transition: color 0.3s ease;
  }

  & > p {
    color: ${colors.gray.dark01};
    font-size: 2.4rem;

    transition: color 0.3s ease;
  }

  :hover {
    background-color: ${colors.primary.default};

    & > svg {
      color: ${colors.gray.white};
    }

    & > p {
      color: ${colors.gray.white};
    }
  }

  ${(props) => props.selected && css`
    background-color: ${colors.primary.default};

    & > svg {
      color: ${colors.gray.white};
    }

    & > p {
      color: ${colors.gray.white};
    }
  `};
`;
