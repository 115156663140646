import IProfessional from '@monorepo-zazuu/shared/interfaces/IProfessional';
import React, { useCallback } from 'react';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import * as Styles from './styles';


interface IProps {
  visible: boolean;
  handleCloseModal: () => void;
  professionals: IProfessional[];
  handleAddProfessional: (professional: IProfessional) => void;
}

const ModalAddProfessional: React.FC<IProps> = ({visible, handleCloseModal, professionals, handleAddProfessional}) => {

  const handleProfessional = useCallback((professional: IProfessional) => {
    handleAddProfessional(professional)
    handleCloseModal()
  }, [handleAddProfessional, handleCloseModal])

  return (
    <Modal visible={visible} closeModal={handleCloseModal}>
      <Styles.Content>
        <Text
          test="Adicionar professional"
          size={24}
          align="center"
          weight="600"
        />
        {professionals.map(professional => (
          <button onClick={() => handleProfessional(professional)} key={professional.id}>
            <Text
              test={`${professional.user.name} - ${professional.user.roles.map(r => r.name)}`}
              size={18}
              align="center"
              weight="600"
            />
          </button>
        ))}
      </Styles.Content>
    </Modal>
  )
}

export default ModalAddProfessional;
