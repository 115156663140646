import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.button<{selected: boolean}>`
  background-color: ${(props) => (props.selected ? colors.primary.default : colors.gray.white)};
  color: ${(props) => (props.selected ? colors.gray.white : colors.primary.default)};
  border: 1px solid ${(props) => (props.selected ? colors.primary.default : colors.gray.light02)};
  display: flex;
  height: 4.8rem;
  min-width: 100px;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding: 0 2rem;
  font-family: 'Open Sans';
  font-size: 1.6rem;

  transition: all 0.3s ease;

  :hover {
    background-color: ${colors.primary.default};
    color: ${colors.gray.white};
  }

  @media(max-width: 500px){
    height: 6rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
