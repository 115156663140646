import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input + input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    -webkit-box-shadow: 0px 0px 14px 1px rgba(200, 200, 200, 0.4);
    -moz-box-shadow: 0px 0px 14px 1px rgba(200, 200, 200, 0.4);
    box-shadow: 0px 0px 14px 1px rgba(200, 200, 200, 0.4);
  }

  input:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  -webkit-appearance: none;
  border-radius: 3rem;
  cursor: pointer;

  background: ${(props) => (props.checked ? colors.secondary.default : colors.gray.white)};
  color: ${(props) => (props.checked ? colors.gray.white : colors.gray.dark01)};
  border: 2px solid
    ${(props) => (props.checked ? colors.secondary.default : colors.gray.light02)};
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};

  font-family: 'Open Sans';
  font-size: 1.6rem;
  place-content: center;

  @media (max-width: 500px) {
    height: 6rem;
  }
`;
