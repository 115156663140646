import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -5rem;
`;

export const StepperLine = styled.div`
  height: 3px;
  background-color: ${colors.gray.dark03};
  width: 20%;
  border-radius: 3px;
`;

export const StepperDot = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${colors.gray.light01};
  border-radius: 25px;
  border: 2px solid ${colors.gray.white};
`;

export const StepperDotDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6rem;
  height: 70px;
`;

export const TextDiv = styled.div`
  width: 60px;
`;
