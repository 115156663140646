import styled from 'styled-components';
import CsvDownload from 'react-json-to-csv';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 6.4rem);
  padding: 2rem;
  overflow-x: auto;

  @media (max-width: 500px) {
    overflow-x: unset;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
  margin-bottom: 2rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CsvDownloadStyled = styled(CsvDownload)`
  display: flex;
  width: 170px;
  padding: 1rem 2rem;
  color: ${colors.gray.white};
  background: ${colors.secondary.default};
  margin-top: 2rem;
  font-weight: bold;
  text-align: center;
`;

export const ContainerListCustomers = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;

  @media(max-width: 500px){
    width: calc(100vw - 4rem);
    overflow-x: auto;
  }
`;

export const Message = styled.p`
  display: flex;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  color: ${colors.gray.dark01};
  width: 100%;
  white-space: nowrap;
  text-align: center;
`;

export const ListOrders = styled.table`
  margin-top: 1rem;
  width: 100%;
`;

export const ListOrdersHeader = styled.tr`
  background: ${colors.secondary.default};
  color: ${colors.gray.white} !important;
`;

export const ListOrdersRow = styled.tr`
  background: ${colors.gray.white};

  :hover {
    background: ${colors.gray.light02};
  }
`;

export const TextHeader = styled.th`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;
  color: ${colors.gray.white};
  white-space: nowrap;
  padding: 1rem;
`;

export const Td = styled.td`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;
  color: ${colors.primary.default};

  white-space: nowrap;
  padding: 1rem;
`;
