import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('@zazuu:admin:user:token');
      localStorage.removeItem('@zazuu:admin:user');
      localStorage.removeItem('@zazuu:admin:customer');
      localStorage.removeItem('@zazuu:cart');

      toast('Sua sessao expirou', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'warning',
      });

      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default api;
