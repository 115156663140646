import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import {
  OptionTypeBase,
  Props as SelectProps
} from 'react-select';
import { ReactSelectStyled } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  style?: object;
}

const Select: React.FC<Props> = ({ name, style, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, option: OptionTypeBase) => {
        ref.state.value = option;
      }
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div style={style}>
    <ReactSelectStyled
      ref={selectRef}
      defaultValue={defaultValue}
      style={style}
      classNamePrefix="react-select"
      {...rest}
      />
    </div>
  );
};

export default Select;
