import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Modal = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 10;
  height: 100%;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray.white};
  border-radius: 3rem;
  margin: auto auto;
  padding: 4rem;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  overflow: auto;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2rem;
  margin: 0 auto;

  width: 50%;
`;

export const StepLine = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${colors.primary.default};
`;

export const Step = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  background: ${(props) => (props.selected ? colors.primary.default : colors.gray.white)};
  color: ${(props) => (props.selected ? colors.gray.white : colors.primary.default)};
  border: 1px solid ${colors.primary.default};
`;

export const Content = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.dark02};

  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 120%;
  color: ${colors.gray.dark02};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray.light02};
  border-radius: 3rem;
`;

export const OptionsButton = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-width: 70px;
  height: 4.8rem;

  border-radius: 3rem;

  background: ${(props) => (props.selected ? colors.gray.light02 : colors.gray.light02)};
  color: ${(props) => (props.selected ? colors.primary.default : colors.gray.dark02)};
  border: 2px solid
    ${(props) => (props.selected ? colors.primary.default : colors.gray.dark03)};
  z-index: ${(props) => (props.selected ? 1 : 0)};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};

  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;
  color: ${colors.gray.dark02};
  place-content: center;

  @media (max-width: 650px) {
    height: 4rem;
  }

  & > svg {
    font-size: 3rem;
    margin: 0 auto;
  }

  & + button {
    margin-left: -4rem !important;

    @media (max-width: 650px) {
      margin-left: -3.5rem !important;
    }
  }

  @media (max-width: 500px) {
    height: 6rem;
  }
`;

export const ListButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const ButtonItem = styled.button.attrs({ type: 'button' }) <{
  selected: boolean;
}>`
  width: calc(50% - 1rem);
  background: ${colors.gray.light02};
  border: 2px solid ${colors.gray.light02};
  color: ${colors.gray.dark01};

  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 120%;

  height: 4.8rem;
  border-radius: 3rem;
  margin: 0.5rem 0;
  margin-right: auto;

  transition: all 0.5s ease;

  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  color: ${(props) => (props.selected ? colors.primary.default : colors.gray.dark02)};
  background: ${(props) => (props.selected ? colors.gray.light02 : colors.gray.light02)};
  border: 2px solid
    ${(props) => (props.selected ? colors.primary.default : colors.gray.light02)};

  :hover {
    background: ${colors.gray.light02};
    border: 2px solid ${colors.primary.default};
    color: ${colors.primary.default};
  }

  @media (max-width: 650px) {
    height: 6rem;
  }
`;

export const Button = styled.button<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
  padding: 1.5rem;
  background: ${colors.secondary.default};
  color: ${colors.gray.white};
  border-radius: 4rem;
`;

export const TextError = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 120%;
  margin-top: 4rem;

  color: ${colors.suport.alert};
`;
