import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.secondary.default};
  width: 100%;
  text-align: center;
  padding: 5px;
  justify-content: center;
  align-items: center;

  & > a {
    width: 100%;
    padding-left: 4rem;
    padding-right: 2rem;
    text-decoration: none;
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 120%;
    color: ${colors.gray.white};
    word-wrap: wrap;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  width: 2rem;

  & > svg {
    color: ${colors.gray.white};
    font-size: 2rem;
  }

  :hover {
    svg {
      font-size: 2.2rem;
    }
  }
`;
