import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputImage = styled.button`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: initial;

  img {
    display: flex;
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: ${colors.gray.light02};
  }

  label {
    display: flex;
    width: 100%;
    justify-content: center;
    border: 0;
    cursor: pointer;

    background-color: ${colors.primary.light};
    height: 4.8rem;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      height: 6rem;
    }

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #312e38;
    }
    &:hover {
      background-color: ${colors.secondary.default};
    }
  }
`;
