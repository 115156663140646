import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReactSelectStyled = styled(ReactSelect)<{error: boolean}>`
  color: ${colors.gray.dark01};
  box-shadow: none;
  border-color: transparent;
  width: 100%;

  .react-select__option {
    background-color: ${colors.gray.white};
    :hover {
      background-color: ${colors.gray.light02};
    }
  }

  .react-select__option--is-selected {
    color: ${colors.secondary.default};
  }

  .react-select__control {
    height: 4.8rem;
    border-radius: 2.4rem;
    background-color: ${colors.gray.light02};
    font-size: 1.6rem;
    padding-left: 8px;

    ${(props) => props.error
    && css`
        border-color: ${colors.suport.alert};
    `};

    @media (max-width: 500px) {
      height: 6rem;
    }
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${colors.secondary.default};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }

  .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px ${colors.secondary.default};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }
`;
