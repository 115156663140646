import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { OptionTypeBase } from 'react-select';
import { Props as AsyncProps } from 'react-select/async';
import { SelectStyled } from './styles';

interface IProps extends AsyncProps<OptionTypeBase> {
  name: string;
  disabled: boolean;
  style?: object;
  onSelectItem?: (value: {value: string; label: string}) => void;
}

const AsyncSelect: React.FC<IProps> = ({ name, style, disabled, onSelectItem, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }

          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }
        if (!ref.select.state.value) {
          return '';
        }

        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div style={{...style, opacity: disabled ? 0.6 : 1}}>
      <SelectStyled
        isDisabled={disabled}
        cacheOptions
        defaultValue={defaultValue}
        ref={selectRef}
        onChange={(value: {value: string; label: string}) => onSelectItem && onSelectItem(value)}
        classNamePrefix="react-select"
        {...rest}
        />
    </div>
  );
};

export default AsyncSelect;
