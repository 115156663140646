import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  background: transparent;
  width: 100%;
  overflow: auto;
`;

export const TableStyled = styled.table`
  background: ${colors.gray.white};
  border: 3px solid ${colors.secondary.default};
  border-collapse: collapse;
  width: 100%;
`;

export const THead = styled.thead`
  background: ${colors.secondary.default};
  padding: 1rem;
`;

export const TH = styled.th`
  white-space: nowrap;
  padding: 5px;
  color: ${colors.gray.white};
`;

export const TR = styled.tr`
  border: 1px solid ${colors.secondary.default};
`;

export const TBody = styled.tbody`
  flex-direction: column;
`;

export const TD = styled.td`
  white-space: nowrap;
  background: ${colors.gray.white};
  padding: 5px;

  border: 1px solid ${colors.secondary.default};
  color: ${colors.gray.dark01};
  padding: 1rem;
`;

export const ActionButton = styled.button``;
