import { gql } from '@apollo/client';
import colors from '@monorepo-zazuu/shared/constants/colors';
import ICategory from '@monorepo-zazuu/shared/interfaces/ICategory';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ISelectValue from '@monorepo-zazuu/shared/interfaces/ISelectValue';
import IRole from '@monorepo-zazuu/shared/interfaces/IRole';
import Button from '../../components/Button';
import Text from '../../components/web/Text';
import client from '../../services/api-graphql';
import ModalAddCategory from './ModalAddCategory';
import ModalAddService from './ModalAddService';
import * as Styles from './styles';
import TableServices from './TableServices';

const ServicesManager: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [species, setSpecies] = useState<ISelectValue[]>([]);
  const [roles, setRoles] = useState<ISelectValue[]>([]);
  const [categoriesSelect, setCategoriesSelect] = useState<{value: string; label: string}[]>([]);
  const [modalAddService, setModalAddService] = useState(false);
  const [modalAddCategory, setModalAddCategory] = useState(false);
  const [categoryAndSubCategorySelect, setCategoryAndSubCategorySelect] = useState<{
    category: string,
    subcategory: string | undefined
  } | undefined>(undefined);

  const loadData = useCallback(async() => {
    try {
      const results = await client.query({
        query: gql`
          query {
            categories {
              id
              name
              type_layout
              services {
                id
                name
                category_id
                subcategory_id
                status
              }
              subcategories {
                id
                name
                type_layout
                services {
                  id
                  name
                  category_id
                  subcategory_id
                  status
                }
              }
            }
            species {
              id
              name
            }
            roles {
              id
              name
            }
          }
        `
      });
      setSpecies(results.data.species.map((specie: any) => ({value: specie.id, label: specie.name})));
      const categ = results.data.categories as ICategory[];
      setCategoriesSelect(categ.map(category => ({value: category.id, label: category.name})));
      setCategories(categ.map(category => ({...category, services: category.services.filter(service => !service.subcategory_id)})));
      setRoles(results.data.roles.map((role: IRole) => ({value: role.id, label: role.name})));
    } catch(error){
      toast(error?.networkError?.result?.errors[0]?.message || 'Erro recuperar servicos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, []);

  const afterAddCategory = async (category: any, isPrimary: boolean) => {
    if(isPrimary) {
      setCategories(old => ([
        ...old,
        category
      ]));
    } else {
      setCategories(old => old.map(categ => categ.id === category.id ? {...categ, subcategories: [...categ.subcategories, category]} : categ));
    }
    setModalAddCategory(false);
  }

  const handleAddNewService = useCallback((newService: any) => {
    setCategories(old => old.map(c => {
      if(c.id === newService.category_id) {
        if(newService?.subcategory_id) {
          return {
            ...c,
            subcategories: c.subcategories.map(s => s.id === newService.subcategory_id ? {...s, services: [...s.services, newService]} : s)
          }
        }
        return {...c, services: [...c.services, newService]}
      }
      return c;
    }));
    setModalAddService(false);
  }, []);

  useEffect(() => {
    loadData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles.Container>
      <Text
        test="Categorias"
        color={colors.primary.default}
        weight="600"
        align="left"
      />
      {categories.map(category => (
        <Styles.Column key={category.id}>
          <Styles.Block key={category.id}>
            <Styles.BlockHeader>
              <Text
                test={category.name}
                color={colors.gray.dark01}
                weight="600"
                align="left"
              />
              <Styles.BlockType>
                <Text
                  test="PRINCIPAL"
                  color={colors.gray.dark01}
                  align="left"
                />
              </Styles.BlockType>
            </Styles.BlockHeader>
            <TableServices services={category.services} type_layout={category.type_layout} handleEdit={() => {}} />
            <Button
              behavior="secondary"
              text="Adicionar item"
              onClick={() => {
                setCategoryAndSubCategorySelect({
                  category: category.id,
                  subcategory: undefined
                });
                setModalAddService(true);
              }}
              styleContainer={{marginLeft: 16, marginTop: 16}}
            />
          </Styles.Block>
          {category.subcategories.map(subcategory => (
            <Styles.Block key={subcategory.id}>
              <Styles.BlockHeader>
                <Text
                  test={`${category.name} > ${subcategory.name}`}
                  color={colors.gray.dark01}
                  weight="600"
                  align="left"
                />
                <Styles.BlockType>
                  <Text
                    test="SECUNDÁRIA"
                    color={colors.gray.dark01}
                    align="left"
                  />
                </Styles.BlockType>
              </Styles.BlockHeader>
              <TableServices services={subcategory.services} type_layout={subcategory.type_layout} handleEdit={() => {}} />
              <Button
                behavior="secondary"
                text="Adicionar item"
                onClick={() => {
                  setCategoryAndSubCategorySelect({
                    category: category.id,
                    subcategory: subcategory.id
                  });
                  setModalAddService(true);
                }}
                styleContainer={{marginLeft: 16, marginTop: 16}}
              />
            </Styles.Block>
          ))}
        </Styles.Column>
      ))}
      {modalAddService && !!categoryAndSubCategorySelect && <ModalAddService
        visible={modalAddService}
        closeModal={() => setModalAddService(false)}
        species={species}
        roles={roles}
        category_id={categoryAndSubCategorySelect.category}
        subcategory_id={categoryAndSubCategorySelect.subcategory}
        handleAddNewService={handleAddNewService}
      />}
      <ModalAddCategory
        visible={modalAddCategory}
        closeModal={() => setModalAddCategory(false)}
        afterAddCategory={afterAddCategory}
        categoriesToSelect={categoriesSelect}
      />
    </Styles.Container>
  );
}

export default ServicesManager;
