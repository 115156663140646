import React, {
  useCallback,
  useState
} from 'react';
import { InputHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { Container, IconButton } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: React.CSSProperties;
  icon?: React.ComponentType<IconBaseProps>;
  onclickIcon?: () => void;
  error?: boolean;
}

const Input: React.FC<IInputProps> = ({
  value,
  containerStyle,
  icon: Icon,
  onclickIcon,
  error = false,
  ...rest
}) => {
  const [isFocused, setIsFocus] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);
  }, []);

  return (
    <Container
      style={containerStyle}
      hasIcon={!Icon}
      isFocused={isFocused}
      isFilled={!!value}
      isErrored={error}
    >
      <input
        value={value}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        {...rest}
      />
      {Icon && (
        <IconButton type="button" onClick={() => onclickIcon && onclickIcon()}>
          <Icon />
        </IconButton>
      )}
    </Container>
  );
};

export default Input;
