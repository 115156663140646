import React, { TableHTMLAttributes, useMemo } from 'react';
import {
  ActionButton, Container, TableStyled, TBody, TD, TH, THead, TR,
} from './styles';

interface IPropsTable extends TableHTMLAttributes<HTMLTableElement> {
  data: object[];
  action?: (id: string | undefined) => void;
  removeProperties?: string[];
}

const Table: React.FC<IPropsTable> = ({
  data, action, removeProperties, ...rest
}) => {
  const headers = useMemo(() => (data.length > 0 ? Object.keys(data[0]) : [] as string[]), [data]);
  const content = useMemo(() => data, [data]);

  return (
    <Container {...rest}>
      <TableStyled>
        <THead>
          <TR>
            {action && <TH>action</TH>}
            {headers.map((header) => {
              if (removeProperties?.includes(header)) {
                return null;
              }
              return (<TH key={header}>{header}</TH>);
            })}
          </TR>
        </THead>
        <TBody>
          {content.map((item, index) => (
            <TR key={index}>
              {action && (
              <TD>
                <ActionButton onClick={() => {
                  const propertieId = Object.entries(item).find((it) => it[0] === 'id');
                  action(propertieId ? propertieId[1] : undefined);
                }}
                >
                  remove
                </ActionButton>
              </TD>
              )}
              {Object.entries(item).map((entrie, index) => {
                if (removeProperties?.includes(entrie[0])) {
                  return null;
                }
                return <TD key={index}>{entrie[1]}</TD>;
              })}
            </TR>
          ))}
        </TBody>
      </TableStyled>
    </Container>
  );
};

export default Table;
