import colors from '@monorepo-zazuu/shared/constants/colors';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  & > button {
    padding: 8px;
    background: ${colors.gray.white};
    margin-top: 8px;
    border: 1px solid ${colors.secondary.default};

    & > p {
      color: ${colors.gray.dark01};
    }

    :hover {
      transition: all 0.2s ease;
      background: ${colors.secondary.default};

      & > p {
        color: ${colors.gray.white};
      }
    }
  }
`;
