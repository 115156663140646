import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { FiCamera } from 'react-icons/fi';
import { InputImageContainer } from './styles';
import dogDefault from '@monorepo-zazuu/shared/assets/images/dog-default.png';

import resizeImage from '../../utils/resizeImage';
import blobToFile from '../../utils/blobToFile';

interface Props {
  name: string;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;
const ImageInput: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [fileLoad, setFileLoad] = useState(defaultValue);

  const handlePreview = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        setPreview(null);
      }

      const image = file ? await resizeImage(file) : undefined;

      if (image instanceof Blob && file) {
        const fileConverted = blobToFile(image, file.name);
        const previewURL = URL.createObjectURL(fileConverted);
        setPreview(previewURL);
        setFileLoad(fileConverted);
      }
    },
    [],
  );
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return fileLoad;
      },
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField, preview, fileLoad]);

  return (
    <InputImageContainer htmlFor="avatar">
      {preview ? (
        <img src={preview} alt="Preview" width="100" />
      ) : (
          <img src={dogDefault} alt="https://www.instagram.com/soyandrelo/" width="100" />
        )}
      <input
        type="file"
        id="avatar"
        ref={inputRef}
        onChange={handlePreview}
        {...rest}
      />
      <div>
        <FiCamera />
      </div>
    </InputImageContainer>
  );
};
export default ImageInput;
