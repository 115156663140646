enum TypePaymentEnum {
  card = 1,
  link = 2,
  pix = 3,
  transferencia = 4,
  dinheiro = 5,
  newlink = 6,
  linkavulso = 7,
  definir = 0,
}

export default TypePaymentEnum;
