import styled from 'styled-components';

import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem;
`;

export const LoadingDiv = styled.div`
  @keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  position: absolute;
  top: 50%;
  right: 50%;
  color: ${colors.primary.default};
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const FormContainer = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export const FormSpace = styled.div`
  width: 100%;
  height: 1rem;
`;

export const SheetContainer = styled.div`
  width: 100%;
  height: 40rem;
  margin-top: 3rem;
  background: ${colors.gray.light03};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 870px) {
    flex-direction: column;
    height: 90vh;
  }
`;

export const ImgDiv = styled.div`
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media screen and (max-width: 870px) {
    width: 90%;
  }
`;

export const PuppiesImgDiv = styled.div<{ specie: string }>`
  width: 100%;
  height: 90%;
  border-right: 10px solid ${colors.gray.light02};
  border-top: 10px solid ${colors.gray.light02};
  border-bottom: 10px solid ${colors.gray.light02};
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: ${(props) => (props.specie === 'cachorro' ? '#27e0c9' : '#ff6473')};

  @media screen and (max-width: 870px) {
    height: 100%;
    border-right: 10px solid ${colors.gray.light02};
    border-left: 10px solid ${colors.gray.light02};
    border-bottom: 10px solid ${colors.gray.light02};
    border-top: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`;

export const GenderDiv = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -50px;
  padding-left: 13px;
  margin-left: -20px;
  position: relative;
  z-index: 2;
  background: ${colors.primary.default};

  @media screen and (max-width: 870px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
    width: 30%;
    padding-left: 0;
    margin-left: 10px;
    margin-bottom: -38px;
    height: 38px;
  }
`;

export const PuppiesImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  @media screen and (max-width: 870px) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`;

export const InfoDiv = styled.div`
  height: 90%;
  width: calc(70% - 2rem);
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 12px;
  border: 1px dashed #ccc;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 870px) {
    width: 100%;
    margin-right: 0;
    padding-left: 1.3rem;
    border: none;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 85%;
`;

export const StatusContainer = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const TextDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &+div {
    margin-top: 1rem;
    border-top: 1px solid ${colors.gray.light02};
    padding-top: 1rem;
  }

  @media screen and (max-width: 870px) {
    justify-content: space-between;
  }
`;
