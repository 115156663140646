const colors = {
  primary: {
    default: '#8EB7AA',
    light: '#D7E5E0',
    dark: '#518071',
    background: '#EDF4FA', // old system
  },
  secondary: {
    default: '#CCD500',
    dark: '#585C00',
  },
  gray: {
    black: '#000000',
    white: '#FFFFFF',
    dark01: '#4C4C4C',
    dark02: '#7E7E81',
    dark03: '#BFBFC0',
    light01: '#C9D0D8',
    light02: '#EAEDF0',
    light03: '#F9FAFB',
  },
  suport: {
    alert: '#E8505B',
    blue: '#3B78ED',
    green: '#8EB7AA',
    warning: '#FF8F39',
  },
};

export default colors;
