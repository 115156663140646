import colors from '@monorepo-zazuu/shared/constants/colors';
import ITruck from '@monorepo-zazuu/shared/interfaces/ITruck';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import WeekdayEnum from '../../../utils/enums/WeekdayEnum';
import Button from '../../../components/Button';
import InputRef from '../../../components/InputRef';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import api from '../../../services/api';
import {
  Container, Line, RemoveButton, Row, RowHoursLabel, Session,
} from './styles';
import SelectRef from '../../../components/SelectRef';
import masks from '../../../utils/masks';

interface IProps {
  handleCloseModal: () => void;
  visible: boolean;
  truckBasic: ITruck;
}

const ModalTruckDetails: React.FC<IProps> = ({ visible, handleCloseModal, truckBasic }) => {
  const blockRef = useRef<FormHandles>(null);
  const blockWeeklyRef = useRef<FormHandles>(null);
  const [truck, setTruck] = useState<ITruck>(truckBasic);

  const handleAddBlockDay = useCallback(async (data: {date: Date; hour_start: string; hour_end: string}): Promise<void> => {
    try {
      const dateParse = new Date(data.date);
      const response = await api.post('professionals/trucks-blocks', {
        truck_id: truckBasic.id,
        date: new Date(dateParse.getUTCFullYear(), dateParse.getUTCMonth(), dateParse.getUTCDate()),
        hour_start: data.hour_start,
        hour_end: data.hour_end,
      });
      toast('Bloqueio realizado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
      setTruck((old) => ({ ...old, truck_blocks_days: [...old.truck_blocks_days, response.data] }));
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [truckBasic.id]);

  const handleRemoveBlockDay = useCallback(async (truck_block_id: string): Promise<void> => {
    try {
      await api.delete('professionals/trucks-blocks', {
        params: {
          truck_id: truckBasic.id,
          truck_block_id,
        },
      });
      setTruck((old) => ({ ...old, truck_blocks_days: old.truck_blocks_days.filter((bd) => bd.id !== truck_block_id) }));
      toast('Bloqueio removido com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [truckBasic.id]);

  const handleAddBlockWeekly = useCallback(async (data: {weekday: number; hour_start: string; hour_end: string}): Promise<void> => {
    try {
      const response = await api.post('professionals/trucks-blocks-weekly', {
        truck_id: truckBasic.id,
        weekday: data.weekday,
        hour_start: data.hour_start,
        hour_end: data.hour_end,
      });
      toast('Bloqueio realizado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
      setTruck((old) => ({ ...old, truck_blocks_weekly: [...old.truck_blocks_weekly, response.data] }));
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [truckBasic.id]);

  const handleRemoveBlockWeekly = useCallback(async (truck_block_weekly_id: string): Promise<void> => {
    try {
      await api.delete('professionals/trucks-blocks-weekly', {
        params: {
          truck_id: truckBasic.id,
          truck_block_weekly_id,
        },
      });
      setTruck((old) => ({ ...old, truck_blocks_weekly: old.truck_blocks_weekly.filter((bd) => bd.id !== truck_block_weekly_id) }));
      toast('Bloqueio removido com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [truckBasic.id]);

  useEffect(() => {
    api.get('/professionals/trucks/details', {
      params: {
        truck_id: truckBasic.id,
      },
    }).then((response) => {
      setTruck(response.data);
    });
  }, [truckBasic.id]);

  const applyMask = useCallback(
    (formRef: React.RefObject<FormHandles>, e: React.ChangeEvent<HTMLInputElement>, mask?: string) => {
      formRef.current?.setFieldValue(
        e.target.name,
        mask ? masks[mask](e.target.value) : e.target.value
      );
    },
    [],
  );

  return (
    <Modal visible={!!visible} closeModal={handleCloseModal}>
      <Container id="select-control">
        <Text test="Detalhes do truck" size={18} color={colors.gray.dark01} weight="600" />
        <Session>
          <Text test="Bloquear dia" color={colors.gray.dark02} size={22} weight="700" />
          <Line />
          {truck?.truck_blocks_days?.map((day_blocked) => (
            <Row key={day_blocked.date.toString()} style={{ width: '100%', marginTop: '2rem' }}>
              <Text
                test={`Bloqueado ${format(parseISO(day_blocked.date.toString()), "'dia' dd 'de' MMMM", { locale: ptBR })} das ${day_blocked.hour_start} as ${day_blocked.hour_end}`}
                color={colors.gray.dark01}
              />
              <RemoveButton onClick={() => handleRemoveBlockDay(day_blocked.id)} style={{ placeContent: 'center' }}>
                <FiX name="x" size={18} color={colors.suport.alert} />
              </RemoveButton>
            </Row>
          ))}
          <Form
            ref={blockRef}
            onSubmit={handleAddBlockDay}
            style={{
              display: 'flex', flexDirection: 'column', marginTop: '2rem', width: '100%',
            }}
          >
            <InputRef name="date" type="date" placeholder="data" style={{ width: '100%' }} />
            <RowHoursLabel style={{ marginTop: '2rem', paddingRight: '1rem', paddingLeft: '1rem' }}>
              <Text test="Começa" color={colors.gray.dark01} />
              <Text test="Termina" color={colors.gray.dark01} />
            </RowHoursLabel>
            <RowHoursLabel>
              <InputRef
                name="hour_start"
                placeholder="00:00"
                onChange={(e) => applyMask(blockRef, e, 'hours')}
              />
              <InputRef
                name="hour_end"
                containerStyle={{ marginLeft: '2rem' }}
                placeholder="12:00"
                onChange={(e) => applyMask(blockRef, e, 'hours')}
              />
            </RowHoursLabel>
            <Button
              text="adicionar bloqueio"
              behavior="primary"
              styleContainer={{ marginTop: '2rem' }}
              style={{ width: '100%' }}
              type="submit"
            />
          </Form>
        </Session>
        <Session>
          <Text test="Bloquear dia semanalmente" color={colors.gray.dark02} size={22} weight="700" />
          <Line />
          {truck?.truck_blocks_weekly?.map((truck_block_weekly) => (
            <Row key={truck_block_weekly.weekday} style={{ width: '100%', marginTop: '2rem' }}>
              <Text
                test={`Bloqueado, ${WeekdayEnum[truck_block_weekly.weekday]} das ${truck_block_weekly.hour_start} as ${truck_block_weekly.hour_end}`}
                color={colors.gray.dark01}
              />
              <RemoveButton onClick={() => handleRemoveBlockWeekly(truck_block_weekly.id)} style={{ placeContent: 'center' }}>
                <FiX name="x" size={18} color={colors.suport.warning} />
              </RemoveButton>
            </Row>
          ))}
          <Form
            ref={blockWeeklyRef}
            onSubmit={handleAddBlockWeekly}
            style={{
              display: 'flex', flexDirection: 'column', marginTop: '2rem', width: '100%',
            }}
          >
            <SelectRef
              name="weekday"
              placeholder="dia da semana"
              style={{ width: '100%' }}
              menuPortalTarget={document.getElementById('select-control')}
              menuPlacement="auto"
              options={
              Object.entries(WeekdayEnum).map((day) => ({
                value: day[0],
                label: day[1],
              }))
            }
            />
            <RowHoursLabel style={{ marginTop: '2rem', paddingRight: '1rem', paddingLeft: '1rem' }}>
              <Text test="Começa" color={colors.gray.dark01} />
              <Text test="Termina" color={colors.gray.dark01} />
            </RowHoursLabel>
            <RowHoursLabel>
              <InputRef
                name="hour_start"
                placeholder="00:00"
                onChange={(e) => applyMask(blockWeeklyRef, e, 'hours')}
              />
              <InputRef
                name="hour_end"
                containerStyle={{ marginLeft: '2rem' }}
                placeholder="24:00"
                onChange={(e) => applyMask(blockWeeklyRef, e, 'hours')}
              />
            </RowHoursLabel>
            <Button
              text="adicionar bloqueio"
              behavior="primary"
              styleContainer={{ marginTop: '2rem' }}
              style={{ width: '100%' }}
              type="submit"
            />
          </Form>
        </Session>
      </Container>
    </Modal>
  );
};

export default ModalTruckDetails;
