import styled, { css } from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

interface IInputProps {
  hasIcon: boolean;
  isErrored: boolean;
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<IInputProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  input {
    display: flex;
    height: 4.8rem;
    width: 100%;

    background: ${colors.gray.light02};

    border: 1px solid ${colors.gray.light02};
    border-radius: 5rem;

    -webkit-appearance: textfield;
    -moz-appearance: textfield;

    -webkit-appearance: none;
    -webkit-box-shadow: inset 0px 4px 4px rgba(77, 76, 76, 0.2);
    box-shadow: inset 0px 4px 4px rgba(77, 76, 76, 0.2);

    ${(props) => props.isErrored
    && css`
        border: 1px solid ${colors.suport.alert};
      `};

    ${(props) => (props.isFocused || props.isFilled)
    && css`
        border: 1px solid ${colors.secondary.default};
      `};

    padding-left: 2rem;

    padding-right: ${(props) => (props.hasIcon ? 2 : 0)}rem;

    font-family: 'Open Sans';
    font-size: 1.6rem;
    line-height: 150%;
    color: ${colors.gray.dark02};

    &::placeholder {
      color: ${colors.gray.dark01};
    }

    @media (max-width: 500px) {
      height: 6rem;
    }
  }
  svg {
    font-size: 1.9rem;
    color: ${colors.gray.dark01};

    @media (max-width: 650px) {
      font-size: 2.4rem;
    }
  }
`;

export const IconButton = styled.button`
  position: absolute;
  right: 15px;

  padding: 1rem;

  background: transparent;
  border: none;
`;
