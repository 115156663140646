enum WeekdayEnum {
  'domingo' = 0,
  'segunda' = 1,
  'terça' = 2,
  'quarta' = 3,
  'quinta' = 4,
  'sexta' = 5,
  'sábado' = 6,
}

export default WeekdayEnum;
