import logo from '@monorepo-zazuu/shared/assets/icons/logo.svg';
import React from 'react';
import { IoIosLogOut } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { Container } from './styles';

const Header: React.FC = () => {
  const { user, signOut } = useAuth();

  return (
    <Container visible={!!user}>
      <nav>
        <div>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <p>
            <span>Olá,</span> {user?.name.split(' ')[0]}
          </p>
          <button
          style={{
            display: 'flex',
            marginLeft: 16,
            justifyContent: 'center'
          }}
          type="button"
            onClick={() => {
              signOut();
            }}>
            <IoIosLogOut />
          </button>
        </div>
      </nav>
    </Container>
  );
};

export default Header;
