import { gql } from '@apollo/client';
import logo from '@monorepo-zazuu/shared/assets/icons/logo.svg';
import verifyCepZone from '@monorepo-zazuu/shared/utils/verifyCepZone';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import cepPromise from 'cep-promise';
import React, { useCallback, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { FiCalendar } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import InputRef from '../../components/InputRef';
import client from '../../services/api-graphql';
import getValidationErrors from '../../utils/getValidationErrors';
import masks from '../../utils/masks';
import removeSymbolsOfString from '../../utils/removeSymbolsOfString';
import {
  ButtonSubmit,
  CenterSide,
  CepContainer,
  Container,
  Content,
  HeaderMobile,
  LeftFields,
  LeftSide,
  RightFields,
  RightSide,
  Row,
  SubTitle,
  Title,
  TitleFields
} from './styles';

interface ISignUpFormData {
  name: string;
  cpf: string;
  birthday: string;
  address: {
    cep: string;
    number: string;
    complement: string;
    surname: string;
  };
  email: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
}

const SignUpCustomer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: ISignUpFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(),
        cpf: Yup.string().required().length(14),
        birthday: Yup.string().required().length(10),
        address: Yup.object().shape({
          cep: Yup.string().required().length(9),
          number: Yup.string().optional().max(10),
          street: Yup.string().required(),
          neighborhood: Yup.string().required(),
          city: Yup.string().required(),
          state: Yup.string().required(),
          surname: Yup.string().required(),
          complement: Yup.string().max(20).notRequired(),
        }),
        email: Yup.string().email().required(),
        phone: Yup.string().required().min(14).max(15),
      });

      await schema.validate(data, { abortEarly: false });

      const date_splitted = data.birthday.split('/');

      await client.mutate({
        mutation: gql`
          mutation($customer: UserInputType!) {
            createCustomer(customer: $customer) {
              id
            }
          }
        `, variables: {
          customer: {
            name: data.name,
            email: data.email,
            cpf: removeSymbolsOfString(data.cpf),
            password: data.password,
            phone: removeSymbolsOfString(data.phone),
            birthday: `${date_splitted[2]}-${date_splitted[1]}-${date_splitted[0]}`,
            address: data.address,
          },
        }
      });

      if (!verifyCepZone(data.address.cep)) {
        toast('Seu CEP está fora da zona de atendimento...', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'warning',
        });
      }

      toast('Cadastrado com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });

      history.push('/dashboard');
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      } else {
      const erroGRAPHQL = error as any;
      const payloadErro = erroGRAPHQL.graphQLErrors[0];
        toast(payloadErro.message || 'Erro desconhecido', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
      }
    }
  };

  const searchFullAddress = useCallback(async (cep: string) => {
    try {
      const address = await cepPromise(cep);
      formRef.current?.setData({
        address: {
          street: address.street,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
        },
      });
    } catch (error) {
      toast('Erro ao buscar o CEP', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
      formRef.current?.setErrors({ 'address.cep': 'Erro com o CEP' });
    }
  }, []);

  const applyMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, mask?: string) => {
      formRef.current?.setFieldValue(
        e.target.name,
        mask ? masks[mask](e.target.value) : e.target.value
      );
    },
    [],
  );

  return (
    <Container>
      <LeftSide />
      <HeaderMobile>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </HeaderMobile>
      <CenterSide>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <Title>Faça seu cadastro agora!</Title>
            <SubTitle>
              Cadastre-se para realizar agendamentos e acessar o conteúdo zazuu
              direto da sua casa.
            </SubTitle>
            <Row>
              <LeftFields>
                <TitleFields>Dados pessoais</TitleFields>
                <InputRef
                  placeholder="nome"
                  name="name"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <InputRef
                  placeholder="cpf"
                  name="cpf"
                  onChange={(e) => applyMask(e, 'cpf')}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <InputRef
                  placeholder="data de nascimento"
                  name="birthday"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  icon={FiCalendar}
                  onChange={(e) => applyMask(e, 'birthday')}
                />
              </LeftFields>
              <RightFields>
                <TitleFields>Contato</TitleFields>
                <InputRef
                  placeholder="email"
                  name="email"
                  type="email"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <InputRef
                  placeholder="telefone"
                  name="phone"
                  onChange={(e) => applyMask(e, 'phone')}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
              </RightFields>
            </Row>
            <Row>
              <LeftFields>
                <TitleFields>Endereço</TitleFields>
                <CepContainer>
                  <InputRef
                    placeholder="cep"
                    name="address.cep"
                    containerStyle={{
                      flex: 1,
                      marginTop: '1rem',
                    }}
                    onChange={(e) => applyMask(e, 'cep')}
                    onBlurExecute={searchFullAddress}
                  />
                  <InputRef
                    placeholder="nº"
                    name="address.number"
                    containerStyle={{
                      width: '8rem',
                      marginTop: '1rem',
                      marginLeft: '1rem',
                    }}
                  />
                </CepContainer>
                <InputRef
                  placeholder="rua"
                  name="address.street"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    opacity: 0.5,
                  }}
                  disabled
                />
                <InputRef
                  placeholder="bairro"
                  name="address.neighborhood"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    opacity: 0.5,
                  }}
                  disabled
                />
                <InputRef
                  placeholder="cidade"
                  name="address.city"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    opacity: 0.5,
                  }}
                  disabled
                />
                <InputRef
                  placeholder="estado"
                  name="address.state"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    opacity: 0.5,
                  }}
                  disabled
                />
                <InputRef
                  placeholder="complemento"
                  name="address.complement"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                />
                <InputRef
                  placeholder="apelido do endereço"
                  name="address.surname"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                />
              </LeftFields>
              <RightFields />
            </Row>
            <TitleFields style={{ paddingTop: '1rem', fontSize: '1.4rem' }}>
              Ao clicar em Cadastre-se, você concorda com nossos Termos,{' '}
              <a href={`${process.env.REACT_APP_WEB_URL}/termos`} target="_blank" rel="noopener noreferrer">
                Termos e Condições Gerais de Uso,
              </a>{' '}
              <a href={`${process.env.REACT_APP_WEB_URL}/politicadedados`} target="_blank" rel="noopener noreferrer">Política de Dados</a>. Você pode receber notificações por SMS e pode cancelar isso quando quiser.
            </TitleFields>
            <ButtonSubmit type="submit">
              {loading ? <FaSpinner /> : 'cadastrar'}
            </ButtonSubmit>
          </Content>
        </Form>
      </CenterSide>
      <RightSide>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </RightSide>
    </Container>
  );
};

export default SignUpCustomer;
