import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 650px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    margin-bottom: calc((100vw / 5) + 4rem);
  }
`;

export const CenterSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colors.gray.white};
  border-radius: 8px;

  padding: 1rem 3.5rem 0 3.5rem;
  margin-bottom: 3rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  & + div {
    margin-top: 4rem;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const Title = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 150%;
  color: ${colors.primary.default};

  margin-bottom: 1rem;
`;

export const LeftFields = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-right: 2rem;

  @media (max-width: 650px) {
    margin-right: 0;
  }
`;

export const RightFields = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-left: 1rem;

  @media (max-width: 650px) {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

export const TitleFields = styled.strong`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 150%;

  color: ${colors.primary.default};

  margin-top: 2rem;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`;

export const InputWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
  border-radius: 5rem;

  align-items: center;

  svg {
    font-size: 2rem;
    color: ${colors.gray.dark01};
  }
`;

export const CepContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
`;

export const ButtonSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 5rem;

  margin: 3rem auto 2rem auto;
  padding: 0 4rem;

  height: 5.6rem;

  background: ${colors.primary.default};

  font-family: 'Open Sans';
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.light02};
  transition: background 0.2s;

  :hover {
    background: ${colors.primary.default};
  }

  svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: 650px) {
    margin-top: 3rem;
    height: 7.6rem;
  }
`;

export const CardAddress = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  margin-bottom: 1rem;
`;

export const CardAddressTitle = styled.p`
  margin-bottom: 1rem;

  font-family: 'Open Sans';
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 120%;
  color: ${colors.gray.dark02};
`;

export const CardAddressComplement = styled.p<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  font-family: 'Open Sans';
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const CardAddressDetails = styled.p`
  font-family: 'Open Sans';
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const CardLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${colors.gray.light02};
  margin: 1rem 0;
`;

export const ButtonEdit = styled.button`
  font-size: 1.8rem;
  color: ${colors.gray.dark01};
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 1rem;

  & > svg {
    font-size: 2rem;
    color: ${colors.gray.dark01};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 5.4rem;
  padding: 1rem;
  margin: 0.5rem 0;
  background: ${colors.gray.white};
  position: relative;

  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;
  color: ${colors.gray.dark01};

  transition: all 0.3s ease;

  & > p {
    margin: 0 1rem;
  }
`;

export const BrandImage = styled.img`
  margin: 0 1rem;
  width: 4rem;
  object-fit: contain;
`;
