import colors from '@monorepo-zazuu/shared/constants/colors';
import ITruck from '@monorepo-zazuu/shared/interfaces/ITruck';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import InputRef from '../../../components/InputRef';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  Container,
} from './styles';

interface IProps {
  handleCloseModal: () => void;
  visible: boolean;
  onTruckCreated: (truck: ITruck) => void;
}

const ModalAddTruck: React.FC<IProps> = ({ visible, handleCloseModal, onTruckCreated }) => {
  const formAddTruckRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: Omit<ITruck, 'id'>): Promise<void> => {
    setLoading(true);
    try {
      formAddTruckRef.current?.setErrors({});

      const schema = Yup.object().shape({
        license_plate: Yup.string().required(),
        renavam: Yup.string().required(),
        chasi_number: Yup.string().required(),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await api.post<ITruck>('/professionals/trucks', {
        license_plate: data.license_plate,
        renavam: data.renavam,
        chasi_number: data.chasi_number,
      });

      onTruckCreated(response.data);

      toast('Cadastrado com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
      setLoading(false);
      handleCloseModal();
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formAddTruckRef.current?.setErrors(errors);
      } else {
        toast(error?.response?.data?.message || 'Erro no servidor', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
      }
    }
  };

  return (
    <Modal visible={!!visible} closeModal={handleCloseModal}>
      <Container>
        <Text test="Criar truck" size={18} color={colors.gray.dark01} weight="600" />
        <Form ref={formAddTruckRef} onSubmit={handleSubmit} style={{ width: '100%' }}>
          <InputRef
            name="license_plate"
            placeholder="placa"
            containerStyle={{ marginTop: '2rem' }}
          />
          <InputRef
            name="renavam"
            placeholder="renavam"
            containerStyle={{ marginTop: '1rem' }}
          />
          <InputRef
            name="chasi_number"
            placeholder="Número do chassi"
            containerStyle={{ marginTop: '1rem' }}
          />
          <Button
            text="Adicionar novo truck"
            behavior="primary"
            type="submit"
            styleContainer={{ marginTop: '2rem' }}
            style={{ width: '100%' }}
            loading={loading}
          />
        </Form>
      </Container>
    </Modal>
  );
};

export default ModalAddTruck;
