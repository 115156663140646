import { gql } from '@apollo/client';
import colors from '@monorepo-zazuu/shared/constants/colors';
import ISelectValue from '@monorepo-zazuu/shared/interfaces/ISelectValue';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Modal from '../../../components/Modal';
import Button from '../../../components/web/Button';
import Input from '../../../components/web/Input';
import Select from '../../../components/web/Select';
import Switch from '../../../components/web/Switch';
import Text from '../../../components/web/Text';
import client from '../../../services/api-graphql';
import ModalAddGroup from './ModalAddGroup';
import * as Styles from './styles';
import TableGroups from './TableGroups';

const IServiceForm = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  status: Yup.object().shape({
    value: Yup.mixed().required(),
    label: Yup.mixed().required(),
  }).required(),
  discount_web: Yup.number(),
  discount_mobile: Yup.number(),
  image_url: Yup.string()
});

interface IServiceForm {
  name: string;
  description: string;
  status: ISelectValue;
  discount_web: number;
  discount_mobile: number;
  image: File | undefined | null
  image_url: string;
  role: ISelectValue;
  prices: {
    specie: ISelectValue;
    size: ISelectValue;
    price: string;
    execution_time: number;
  }[];
  groups?: {
    name: string;
    is_default: ISelectValue;
    items: ISelectValue[];
  }[];
}

interface IProps {
  visible: boolean;
  closeModal: () => void;
  species: ISelectValue[];
  roles: ISelectValue[];
  category_id: string;
  subcategory_id: string | undefined;
  handleAddNewService: (newService: any) => void;
}

const ModalAddService: React.FC<IProps> = ({ visible, closeModal, species, roles, category_id, subcategory_id, handleAddNewService }) => {
  const [hasAdditionals, setHasAdditionals] = useState(false);
  const [modalAddGroup, setModalAddGroup] = useState(false);
  const sizes = [
    {
      value: 'PP',
      label: 'PP'
    },
    {
      value: 'P',
      label: 'P'
    },
    {
      value: 'M',
      label: 'M'
    },
    {
      value: 'G',
      label: 'G'
    },
    {
      value: 'GG',
      label: 'GG'
    },
    {
      value: 'XGG',
      label: 'XGG'
    },
  ]

  const initialValues = useMemo(() => {
    return {
      name: '',
      description: '',
      status: {
        value: true,
        label: 'Ativo'
      },
      image: undefined,
      prices: [{}]
    } as IServiceForm;
  }, []);

  const handleSubmit = useCallback(async (service: IServiceForm) => {
    try {
      const results = await client.mutate({
        mutation: gql`
        mutation {
          createService(service: {
            name: "${service.name}",
            description: "${service.description}",
            status: ${service.status.value},
            category_id: "${category_id}",
            role_id: "${service.role.value}",
            ${subcategory_id ? `subcategory_id: "${subcategory_id}",` : ''}
            prices: ${JSON.stringify(service.prices.map(p => ({
              specie_id: p.specie.value,
              size: p.size.value,
              price: Number(p.price),
              execution_time: Number(p.execution_time),
            }))).replace(/"([^"]+)":/g, '$1:')},
            groups: ${JSON.stringify(service.groups?.map(g => ({
              name: g.name,
              is_default: g.is_default.value,
              items: g.items.map(i => ({id: i.value}))
            })) || []).replace(/"([^"]+)":/g, '$1:')},
          }) {
            id
            name
            category_id
            subcategory_id
            status
          }
        }
      `
      });
      handleAddNewService(results.data.createService)
    } catch(error) {
      toast(error?.networkError?.result?.errors[0]?.message || 'Erro ao salvar servico', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }

  }, [category_id, subcategory_id, handleAddNewService]);

  return (
    <Modal visible={visible} closeModal={closeModal}>
      <Styles.Content>
          <Text
            test="Detalhes"
            align="left"
            color={colors.primary.default}
            size={24}
            weight="700"
            marginBottom={8}
          />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={IServiceForm}
          >
            {(props) => (
              <Form>
                <Styles.Row style={{marginBottom: 24}}>
                <Styles.Column style={{flex: 1, marginRight: 16}}>
                  <Text
                    test="Nome do serviço"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Input
                    value={props.values.name}
                    onChange={e => props.setFieldValue('name', e.target.value)}
                    placeholder="digite o nome do serviço"
                    error={!!(props.errors.name && props.touched.name)}
                  />
                  <Text
                    test="Descrição"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Input
                    value={props.values.description}
                    onChange={e => props.setFieldValue('description', e.target.value)}
                    placeholder="digite a descrição do serviço"
                    error={!!(props.errors.description && props.touched.description)}
                  />
                  <Text
                    test="Status"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Select
                    placeholder="ativo ou inativo"
                    value={props.values.status}
                    onChange={value => props.setFieldValue('status', value)}
                    options={[{value: true, label: 'Ativo'}, {value: false, label: 'Inativo'}]}
                  />
                  <Text
                    test="Desconto mobile"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Input
                    value={props.values.discount_mobile}
                    onChange={e => props.setFieldValue('discount_mobile', e.target.value)}
                    placeholder="%"
                    error={!!(props.errors.discount_mobile && props.touched.discount_mobile)}
                  />
                  <Text
                    test="Desconto web"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Input
                    value={props.values.discount_web}
                    onChange={e => props.setFieldValue('discount_web', e.target.value)}
                    placeholder="%"
                    error={!!(props.errors.discount_web && props.touched.discount_web)}
                  />
                </Styles.Column>
                <Styles.Column style={{flex: 1}}>
                  <Text
                    test="Realizado por"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Select
                    placeholder="funções"
                    value={props.values.role}
                    onChange={value => props.setFieldValue('role', value)}
                    options={roles}
                  />
                  <Text
                    test="Imagem"
                    align="left"
                    color={colors.primary.default}
                    size={16}
                    weight="700"
                    marginTop={16}
                    marginBottom={8}
                  />
                  <Styles.InputImage type="button">
                    {props.values.image && <img src={props.values.image_url} alt={props.values.image.name} />}
                    <label htmlFor="image">
                      <input type="file" id="image" onChange={(event) => {
                        if(event.target.files){
                          props.setFieldValue('image', event.target.files[0]);
                          props.setFieldValue('image_url', URL.createObjectURL(event.target.files[0]));
                        }
                      }}
                      accept="image/*"
                      />
                      <Text
                        test="Adicionar imagem"
                        align="center"
                        color={colors.gray.dark02}
                        size={16}
                        weight="700"
                      />
                    </label>
                  </Styles.InputImage>
                </Styles.Column>
              </Styles.Row>
              <Text
                test="Preços"
                align="left"
                color={colors.primary.default}
                size={24}
                weight="700"
                marginBottom={16}
              />
              {props.values.prices.map((price, index) => (
                <Styles.Row key={index}>
                  <Styles.Column style={{flex: 1, marginRight: 8}}>
                    <Text
                      test="Espécie"
                      align="left"
                      color={colors.primary.default}
                      size={16}
                      weight="700"
                      marginTop={16}
                      marginBottom={8}
                    />
                    <Select
                      placeholder="especies"
                      options={species}
                      value={props.values.prices[index].specie}
                      onChange={value => props.setFieldValue(`prices[${index}].specie`, value)}
                    />
                  </Styles.Column>
                  <Styles.Column style={{flex: 1, marginRight: 8}}>
                    <Text
                      test="Porte"
                      align="left"
                      color={colors.primary.default}
                      size={16}
                      weight="700"
                      marginTop={16}
                      marginBottom={8}
                    />
                    <Select
                      placeholder="portes"
                      options={sizes}
                      value={props.values.prices[index].size}
                      onChange={value => props.setFieldValue(`prices[${index}].size`, value)}
                    />
                  </Styles.Column>
                  <Styles.Column style={{flex: 1, marginRight: 8}}>
                    <Text
                      test="Preço"
                      align="left"
                      color={colors.primary.default}
                      size={16}
                      weight="700"
                      marginTop={16}
                      marginBottom={8}
                    />
                    <Input
                      placeholder="R$"
                      value={props.values.prices[index].price}
                      onChange={e => props.setFieldValue(`prices[${index}].price`, e.target.value)}
                    />
                  </Styles.Column>
                  <Styles.Column style={{flex: 1, marginRight: 8}}>
                    <Text
                      test="Tempo execução"
                      align="left"
                      color={colors.primary.default}
                      size={16}
                      weight="700"
                      marginTop={16}
                      marginBottom={8}
                    />
                    <Input
                      placeholder="0"
                      value={props.values.prices[index].execution_time}
                      onChange={e => props.setFieldValue(`prices[${index}].execution_time`, e.target.value)}
                    />
                  </Styles.Column>
                </Styles.Row>
              ))}
              <Styles.Row style={{marginTop: 24, marginBottom: 24}}>
                <Button
                  behavior="secondary"
                  text="Adicionar porte"
                  styleContainer={{marginRight: 16}}
                  onClick={() => props.setFieldValue('prices', [...props.values.prices, {}])}
                />
                {/* <Button
                  behavior="secondary"
                  text="Copiar preços de outro serviço"
                /> */}
              </Styles.Row>
              <Styles.Row>
                <Text
                  test="Complementos"
                  align="left"
                  color={colors.primary.default}
                  size={24}
                  weight="700"
                  marginBottom={24}
                />
              </Styles.Row>
              <Styles.Row style={{alignItems: 'center', marginBottom: 24}}>
                <Text
                  test="Este serviço tem complementos?"
                  align="left"
                  color={colors.primary.default}
                  weight="600"
                  marginRight={16}
                />
                <Switch checked={hasAdditionals} option1="sim" option2="não" onChangeOption={(value) => setHasAdditionals(value)} styleContainer={{width: 200}} />
              </Styles.Row>
              {hasAdditionals && (
                <Styles.Row>
                  <Button
                    behavior="secondary"
                    text="Criar grupo de complementos"
                    onClick={() => setModalAddGroup(true)}
                  />
                  {/* <Button
                    behavior="primary"
                    text="Copiar de outro serviço"
                    style={{marginLeft: 8}}
                  /> */}
                </Styles.Row>
              )}
              {props.values.groups &&
                <TableGroups
                  groups={props.values.groups}
                  handleRemoveItemFromGroup={(item_id) => props.setFieldValue('groups', props.values.groups?.map(group => ({...group, items: group.items.filter(item => item.value !== item_id)})))}
                  handleRemoveGroup={(group_name) => {props.setFieldValue('groups', props.values.groups?.filter(g => g.name !== group_name))}}
                />}
              <Styles.Row style={{marginTop: 32}}>
                <Button
                  behavior="secondary"
                  text="Cancelar"
                  onClick={closeModal}
                />
                <Button
                  behavior="primary"
                  text="Salvar"
                  style={{marginLeft: 8}}
                  onClick={props.submitForm}
                />
              </Styles.Row>
              {modalAddGroup && <ModalAddGroup
                visible={modalAddGroup}
                closeModal={() => setModalAddGroup(false)}
                handleUpdateGroup={values => {
                  props.setFieldValue('groups', [...props?.values?.groups || [], values])
                }}
              />}
            </Form>
            )}
        </Formik>
      </Styles.Content>
    </Modal>
  )
}

export default ModalAddService;
