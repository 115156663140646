import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { FaEdit, FaSpinner } from 'react-icons/fa';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import mastercardBrandImage from '@monorepo-zazuu/shared/assets/images/mastercard_brand_1x.png';
import visaBrandImage from '@monorepo-zazuu/shared/assets/images/visa_brand.png';
import IAddress from '@monorepo-zazuu/shared/interfaces/IAddress';
import ICard from '@monorepo-zazuu/shared/interfaces/ICard';
import ICustomer from '@monorepo-zazuu/shared/interfaces/ICustomer';
import InputRef from '../../components/InputRef';
import ModalAddress from '../../components/ModalAddress';
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import masks from '../../utils/masks';
import formatDate from '../../utils/formatDate';
import {
  BrandImage,
  ButtonEdit,
  ButtonSubmit,
  CardAddress,
  CardAddressComplement,
  CardAddressDetails,
  CardAddressTitle,
  CardContainer,
  CardLine,
  CenterSide,
  Container,
  Content,
  LeftFields,
  RightFields,
  Row,
  Title,
  TitleFields,
} from './styles';

interface IUpdateProfileFormData {
  name: string;
  cpf: string;
  birthday: string;
  number: string;
  complement: string;
  email: string;
  cellPhone: string;
}

const UpdateProfile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formChangePassword = useRef<FormHandles>(null);
  const { updateCustomerDetails } = useAuth();

  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const [cards, setCards] = useState<ICard[]>([]);
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [addressSelected, setAddressSelected] = useState<IAddress>();
  const [modalAddressVisible, setModalAddressVisible] = useState(false);

  const handleSubmit = useCallback(
    async (data: IUpdateProfileFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(),
          cpf: Yup.string().required().length(14),
          birthday: Yup.string().optional(),
          email: Yup.string().email().required(),
          phone: Yup.string().required().min(14).max(15),
        });

        await schema.validate(data, { abortEarly: false });
        const response = await api.put('/customers', data);
        setLoading(false);
        updateCustomerDetails({
          name: response.data.name,
          email: response.data.email,
        });

        toast('Dados pessoais alterados com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'success',
        });

        formRef.current?.setData({
          name: response.data.name,
          cpf: masks.cpf(response.data.cpf),
          birthday: formatDate(response.data.birthday),
          email: response.data.email,
          phone: masks.phone(response.data.phone),
        });
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [updateCustomerDetails],
  );

  const handleChangePassword = async (data: {
    password: string;
    new_password: string;
    new_password_confirmation: string;
  }) => {
    setLoading(true);

    formChangePassword.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        password: Yup.string().required(),
        new_password: Yup.string().required(),
        new_password_confirmation: Yup.string().oneOf(
          [Yup.ref('new_password'), undefined],
          'Passwords must match',
        ),
      });

      await schema.validate(data, { abortEarly: false });

      await api.patch('/password/change', data);

      toast('Senha alterada com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });

      setLoading(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formChangePassword.current?.setErrors(errors);
      } else {
        formChangePassword.current?.setFieldError('password', 'error');
      }
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    try {
      const responseCards = await api.get('/payments/cards');
      setCards(responseCards.data);

      const responseCustomerData = await api.get<ICustomer>('/customers/me');
      setCustomer(responseCustomerData.data);
      setAddresses(responseCustomerData.data.addresses);

      formRef.current?.setData({
        name: responseCustomerData.data.user.name,
        cpf: masks.cpf(responseCustomerData.data.user.cpf),
        birthday: formatDate(responseCustomerData?.data.user?.birthday),
        email: responseCustomerData.data.user.email,
        phone: masks.phone(responseCustomerData.data.user.phone),
      });
    } catch (error) {
      toast('Erro ao recuperar seus dados', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleChangeAddress = (ad: IAddress) => {
    if (!customer) {
      return;
    }

    setAddresses((old) => old.map((address) => {
      if (address.id === ad.id) {
        return ad;
      }
      return address;
    }));
  };

  const handleDeleteCard = async (card_id: string) => {
    await api.delete(`/payments/cards?card_id=${card_id}`);
    setCards(cards.filter((card) => card.id !== card_id));
  };

  const applyMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, mask: string) => {
      formRef.current?.setFieldValue(
        e.target.name,
        masks[mask](e.target.value),
      );
    },
    [],
  );

  return (
    <Container>
      <CenterSide>
        <Title>Dados pessoais</Title>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <LeftFields>
                <TitleFields>nome</TitleFields>
                <InputRef
                  placeholder="nome"
                  name="name"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <TitleFields>cpf</TitleFields>
                <InputRef
                  placeholder="cpf"
                  name="cpf"
                  onChange={(e) => applyMask(e, 'cpf')}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <TitleFields>data de nascimento</TitleFields>
                <InputRef
                  placeholder="data de nascimento"
                  name="birthday"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  icon={FiCalendar}
                  onChange={(e) => applyMask(e, 'birthday')}
                />
              </LeftFields>
              <RightFields>
                <TitleFields>email</TitleFields>
                <InputRef
                  placeholder="email"
                  name="email"
                  type="text"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <TitleFields>telefone</TitleFields>
                <InputRef
                  placeholder="telefone"
                  name="phone"
                  onChange={(e) => applyMask(e, 'phone')}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
              </RightFields>
            </Row>
            <ButtonSubmit type="submit">
              {loading ? <FaSpinner /> : 'atualizar'}
            </ButtonSubmit>
          </Form>
        </Content>
        <Title>Cartões</Title>
        <Content>
          {cards.map((card) => (
            <CardContainer key={card.id}>
              {card.brand === 'mastercard' && (
                <BrandImage src={mastercardBrandImage} alt="brand" />
              )}
              {card.brand === 'VISA' && (
                <BrandImage src={visaBrandImage} alt="brand" />
              )}
              <p>{card.brand}</p>
              <p>{`**** **** **** ${card.last_digits}`}</p>
              <p>{`${card.exp_month}/${card.exp_year.slice(2, 4)}`}</p>
              <ButtonEdit onClick={() => handleDeleteCard(card.id)}>
                <FiXCircle />
              </ButtonEdit>
            </CardContainer>
          ))}
          {cards.length === 0 && (
            <CardContainer>
              <p>Nenhum cartão salvo</p>
            </CardContainer>
          )}
        </Content>

        <Title>Endereços</Title>
        <Content>
          {addresses.map((address, index) => (
            <div key={address.id}>
              {index > 0 && <CardLine />}
              <CardAddress>
                <CardAddressTitle>{`${address?.street} ${address?.number || 'sn'}`}</CardAddressTitle>
                <CardAddressComplement visible={!!address?.complement}>
                  {address?.complement}
                </CardAddressComplement>
                <CardAddressDetails>{`${address?.state}(${address?.cep}), ${address?.city}`}</CardAddressDetails>
                <ButtonEdit onClick={() => { setAddressSelected(address); setModalAddressVisible(true); }}>
                  <FaEdit />
                </ButtonEdit>
              </CardAddress>
            </div>
          ))}
          {addressSelected && (
          <ModalAddress
            visible={modalAddressVisible}
            initialValues={addressSelected}
            onSubmitForm={(ad) => {
              handleChangeAddress(ad);
            }}
            closeModal={() => { setModalAddressVisible(false); setAddressSelected(undefined); }}
          />
          )}
        </Content>

        <Title>Alterar senha</Title>
        <Content style={{ paddingTop: '2rem', maxWidth: '500px' }}>
          <Form onSubmit={handleChangePassword} ref={formChangePassword}>
            <TitleFields>senha atual</TitleFields>
            <InputRef
              placeholder="senha atual"
              name="password"
              type="password"
              style={{ marginTop: '1rem', marginBottom: '3rem' }}
            />
            <TitleFields>nova senha</TitleFields>
            <InputRef
              placeholder="nova senha"
              name="new_password"
              type="password"
              style={{ marginTop: '1rem', marginBottom: '3rem' }}
            />
            <TitleFields>confirmação nova senha</TitleFields>
            <InputRef
              placeholder="confirmação nova senha"
              name="new_password_confirmation"
              type="password"
              style={{ marginTop: '1rem' }}
            />
            <ButtonSubmit type="submit">
              {loading ? <FaSpinner /> : 'alterar senha'}
            </ButtonSubmit>
          </Form>
        </Content>
      </CenterSide>
    </Container>
  );
};

export default UpdateProfile;
