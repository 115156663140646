import React, { useRef, useEffect } from 'react';

import { ModalStyled, ModalContent } from './styles';

const Modal: React.FC<{
  closeModal(): void;
  visible: boolean;
  children: React.ReactNode;
}> = ({ closeModal, visible, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef, closeModal]);

  return (
    <ModalStyled visible={visible}>
      <ModalContent ref={modalRef}>{children}</ModalContent>
    </ModalStyled>
  );
};

export default Modal;
