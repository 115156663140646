import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import RefCodeAlert from './components/RefCodeAlert';
import AppProvider from './hooks/index';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { ContainerApp } from './styles/styles';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Header />
        <RefCodeAlert />
        <ContainerApp>
          <Routes />
        </ContainerApp>
      </AppProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
};

export default App;
