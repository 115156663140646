import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6.4rem);
  width: 100%;
  align-items: center;
  justify-content: center;

  svg {
    animation: rotate 2s linear infinite;
    color: ${colors.secondary.default};

    font-size: 4rem;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-bottom: 8rem;

  @media (max-width: 600px) {
    margin-bottom: calc(((100vw) / 5) + 2rem);
  }
`;

export const PetsRow = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  width: 100%;
  padding: 0 2rem 3rem 2rem;
`;

export const PetsRowButton = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  margin-top: 2rem;
  margin-right: 1rem;

  color: ${(props) => (props.selected ? colors.gray.white : colors.gray.dark02)};

  border: 2px solid ${colors.secondary.default};

  background: ${(props) => (props.selected ? colors.secondary.default : colors.gray.white)};

  padding: 1.2rem 2rem;
  border-radius: 8px;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 120%;

  transition: all 0.5s ease;

  & > img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const PetDetailsContainer = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : ' none')};
  flex-direction: row;
  position: relative;
  align-items: center;

  background: ${colors.gray.white};
  border-radius: 1rem;
  margin: 3rem auto 0 auto;
  padding: 2rem;

  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.3);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.3);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.3);

  & > img {
    width: 12rem;
    height: 12rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 4rem;
  }
`;

export const PetDetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  div + div {
    margin-top: 0.5rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p + svg {
    margin-left: 1rem;
    color: ${colors.gray.dark02};
  }

  & > svg {
    margin-right: 1rem;
    color: ${colors.gray.dark02};
  }
`;

export const PetName = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 120%;
  color: ${colors.gray.dark02};
`;

export const Text = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 120%;
  color: ${colors.gray.dark02};
`;

export const AppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding: 0 2rem;
`;

export const Title = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
  margin: 4rem;
  align-self: center;
`;

export const ListAppointments = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  place-content: center;

  @media (max-width: 800px) {
    grid-row-gap: 3rem;
  }

  @media (max-width: 390px) {
    grid-template-columns: repeat(auto-fit, 300px);
  }
`;

export const CardAppointment = styled.div<{ status: string }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: ${colors.gray.white};
  position: relative;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 7px 1px
    ${(props) => (props.status === 'P' ? colors.secondary.default : colors.suport.warning)};
  -moz-box-shadow: 0px 0px 7px 1px
    ${(props) => (props.status === 'P' ? colors.secondary.default : colors.suport.warning)};
  box-shadow: 0px 0px 7px 1px
    ${(props) => (props.status === 'P' ? colors.secondary.default : colors.suport.warning)};

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
`;

export const SubTitle = styled.p<{ weight: 'bold' | 'normal' }>`
  font-family: 'Open Sans';
  font-weight: ${(props) => props.weight};
  font-size: 1.8rem;
  line-height: 120%;
  color: ${(props) => (props.weight === 'bold' ? colors.secondary.default : colors.gray.dark01)};
`;

export const TextErro = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 120%;
  color: ${colors.gray.dark02};
  margin-top: 1rem;
  text-align: center;
`;

export const ButtonEditPet = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  background-color: ${colors.secondary.default};
  border-radius: 50%;
  width: 4rem;
  height: 4rem;

  & > svg {
    width: 2rem;
    height: 2rem;
    color: ${colors.gray.white};
  }
`;

export const ChangeDateButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: ${colors.secondary.default};

  & > svg {
    width: 2rem;
    height: 2rem;
    color: ${colors.gray.white};
  }
`;

export const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  place-content: center;

  width: 100%;
  margin: 0 auto 4rem auto;

  @media (max-width: 860px) {
    grid-template-columns: repeat(auto-fit, 300px);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.gray.white};

  text-align: center;

  border-radius: 1rem;
  padding: 2rem 1rem;
  text-align: left;

  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const CardTitle = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.4rem;
  color: ${colors.secondary.default};
`;

export const CardSubtitle = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2rem;
  color: ${colors.gray.dark01};

  padding: 0 2rem;

  margin-top: 1rem;
`;

export const CardComplement = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.6rem;
  color: ${colors.gray.dark01};

  padding: 0 2rem;
  margin: 1rem 0;
  text-align: left;
  width: 100% !important;

  & > span {
    display: block;
  }
`;

export const Subtitle = styled.p`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.4rem;
  color: ${colors.gray.dark01};

  margin-top: 1rem;
`;

export const ButtonBuy = styled.button<{ disabled?: boolean }>`
  display: flex;
  width: calc(100% - 4rem);
  place-content: center;
  margin-top: auto;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;

  border-radius: 3rem;
  padding: 1rem;

  color: ${(props) => (props.disabled ? colors.secondary.default : colors.gray.white)};
  background: ${(props) => (props.disabled ? 'none' : colors.primary.default)};

  transition: all 0.5s ease;

  ${(props) => !props.disabled
    && css`
      :hover {
        color: ${colors.gray.white};
        background: ${colors.primary.default};
      }
    `};

  @media (max-width: 860px) {
    padding: 2rem;
  }
`;

export const ButtonPlus = styled.button`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 2.1rem;
  color: ${colors.primary.default};
  margin-top: 1rem;
`;
