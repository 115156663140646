// import IProfessional from '@monorepo-zazuu/shared/interfaces/IProfessional';
import colors from '@monorepo-zazuu/shared/constants/colors';
import ITruck from '@monorepo-zazuu/shared/interfaces/ITruck';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import Text from '../../components/Text';
import api from '../../services/api';
import ModalAddTruck from './ModalAddTruck';
import ModalTruckDetails from './ModalTruckDetails';
import {
  Card,
  CardColumn,
  CardRow,
  Container,
  List,
  Row,
} from './styles';

const Trucks: React.FC = () => {
  const [trucks, setTrucks] = useState<ITruck[]>([]);
  const [truckSelected, setTruckSelected] = useState<ITruck|undefined>(undefined);
  const [modalAddTruckVisible, setModalAddTruckVisible] = useState(false);

  useEffect(() => {
    try {
      api.get('/professionals/trucks').then((response) => {
        setTrucks(response.data);
      });
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro na busca', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, []);

  const handleSelectTruck = useCallback((truck: ITruck) => {
    setTruckSelected(truck);
  }, []);

  return (
    <Container>
      <Row style={{ justifyContent: 'space-between' }}>
        <Text test="Controle de trucks" size={28} color={colors.gray.dark01} weight="600" align="left" />
        <Button text="Adicionar novo truck" behavior="primary" onClick={() => setModalAddTruckVisible(true)} />
      </Row>
      <List>
        {trucks.map((truck) => (
          <Card key={truck.id} onClick={() => handleSelectTruck(truck)}>
            <CardRow>
              <CardColumn>
                <Text align="left" test={truck.license_plate} color={colors.gray.dark02} size={14} weight="600" ellipsis />
                <Text align="left" test={truck.renavam} color={colors.gray.dark01} size={12} ellipsis />
                <Text align="left" test={truck.chasi_number} color={colors.gray.dark01} size={12} />
              </CardColumn>
            </CardRow>
          </Card>
        ))}
      </List>
      <ModalAddTruck
        visible={modalAddTruckVisible}
        handleCloseModal={() => setModalAddTruckVisible(false)}
        onTruckCreated={(truck) => setTrucks((old) => [...old, truck])}
      />
      {truckSelected && (
      <ModalTruckDetails
        visible={!!truckSelected}
        handleCloseModal={() => setTruckSelected(undefined)}
        truckBasic={truckSelected}
      />
      )}
    </Container>
  );
};

export default Trucks;
