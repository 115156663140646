import colors from '@monorepo-zazuu/shared/constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
`;

export const Table = styled.table`
  background: ${colors.gray.white};
  border: 3px solid ${colors.secondary.default};
  border-collapse: collapse;
  width: 100%;
  margin-top: 24px;

  th {
    background: ${colors.secondary.default};
    padding: 1rem;
    white-space: nowrap;
    padding: 5px;
    color: ${colors.gray.white};
  }

  td {
    white-space: nowrap;
    background: ${colors.gray.white};
    padding: 5px;
    border: 1px solid ${colors.secondary.default};
    color: ${colors.gray.dark01};
    padding: 1rem;
    text-align: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button:first-child {
        margin-right: 1rem;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 2.2rem;
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20%;
`;
