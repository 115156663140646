const orderArrayOfObjects = (array: any[], property: string | number): any[] => {
  const newArray = array.sort((a, b) => {
    if ( a[property] < b[property] ){
      return -1;
    }
    if ( a[property] > b[property] ){
      return 1;
    }
    return 0;
  });
  return newArray
}

export default orderArrayOfObjects;
