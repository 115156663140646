import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  overflow: unset;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const ReportsList = styled.div`
  display: block;
`;

export const Card = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  color: ${colors.gray.dark01};
  margin-bottom: 8px;

  & > div {
    border: 1px solid ${(props) => (props.selected ? colors.secondary.default : colors.suport.alert)};
    background-color: ${(props) => (props.selected ? colors.secondary.default : colors.suport.alert)};
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 4px;
  }

  :hover {
    background-color: ${colors.secondary.default};
    color: ${colors.gray.white};
    transition: all 0.2s ease;

    & > div {
      border: 1px solid ${colors.gray.white};
      transition: all 0.2s ease;
    }
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const CalendarOption = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > p {
    color: ${colors.gray.dark02};
    font-size: 12px;
    margin-left: 2.3rem;
  }

  @media (max-width: 500px) {
    width: 48%;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ProfessionalOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;

  > p {
    color: ${colors.gray.dark02};
    font-size: 12px;
    margin-left: 2.3rem;
  }
`;
