import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import IOrder from '@monorepo-zazuu/shared/interfaces/IOrder';
import StatusTransactionEnum from '../../../utils/enums/StatusTransactionEnum';
import TypePaymentEnum from '../../../utils/enums/TypePaymentEnum';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { Td } from '../styles';
import { Option, Row } from './styles';

interface IProps {
  typePaymentDefault: number;
  order_id: string;
  removeFromList: (order_id: string) => void;
  updateFromList: (order: IOrder) => void;
}

const SelectToPay: React.FC<IProps> = ({
  typePaymentDefault, order_id, removeFromList, updateFromList,
}) => {
  const [typePayment, setTypePayment] = useState(typePaymentDefault);
  const [loadingChangePaymentType, setLoadingChangePaymentType] = useState(false);
  const [loadingPaided, setLoadingPaided] = useState(false);
  const [loadingGenerateLink, setLoadingGenerateLink] = useState(false);
  const [newLink, setNewLink] = useState('');

  const handleChangePaymentType = useCallback(async (type_payment: number) => {
    if (loadingChangePaymentType) {
      return;
    }

    try {
      setLoadingChangePaymentType(true);
      const response = await api.put<IOrder>(
        '/orders/change-type-payment', {
        order_id,
        type_payment,
      },
      );

      updateFromList(response.data);
      setTypePayment(type_payment);

      setLoadingChangePaymentType(false);

      toast('Forma de pagamento alterada com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      setLoadingChangePaymentType(false);
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [order_id, loadingChangePaymentType, updateFromList]);

  const handleConfirmAsPaided = useCallback(async (): Promise<void> => {
    try {
      setLoadingPaided(true);

      await api.put<IOrder>(
        '/orders/change-status', {
        order_id,
        status: StatusTransactionEnum.paided,
      },
      );

      removeFromList(order_id);

      setLoadingPaided(false);
      toast('Status mudado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      setLoadingPaided(false);
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [order_id, removeFromList]);

  const handleGenerateNewLink = useCallback(async (): Promise<void> => {
    try {
      setLoadingGenerateLink(true);

      const response = await api.put<IOrder>(
        '/orders/change-link-payment', {
        order_id,
      },
      );
      response.data.transaction?.transaction_pagseguro_id && setNewLink(response.data.transaction?.transaction_pagseguro_id);

      setLoadingGenerateLink(false);
      toast('Link gerado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      setLoadingGenerateLink(false);
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [order_id]);

  return (
    <>
      <Td>
        <Row>
          <Option onClick={() => handleChangePaymentType(TypePaymentEnum.transferencia)} selected={typePayment === TypePaymentEnum.transferencia}>Transferência</Option>
          <Option onClick={() => handleChangePaymentType(TypePaymentEnum.pix)} selected={typePayment === TypePaymentEnum.pix}>Pix</Option>
          <Option onClick={() => handleChangePaymentType(TypePaymentEnum.dinheiro)} selected={typePayment === TypePaymentEnum.dinheiro}>Dinheiro</Option>
          <Option onClick={() => handleChangePaymentType(TypePaymentEnum.link)} selected={typePayment === TypePaymentEnum.link}>Link</Option>
          <Option onClick={() => handleChangePaymentType(TypePaymentEnum.linkavulso)} selected={typePayment === TypePaymentEnum.linkavulso}>Link avulso</Option>
        </Row>
      </Td>
      <Td>
        <Button behavior="primary" text="Pago" disabled={typePayment === TypePaymentEnum.link || typePayment === TypePaymentEnum.newlink} loading={loadingPaided} onClick={() => handleConfirmAsPaided()} />
      </Td>
      <Td>
        <Row>
          <Button behavior="secondary" text="Gerar link" loading={loadingGenerateLink} onClick={() => handleGenerateNewLink()} disabled={typePayment !== TypePaymentEnum.link && typePayment !== TypePaymentEnum.newlink} />
          {newLink && <p style={{ whiteSpace: 'pre-wrap', marginLeft: '1rem' }}>{`${process.env.REACT_APP_LINK_REDIRECT_PAGSEGURO}${newLink}`}</p>}
        </Row>
      </Td>
    </>
  );
};

export default SelectToPay;
