import colors from '@monorepo-zazuu/shared/constants/colors';
import IService from '@monorepo-zazuu/shared/interfaces/IService';
import React from 'react';
import { FiEdit } from 'react-icons/fi';
import Text from '../../../components/Text';
import ServiceTypeLayoutEnum from '../../../utils/enums/ServiceTypeLayoutEnum';
import * as Styles from './styles';

interface Iprops {
  services: IService[];
  type_layout: number;
  handleEdit:(service_id: string) => void;
}

const TableServices: React.FC<Iprops> = ({ services, type_layout, handleEdit }) => {
  return (
    <Styles.Container>
      <Styles.Table>
        <thead>
        <tr>
          <th>
            <Text
              test="ordem"
              size={18}
              weight="600"
              color={colors.gray.white}
            />
          </th>
          <th>
            <Text
              test="item"
              size={18}
              weight="600"
              color={colors.gray.white}
            />
          </th>
          <th>
            <Text
              test="layout"
              size={18}
              weight="600"
              color={colors.gray.white}
            />
          </th>
          <th>
            <Text
              test="preço"
              size={18}
              weight="600"
              color={colors.gray.white}
            />
          </th>
          <th>
            <Text
              test="status"
              size={18}
              weight="600"
              color={colors.gray.white}
            />
          </th>
          <th>
            <Text
              test="ações"
              size={18}
              weight="600"
              color={colors.gray.white}
            />
          </th>
        </tr>
        </thead>
        <tbody>
        {services.map((service, index) => (
          <tr key={service.id}>
            <td>
              {index === 0 && services.length > 1 && <Text
                test={'para_baixo'}
                size={18}
                color={colors.gray.dark03}
              />}
              {index === (services.length - 1) && services.length > 1 && <Text
                test={'para_cima'}
                size={18}
                color={colors.gray.dark03}
              />}

            </td>
            <td>
              <Text
                test={service.name}
                size={18}
                color={colors.gray.dark03}
              />
            </td>
            <td>
              <Text
                test={ServiceTypeLayoutEnum[type_layout]}
                size={18}
                color={colors.gray.dark03}
              />
            </td>
            <td>
              <Text
                test="-"
                size={18}
                color={colors.gray.dark03}
              />
            </td>
            <td>
              <Text
                test={service.status ? 'Ativo' : 'Inativo'}
                size={18}
                color={colors.gray.dark03}
              />
            </td>
            <td>
              <Styles.Row>
                <div>
                  <button
                    type="button"
                    onClick={() => handleEdit(service.id)}
                  >
                    <FiEdit color={colors.gray.dark02} />
                  </button>
                </div>
              </Styles.Row>
            </td>
          </tr>
        ))}
        </tbody>
      </Styles.Table>
    </Styles.Container>
  );
}


export default TableServices;
