/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { FaSpinner } from 'react-icons/fa';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import colors from '@monorepo-zazuu/shared/constants/colors';

import client from '../../services/api-graphql';

import Button from '../../components/Button';
import Text from '../../components/Text';
import Select from '../../components/Select';
import StatusAnamneseEnum from '../../utils/enums/StatusAnamneseEnum';
import masks from '../../utils/masks';

import catProfile from '../../assets/cat-profile.png';
import dogProfile from '../../assets/dog-profile.png';

import * as Styles from './styles';

interface IDataReports {
  anamnese: any;
  professional: any;
}

interface IAnamnese {
  id: string;
  allergy: boolean;
  allergy_detail: string;
  status: number | null;
  avaliation: string;
  temperament: string;
  complaint: string;
  castrated: boolean;
  feed: string;
  feed_diary: string;
  skin_hygiene: string;
  skin_secretion: string;
  skin_peeling: string;
  eyes_red: boolean;
  eyes_whitish: boolean;
  eyes_secretion: boolean;
  eyes_visual_deficit: boolean;
  respiration_cough: boolean;
  respiration_sneeze: boolean;
  respiration_secretion: boolean;
  respiration_noise: boolean;
  heart_exercise_intolerance: boolean;
  heart_tiredness: boolean;
  digestive_appetite: string;
  digestive_vomit: boolean;
  digestive_diarrhea: boolean;
  urinary_water: string;
  urinary_aspect: string;
  urinary_volume: string;
  urinary_frequency: string;
  reproductive_secretion: boolean;
  reproductive_heat: boolean;
  reproductive_contraceptive: boolean;
  reproductive_puppies: string;
  locomotor_lameness: boolean;
  locomotor_trauma: boolean;
  locomotor_volume: boolean;
  locomotor_impotence: boolean;
  neurologic_convulsion: boolean;
  neurologic_syncope: boolean;
  neurologic_deficit: boolean;
  created_at: Date;
  pet: {
    id: string;
    avatar_url: string;
    birthday: string;
    breed: { name: string };
    gender: 'M' | 'F';
    specie: { id: string; name: 'gato' | 'cachorro' };
    name: string;
    weight: number;
  };
  professional: {
    user: {
      name: string;
    };
  };
  tutor: {
    name: string;
    phone: string;
  };
}

interface IVet {
  id: string;
  user: {
    name: string;
    roles: {
      id: string;
      name: string;
    };
  };
}

const AnamneseControl: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [anamneses, setAnamneses] = useState<IAnamnese[]>([]);
  const [selectedAnamnese, setSelectedAnamnese] = useState<IAnamnese>();
  const [vets, setVets] = useState<IVet[]>([]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await client.query({
        query: gql`
          query($filters: FiltersAnamneseInputType) {
            anamneses(filters: $filters) {
              id
              status
              created_at
              allergy
              allergy_detail
              avaliation
              castrated
              complaint
              digestive_appetite
              digestive_diarrhea
              digestive_vomit
              eyes_red
              eyes_secretion
              eyes_visual_deficit
              eyes_whitish
              feed
              feed_diary
              heart_exercise_intolerance
              heart_tiredness
              locomotor_impotence
              locomotor_lameness
              locomotor_trauma
              locomotor_volume
              neurologic_convulsion
              neurologic_deficit
              neurologic_syncope
              reproductive_contraceptive
              reproductive_heat
              reproductive_puppies
              reproductive_secretion
              respiration_sneeze
              skin_hygiene
              skin_peeling
              skin_secretion
              temperament
              urinary_aspect
              urinary_frequency
              urinary_volume
              urinary_water
              professional_id
              professional {
                user {
                  name
                }
              }
              pet {
                id
                avatar_url
                name
                specie {
                  name
                }
                breed {
                  name
                }
                birthday
                gender
                weight
              }
              tutor {
                name
                phone
              }
            }
            professionals {
              id
              user {
                name
                roles {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          filters: {
            where: {
              status: StatusAnamneseEnum.Pendente,
              professional_id: null,
            },
          },
        },
      });

      const vetsTemp = [];

      for (const vet of response.data.professionals) {
        for (const role of vet.user.roles) {
          if (role.name === 'veterinário') {
            vetsTemp.push(vet);
          }
        }
      }

      setAnamneses(response.data.anamneses);
      setVets(vetsTemp);

      setLoading(false);
    } catch (error) {
      toast(error.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, []);

  const handleSubmit = useCallback(async (data: IDataReports) => {
    try {
      setLoading(true);

      if (!data.anamnese) {
        toast('Favor, selecionar uma ficha de anamnese para atualizar', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });

        setLoading(false);

        return;
      }

      if (!data.professional) {
        toast('Favor, selecionar um veterinário para alocar a ficha', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });

        setLoading(false);

        return;
      }

      await client.mutate({
        mutation: gql`
          mutation($anamnese: UpdateAnamneseInputType!) {
            UpdateAnamneseByVet(anamnese: $anamnese) {
              id
            }
          }
        `,
        variables: {
          anamnese: {
            id: data.anamnese.id,
            professional_id: data.professional,
          },
        },
      });

      toast('Ficha alocada com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });

      setSelectedAnamnese(undefined);
      setAnamneses([]);
      loadData();
    } catch (error) {
      setLoading(false);

      toast('Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [loadData]);

  const handleSelectAnamnese = useCallback((sheet) => {
    // console.log('DATA: ', sheet.value);

    setSelectedAnamnese(sheet.value);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Styles.Container>
      {!loading ? (
        <>
          <Styles.Title>Controle das Fichas de Anamnese</Styles.Title>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Styles.FormContainer>
              <Select
                name="anamnese"
                containerStyle={{ marginTop: '2rem' }}
                placeholder="Selecione uma ficha"
                options={anamneses.map((anamnese) => ({
                  value: anamnese,
                  label: `${anamnese.tutor.name} | ${anamnese.pet.name}`,
                }))}
                onChange={(value) => handleSelectAnamnese(value)}
                icon={IoMdArrowDropdown}
              />

              <Styles.FormSpace />

              {selectedAnamnese && (
                <>
                  <Select
                    name="professional"
                    placeholder="Aloque um profissional"
                    options={vets.map((vet) => ({
                      value: vet.id,
                      label: vet.user.name,
                    }))}
                    icon={IoMdArrowDropdown}
                  />

                  <Styles.SheetContainer>
                    <Styles.ImgDiv>
                      <Styles.PuppiesImgDiv specie={selectedAnamnese?.pet?.specie?.name}>
                        <Styles.PuppiesImg
                          src={
                            selectedAnamnese?.pet?.avatar_url
                              ? selectedAnamnese?.pet.avatar_url
                              : (
                                selectedAnamnese?.pet?.specie?.name === 'cachorro'
                                  ? dogProfile
                                  : catProfile
                              )
                          }
                        />
                      </Styles.PuppiesImgDiv>
                    </Styles.ImgDiv>

                    <Styles.InfoDiv>
                      <Styles.TextContainer>
                        <Styles.TextDiv>
                          <Text
                            test="Nome do pet: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={selectedAnamnese?.pet?.name}
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>

                        <Styles.TextDiv>
                          <Text
                            test="Idade: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={
                              formatDistanceToNow(
                                parseISO(selectedAnamnese?.pet?.birthday), { locale: ptBR },
                              ).replace('cerca de', '') || 'Sem idade'
                            }
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>

                        <Styles.TextDiv>
                          <Text
                            test="Raça: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={selectedAnamnese?.pet?.breed?.name}
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>

                        <Styles.TextDiv>
                          <Text
                            test="Gênero: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={selectedAnamnese?.pet?.gender === 'M' ? 'Macho' : 'Fêmea'}
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>

                        <Styles.TextDiv>
                          <Text
                            test="Tutor: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={selectedAnamnese?.tutor?.name}
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>

                        <Styles.TextDiv>
                          <Text
                            test="Telefone: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={masks.phone(selectedAnamnese?.tutor?.phone)}
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>

                        <Styles.TextDiv>
                          <Text
                            test="Profissional: "
                            align="left"
                            color={colors.gray.dark03}
                            size={18}
                            weight="400"
                            letterSpacing={2}
                          />

                          <Text
                            test={selectedAnamnese?.professional?.user?.name || 'Não alocado'}
                            align="left"
                            color={colors.gray.dark02}
                            size={16}
                            weight="400"
                            marginLeft={5}
                          />
                        </Styles.TextDiv>
                      </Styles.TextContainer>

                      <Styles.StatusContainer>
                        <Text
                          test="Criada: "
                          align="left"
                          color={colors.gray.dark02}
                          size={16}
                          weight="400"
                          letterSpacing={2}
                        />

                        <Text
                          test={format(new Date(selectedAnamnese?.created_at), 'dd/MM/yyyy')}
                          align="left"
                          color={colors.suport.green}
                          size={14}
                          marginRight={32}
                          weight="400"
                          marginLeft={5}
                        />

                        <Text
                          test="Status: "
                          align="left"
                          color={colors.gray.dark02}
                          size={16}
                          weight="400"
                          letterSpacing={2}
                        />

                        <Text
                          test={StatusAnamneseEnum[selectedAnamnese?.status!]}
                          align="left"
                          color={colors.suport.alert}
                          size={14}
                          weight="400"
                          marginLeft={5}
                        />
                      </Styles.StatusContainer>
                    </Styles.InfoDiv>
                  </Styles.SheetContainer>
                </>
              )}
            </Styles.FormContainer>

            {selectedAnamnese && (
              <Button
                behavior="primary"
                type="submit"
                text="Salvar"
                style={{ width: '100%' }}
                loading={loading}
              />
            )}
          </Form>
        </>
      ) : (
        <Styles.LoadingDiv>
          <FaSpinner size={40} />
        </Styles.LoadingDiv>
      )}
    </Styles.Container>
  );
};

export default AnamneseControl;
