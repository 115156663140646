import React from 'react';
import {
  OptionTypeBase,
  Props as SelectProps
} from 'react-select';
import * as Styles from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  styleContainer?: React.CSSProperties;
  error?: boolean;
}

const Select: React.FC<Props> = ({ styleContainer, error = false, ...rest }) => (
  <Styles.Container style={styleContainer}>
    <Styles.ReactSelectStyled
      classNamePrefix="react-select"
      error={error}
      {...rest}
    />
  </Styles.Container>
)

export default Select;
