import styled, {css} from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';
import AppointmentStatusEnum from '../../../utils/enums/AppointmentStatusEnum';

export const Content = styled.div`
  text-align: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Card = styled.button`
  background-color: ${colors.secondary.default};
  border-radius: 8px;
  margin-top: 16px;
  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.4);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.4);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.4);
`;

export const CardHeader = styled.div<{selected: boolean; status: number}>`
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${props => props.selected ? colors.secondary.default : colors.gray.dark03};
  ${props => props.status === AppointmentStatusEnum['Aguardando pagamento'] && css`
    background-color: ${colors.suport.warning};
  `}
`;

export const CardContent = styled.div<{selected: boolean}>`
  background-color: ${colors.gray.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  ${props => props.selected && css`
    border: 2px solid ${colors.secondary.default};
  `}
`;

export const CardAddress = styled.div`
  padding: 8px;
`;

export const Button = styled.button`
  margin-left: auto;
`;

export const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 8px 8px 8px;
`;
