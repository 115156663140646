import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px - 4rem);
  padding: 2rem;

  margin-bottom: calc(((100vw) / 5) + 2rem);

  @media (max-width: 500px) {
    width: 100%;
  }

  & > form {
    width: 50%;
    margin: 0 auto;

    @media (max-width: 860px) {
      width: 90%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const Subtitle = styled.p`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
