import Select from 'react-select/async';
import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const SelectStyled = styled(Select)`
  color: ${colors.gray.dark01};
  box-shadow: none;
  border-color: transparent;

  .react-select__option {
    background-color: ${colors.gray.light01};

    :hover {
      background-color: ${colors.gray.light02};
    }
  }

  .react-select__option--is-selected {
    color: ${colors.secondary.default};
  }

  .react-select__control {
    height: 4.8rem;
    border-radius: 2.4rem;
    padding-left: 1rem;
    background-color: ${colors.gray.light02};
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${colors.secondary.default};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }

  .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px ${colors.secondary.default};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }
`;
