enum AppointmentStatusEnum {
  'Aguardando pagamento' = 1,
  'Pago' = 2,
  'A caminho' = 3,
  'No local' = 4,
  'Em andamento' = 5,
  'Procedimento finalizado' = 6,
  'Atendimento finalizado' = 7,
  'Cancelado' = -1,
};

export default AppointmentStatusEnum;
