import styled from 'styled-components';

import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${colors.gray.light02};
  width: 100%;
  height: calc(100vh - 15.2rem);

  & > img {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 2;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 7.2rem;

  justify-content: center;
  align-items: center;

  position: relative;

  background: ${colors.gray.white};
  box-shadow: 0 0 5px 6px rgba(0, 0, 0, 0.1);

  img {
    height: 6rem;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5rem;
  z-index: 3;
  margin: auto 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  display: flex;
  flex-direction: column;
  max-width: 400px;

  background: ${colors.gray.white};
  padding: 3rem;
  margin: auto 0;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3rem;

  h1 {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 3.7rem;
    line-height: 120%;

    color: ${colors.gray.dark02};

    margin-bottom: 2rem;
  }

  h1 + div {
    margin: 1rem 0;
  }

  div {
    margin: 0.5rem 0;
    text-align: center;
  }

  a {
    font-family: 'Open Sans';
    font-size: 1.3rem;
    line-height: 150%;

    color: ${colors.primary.default};

    text-decoration: none;
  }

  p {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 150%;

    text-align: center;

    margin: 1rem;

    color: ${colors.suport.alert};
  }

  p + a {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4.8rem;
    background: ${colors.gray.white};
    transition: border 0.3s;
    transition: color 0.3s;

    border: 2px solid ${colors.gray.dark02};
    border-radius: 2.4rem;

    font-family: Open Sans;
    font-size: 2.1rem;
    line-height: 120%;
    color: ${colors.gray.dark02};

    padding: 0 3.5rem;

    margin-right: 1rem;

    :hover {
      border: 2px solid ${colors.primary.default};
      color: ${colors.primary.default};
    }
  }
`;

export const ButtonSignIn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  border: none;
  border-radius: 5rem;

  margin-top: 2rem;

  height: 5.6rem;

  background: ${colors.primary.default};

  font-family: 'Open Sans';
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.light02};
  transition: background 0.2s;

  :hover {
    background: ${colors.primary.default};
  }

  svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media(max-width: 500px) {
    height: 6rem;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.gray.light02};
`;
