import colors from '@monorepo-zazuu/shared/constants/colors';
import styled from 'styled-components';
import Button from '../../components/Button';
import InputRef from '../../components/InputRef';
import SelectRef from '../../components/SelectRef';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
`;

export const Input = styled(InputRef)`
  display: flex;
  flex-direction: row;
  height: 4.8rem;
  border: 2px solid ${colors.secondary.default} !important;
  border-radius: 3rem 0 0 3rem !important;
  padding: 0 2rem !important;

  font-family: 'Open Sans';
  font-size: 2.4rem;
  line-height: 150%;
  color: ${colors.gray.dark02};
  background: ${colors.gray.white} !important;

  @media(max-width: 500px) {
    border-radius: 3rem !important;
  }
`;

export const Select = styled(SelectRef)`
  .react-select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${colors.gray.light02};
  }

  @media (max-width: 500px) {
    margin: 2rem 0;

    .react-select__control {
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;

export const ButtonStyled = styled(Button)`
  margin-left: 2rem;

  @media(max-width: 500px) {
    margin-left: 0;
  }
`;

export const List = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  justify-content: left;
  margin-top: 2rem;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 4rem 2rem;
  }
`;

export const Card = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray.white};
  padding: 1rem 3rem;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 2px solid ${colors.gray.white};
  overflow: hidden;

  :hover {
    border: 2px solid ${colors.secondary.default};
  }

  @media (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 160px;
`;

export const Photo = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  resize: contain;
`;

export const Tag = styled.div`
  display: flex;
  background-color: ${colors.gray.light02};
  border-radius: 5px;
  padding: 3px 10px;

  margin-left: 1rem;
  :first-of-type {
    margin-left: 0;
  }
`;
