import React, { InputHTMLAttributes } from 'react';
import { Container, Input } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  option1: string;
  option2: string;
  styleContainer?: object;
  onChangeOption: (option: boolean) => void;
}

const Switch: React.FC<IProps> = ({ checked, option1, option2, onChangeOption, styleContainer }) => {
  return (
    <Container style={styleContainer}>
      {option1 && (
        <Input
          key={option1}
          type="button"
          value={option1}
          checked={checked}
          onChange={() => { }}
          onClick={() => {
            onChangeOption && onChangeOption(true);
          }}
        />
      )}
      {option2 && (
        <Input
          key={option2}
          type="button"
          value={option2}
          checked={!checked}
          onChange={() => { }}
          onClick={() => {
            onChangeOption && onChangeOption(false);
          }}
        />
      )}
    </Container>
  );
};

export default Switch;
