import ceps from '../constants/ceps';

function verifyCepZone(cep) {
  const cepClean = cep.replace(/[^0-9 ]/g, '');
  const cepMacroRegion = cepClean.substring(0, 3);
  const cepMicroRegion = cepClean.substring(0, 5);

  const cepFounded = ceps.find((c) => (c === cepMacroRegion || c === cepMicroRegion));
  return !!cepFounded;
}

export default verifyCepZone;
