import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import InputRef from '../../components/InputRef';
import colors from '@monorepo-zazuu/shared/constants/colors';
import api from '../../services/api';
import { Container, Subtitle, Title } from './styles';

interface IFormData {
  customer_cpf: string;
  coupon_code: string;
}

const AddCouponToCustomer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = async (data: IFormData) => {
    try {
      const {
        data: { user, coupon },
      } = await api.post('/coupons/add-to-customer', {
        customer_cpf: data.customer_cpf,
        coupon_code: data.coupon_code,
      });

      toast(
        `Cupom de ${coupon.type === 'A' ? 'R$' : ''}${coupon.amount}${coupon.type === 'P' ? '%' : ''
        } adicionado para ${user.name}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'success',
        },
      );
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <Title>Cupons</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>Código do cupom</Subtitle>
        <InputRef name="coupon_code" placeholder="código" />
        <Subtitle>CPF do cliente</Subtitle>
        <InputRef
          name="customer_cpf"
          placeholder="cpf do cliente"
          style={{
            paddingRight: '2rem',
            color: colors.gray.dark01,
            marginBottom: '4rem',
          }}
        />
        <Button
          behavior="primary"
          text="adicionar Cupom ao cliente"
          type="submit"
          style={{ width: '100%' }}
        />
      </Form>
    </Container>
  );
};

export default AddCouponToCustomer;
