import Resizer from 'react-image-file-resizer';

const resizeImage = (
  file: File,
): Promise<string | Blob | ProgressEvent<FileReader>> =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      50,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
    );
  });

export default resizeImage;
