import { gql } from '@apollo/client';
import colors from '@monorepo-zazuu/shared/constants/colors';
import ICategory from '@monorepo-zazuu/shared/interfaces/ICategory';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import Button from '../../../components/web/Button';
import Input from '../../../components/web/Input';
import Select from '../../../components/web/Select';
import Switch from '../../../components/web/Switch';
import client from '../../../services/api-graphql';
import * as Styles from './styles';

interface IProps {
  visible: boolean;
  categoriesToSelect: {value: string; label: string;}[];
  closeModal: () => void;
  afterAddCategory: (category: ICategory, isPrimary: boolean) => void;
}

const ModalAddCategory: React.FC<IProps> = ({visible, closeModal, afterAddCategory, categoriesToSelect}) => {
  const [isPrimary, setIsPrimary] = useState(true);
  const [name, setName] = useState('');
  const [category, setCategory] = useState<{value: string; label: string;}| null | undefined>();

  const handleSubmit = useCallback(async () => {
    if(!name) {
      toast('Insira o nome da categoria', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
      return
    }
    if(isPrimary) {
      const results = await client.mutate({
        mutation: gql`
          mutation {
            addCategory(name: "${name}") {
              id
              name
            }
          }
        `
      });
      afterAddCategory({
        ...results.data.addCategory,
        services: [],
        subcategories: []
      },
      isPrimary);
    } else {
      if(!category) {
        toast('Selecione a categoria principal', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
        return;
      }

      const results = await client.mutate({
        mutation: gql`
          mutation {
            addSubcategory(name:"${name}", category_id: "${category.value}") {
              id
              name
            }
          }
        `
      });
      afterAddCategory({...results.data.addCategory, services: []}, isPrimary);
    }
  }, [isPrimary, name, category, afterAddCategory])

  return (
    <Modal visible={visible} closeModal={closeModal}>
      <Styles.Content>
        <Text
          test="Tipo de categoria"
          size={18}
          color={colors.primary.default}
          align="left"
          weight="700"
          marginBottom={8}
        />
        <Styles.Row>
          <Switch
            checked={isPrimary}
            option1="Principal"
            option2="Secundária"
            onChangeOption={() => {setIsPrimary(old => !old); setCategory(undefined)}}
          />
        </Styles.Row>
        <Text
          test="Nome da categoria"
          size={18}
          color={colors.primary.default}
          align="left"
          weight="700"
          marginTop={8}
          marginBottom={8}
        />
        {!isPrimary && (
          <Select
            options={categoriesToSelect}
            onChange={(value: any) => setCategory(value)}
            styleContainer={{marginBottom: 8}}
          />
        )}
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="banho, combo, vacinas,etc."
        />
        <Styles.Row>
          <Button
            behavior="secondary"
            text="Cancelar"
            onClick={closeModal}
          />
          <Button
            behavior="primary"
            text="Salvar"
            styleContainer={{marginLeft: 8}}
            onClick={handleSubmit}
          />
        </Styles.Row>
      </Styles.Content>
    </Modal>
  )
}

export default ModalAddCategory;
