import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IoMdArrowDropdown } from 'react-icons/io';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import InputRef from '../../components/InputRef';
import Select from '../../components/Select';
import Table from '../../components/Table';

import api from '../../services/api';

import * as Styles from './styles';

interface IDataReports {
  report: string;
  from: Date;
  to: Date;
}

const Reports: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [results, setResults] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: IDataReports): Promise<void> => {
    setResults([]);
    setCsvData([]);

    if (
      data.from.toString() === ''
      || data.to.toString() === ''
      || data.report === ''
    ) {
      toast('Insira todos os dados', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'warning',
      });

      return;
    }

    try {
      setLoading(true);

      const response = await api.get(
        `/reports/${data.report.toString()}?from=${data.from}&to=${data.to}`,
      );

      if (response.data.length > 0) {
        const tempCsvData = [] as any[];

        response.data.forEach((reportItem: any) => {
          tempCsvData.push({
            id: reportItem.id,
            status: reportItem.status,
            forma_pagamento: reportItem.forma_pagamento,
            plataforma: reportItem.plataforma,
            data: reportItem.data,
            periodo: reportItem.periodo,
            tutor: reportItem.tutor,
            pet: reportItem.pet,
            raca: reportItem.raca,
            sexo: reportItem.sexo,
            specie: reportItem.specie,
            porte: reportItem.porte,
            endereco_completo: reportItem.endereco_completo,
            servico: reportItem.servico,
            valor_serviço: reportItem.valor_serviço,
            adicionais: reportItem.adicionais,
            valor_adicionais: reportItem.valor_adicionais,

            valor_total: reportItem.valor_total,
            temp_valor_total: reportItem.temp_valor_total || 'sem dado',

            desconto_admin: reportItem.desconto_admin,
            coupon: reportItem.coupon,
            gerador: reportItem.gerador,
            gerado_em: reportItem.gerado_em,
            observacoes: reportItem.observacoes,
            acrescimo_admin: reportItem.acrescimo_admin,
            motivo_acrescimo: reportItem.motivo_acrescimo,
            cep: reportItem.address?.cep || 'sem dado',
            estado: reportItem.address?.state || 'sem dado',
            cidade: reportItem.address?.city || 'sem dado',
            rua: reportItem.address?.street || 'sem dado',
            bairro: reportItem.address?.neighborhood || 'sem dado',
            numero: reportItem.address?.number || 'sem dado',
            complemento: reportItem.address?.complement || 'sem dado',
          });
        });

        setCsvData(tempCsvData as any);

        setResults(response.data.map((row: any) => {
          delete row.address;

          return row;
        }));
      } else {
        toast('Nenhum dado encontrado', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'warning',
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast('ERRO NO SERVIDOR', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  };

  const handleRemoveAppointment = useCallback(async (id: string | undefined) => {
    try {
      await api.delete(`/appointments/${id}`);

      setResults((oldState) => oldState.filter((old: any) => old.id !== id));
    } catch (error) {
      toast(error?.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, []);

  return (
    <Styles.Container>
      <Styles.Title>Relatórios</Styles.Title>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Select
          name="report"
          containerStyle={{ marginTop: '2rem' }}
          placeholder="relatórios"
          options={[
            { value: 'generate-calendar', label: 'agendamentos' },
            { value: 'generate-new-customers', label: 'novos usuarios' },
          ]}
          icon={IoMdArrowDropdown}
        />

        <Styles.Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <InputRef
            name="from"
            style={{ paddingRight: '2rem' }}
            placeholder="data inicial"
            type="date"
          />

          <InputRef
            name="to"
            containerStyle={{ marginLeft: '2rem' }}
            style={{ paddingRight: '2rem' }}
            placeholder="data final"
            type="date"
          />
        </Styles.Row>

        <Button
          behavior="primary"
          type="submit"
          text="Buscar"
          style={{ width: '100%' }}
          loading={loading}
        />
      </Form>

      {loading && 'carregando...'}

      {results.length > 0 && (
        <Table
          data={results}
          removeProperties={['id']}
          action={formRef.current?.getFieldValue('report') === 'generate-calendar' ? handleRemoveAppointment : undefined}
          style={{ marginTop: '4rem' }}
        />
      )}

      {csvData.length > 0 && <Styles.CsvDownloadStyled data={csvData} />}
    </Styles.Container>
  );
};

export default Reports;
