import React, { useRef, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import cepPromise from 'cep-promise';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalContent,
  TitleFields,
  CepContainer,
  ButtonSubmit
} from './styles';
import IAddress from '@monorepo-zazuu/shared/interfaces/IAddress';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';
import InputRef from '../InputRef';
import masks from '../../utils/masks';

const ModalAddress: React.FC<{
  closeModal(): void;
  initialValues?: IAddress;
  visible: boolean;
  onSubmitForm(address: IAddress): void;
}> = ({ closeModal, visible, initialValues, onSubmitForm }) => {
  const { updateCustomerDetails } = useAuth();
  const modalAddressRef = useRef<HTMLDivElement>(null);
  const formAddressRef = useRef<FormHandles>(null);
  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (
        modalAddressRef.current &&
        !modalAddressRef.current.contains(event.target)
      ) {
        if (visible) {
          closeModal();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalAddressRef, closeModal, visible]);

  const applyMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, mask?: string) => {
      formAddressRef.current?.setFieldValue(
        e.target.name,
        mask ? masks[mask](e.target.value) : e.target.value
      );
    },
    []
  );

  const handleSubmit = async (data: IAddress) => {
    if (data.cep) {
      if(initialValues){

        await api
        .put('/addresses', {
          ...data,
          id: initialValues?.id
        })
        .then(response => {
          updateCustomerDetails({ cep: response.data.cep });
          onSubmitForm(response.data);
          closeModal();
        });
      } else {
        await api
        .post('/addresses', data)
        .then(response => {
          onSubmitForm(response.data);
          closeModal();
        });
      }
    } else {
      formAddressRef.current?.setErrors({ cep: 'Erro ao atualizar o perfil' });
    }
  };

  const searchFullAddress = useCallback(async (cep: string) => {
    try {
      const address = await cepPromise(cep);
      formAddressRef.current?.setData({
          street: address.street,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state
      });
    } catch (error) {
      toast('Erro ao buscar o CEP', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error'
      });
      formAddressRef.current?.reset();
      formAddressRef.current?.setErrors({ cep: 'Erro com o CEP' });
    }
  }, []);

  return (
    <Modal visible={visible}>
      <ModalContent ref={modalAddressRef}>
        <Form onSubmit={handleSubmit} ref={formAddressRef}>
          <TitleFields>Endereço</TitleFields>
          <CepContainer>
            <InputRef
              placeholder="cep"
              name="cep"
              containerStyle={{
                flex: 1,
                marginTop: '1rem'
              }}
              onChange={e => applyMask(e, 'cep')}
              onBlurExecute={searchFullAddress}
            />
            <InputRef
              placeholder="nº"
              name="number"
              containerStyle={{
                width: '8rem',
                marginTop: '1rem',
                marginLeft: '1rem'
              }}
            />
          </CepContainer>
          <InputRef
            placeholder="rua"
            name="street"
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              opacity: 0.5
            }}
            disabled
          />
          <InputRef
            placeholder="bairro"
            name="neighborhood"
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              opacity: 0.5
            }}
            disabled
          />
          <InputRef
            placeholder="cidade"
            name="city"
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              opacity: 0.5
            }}
            disabled
          />
          <InputRef
            placeholder="estado"
            name="state"
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              opacity: 0.5
            }}
            disabled
          />
          <InputRef
            placeholder="complemento"
            name="complement"
            autoComplete="off"
            style={{
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
          />
          <ButtonSubmit type="submit">{initialValues ? 'atualizar' : 'adicionar'}</ButtonSubmit>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddress;
