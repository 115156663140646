import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';
import InputRef from '../../components/InputRef';
import SelectRef from '../../components/Select';
import Button from '../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1366px;
  width: 100%;
  padding: 2rem;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    padding: 2rem;
    height: 100%;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;

  & > form {
    display: flex;
    width: 100%;
  }

  @media (max-width: 850px) {
    margin-top: 2rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;

export const Input = styled(InputRef)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4.8rem;
  border: 2px solid ${colors.secondary.default} !important;
  border-radius: 3rem 0 0 3rem !important;
  padding: 0 2rem !important;

  font-family: 'Open Sans';
  font-size: 2.4rem;
  line-height: 150%;
  color: ${colors.gray.dark02};
  background: ${colors.gray.white} !important;

  @media(max-width: 500px) {
    border-radius: 3rem !important;
  }
`;

export const Select = styled(SelectRef)`
  width: 200px;

  .react-select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${colors.gray.light02};
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 2rem 0;

    .react-select__control {
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  margin-left: 2rem;
  width: 100%;

  @media(max-width: 500px) {
    margin-left: 0;
  }
`;

export const ContainerListCustomers = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Title = styled.p`
  font-family: 'Open Sans';
  font-size: 2.4rem;
  line-height: 150%;
  font-weight: bold;
  color: ${colors.gray.dark02};

  margin-bottom: 1rem;
`;

export const ListCustomers = styled.table`
  margin-top: 1rem;
  width: 100%;
`;

export const ListCustomersHeader = styled.tr`
  background: ${colors.secondary.default};
  color: ${colors.gray.white} !important;
`;

export const ListCustomersRow = styled.tr`
  background: ${colors.gray.white};
  :hover {
    background: ${colors.primary.default};
  }
`;

export const TextHeader = styled.th`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;
  color: ${colors.gray.white};
  white-space: nowrap;
  padding: 1rem;
`;

export const Text = styled.td`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  color: ${colors.secondary.default};
  white-space: nowrap;
  padding: 1rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 8rem;

  & > div {
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    flex-direction: row;

    margin-left: 0;
    overflow-x: auto;
    padding-bottom: 3rem;

    & > div {
      margin: 0 0.5rem;
    }
  }
`;

export const ButtonOption = styled.button`
  background-color: ${colors.secondary.default};
  color: ${colors.gray.white};
  padding: 16px;
  margin-right: 16px;
  border-radius: 8px;

  @media(max-width: 500px) {
    margin-bottom: 16px;
  }
`;
