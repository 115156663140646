import colors from '@monorepo-zazuu/shared/constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem;
`;


export const LoadingDiv = styled.div`
  @keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }
  position: absolute;
  top: 50%;
  right: 50%;
  color: ${colors.primary.default};
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;


export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 1.6rem 0;
  @media(max-width: 800px) {
    flex-wrap: wrap;
    & > div:first-child {
      margin-top: 0;
    }
    & > div {
      margin-top: 2rem;
      margin-left: 0 !important;
      width: 100%;
    }
  }
`;

export const Table = styled.table`
  background: ${colors.gray.white};
  border: 3px solid ${colors.secondary.default};
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;
  th {
    background: ${colors.secondary.default};
    padding: 1rem;
    white-space: nowrap;
    padding: 5px;
    color: ${colors.gray.white};
  }
  td {
    white-space: nowrap;
    background: ${colors.gray.white};
    padding: 5px;
    border: 1px solid ${colors.secondary.default};
    color: ${colors.gray.dark01};
    padding: 1rem;
    text-align: center;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      button:first-child {
        margin-right: 1rem;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 2.2rem;
        }
      }
    }
  }
`;
