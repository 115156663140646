import { gql } from '@apollo/client';
import colors from '@monorepo-zazuu/shared/constants/colors';
import ICoupon from '@monorepo-zazuu/shared/interfaces/ICoupon';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import InputRef from '../../components/InputRef';
import Switch from '../../components/Switch';
import Table from '../../components/Table';
import client from '../../services/api-graphql';
import { Container, Row, Subtitle, Title } from './styles';

interface IFormData {
  name: string;
  code: string;
  type: string;
  amount: number;
  starts_in: string;
  ends_in: string;
  campaign: string;
}

const Coupons: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const [coupons, setCoupons] = useState<ICoupon[]>([]);

  useEffect(() => {
    client.query({
      query: gql`
        query {
          coupons {
            coupons {
              id
              amount
              campaign
              can_accumulate
              code
              starts_in
              ends_in
              description
            }
          }
        }
      `
    }).then(results => {
        setCoupons(results.data.coupons.coupons.map((c: ICoupon) => {
          return {
            id: c.id,
            code: c.code,
            amount: c.amount,
            type: c.type === 'P' ? '%' : '$',
            campaign: c.campaign ? 'sim' : 'não',
            starts_in: c.starts_in
              ? format(parseISO(String(c.starts_in)), 'dd/MM/yyyy')
              : 'sem comeco',
            ends_in: c.ends_in
              ? format(parseISO(String(c.ends_in)), 'dd/MM/yyyy')
              : 'sem fim',
              can_accumulate: c.can_accumulate ? 'sim' : 'não'
          }
        }));
      })
      .catch(error => {
        toast('Erro ao listar cupons', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
      });
  }, []);

  const handleSubmit = async (data: IFormData) => {
    try {
      const results = await client.mutate({
        mutation: gql`
          mutation CreateCoupon($data: CouponType!) {
            createCoupon(data: $data) {
              id
              name
              code
              type
              visible_to_all
              amount
              starts_in
              ends_in
              campaign
              can_accumulate
              only_new_buyer
            }
          }
        `,
        variables: {
          data: {
            ...data,
            description: data.name,
            amount: Number(data.amount),
            status: true,
            only_new_buyer: false,
            can_accumulate: false,
            campaign: data.campaign === 'sim',
            type: data.type === '%' ? 'P' : 'A',
            visible_to_all: data.campaign ? true : false,
          },
        },
      });

      const new_coupon = results.data.createCoupon;

      setCoupons((oldState: any) => ([
        ...oldState,
        {
          id: new_coupon.id,
          code: new_coupon.code,
          amount: new_coupon.amount,
          type: new_coupon.type === 'P' ? '%' : '$',
          campaign: new_coupon.campaign ? 'sim' : 'não',
          starts_in: new_coupon.starts_in
            ? format(parseISO(String(new_coupon.starts_in)), 'dd/MM/yyyy')
            : 'sem comeco',
          ends_in: new_coupon.ends_in
            ? format(parseISO(String(new_coupon.ends_in)), 'dd/MM/yyyy')
            : 'sem fim',
            can_accumulate: new_coupon.can_accumulate ? 'sim' : 'não'
        }]));

      toast('Cupom adicionado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      const erroGRAPHQL = error as any;
      const payloadErro = erroGRAPHQL?.graphQLErrors[0];
      const name = payloadErro.name;
      const message = payloadErro.message;
      toast(`${name} - ${message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <Row>
        <Title>Cupons</Title>
        <Button
            text="Dar cupom para um cliente"
            type="button"
            behavior="primary"
            onClick={() => history.push('/add-cupon-to-customer')}
            />
      </Row>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Subtitle>Descrição</Subtitle>
        <InputRef name="name" placeholder="descrição" />
        <Subtitle>Código</Subtitle>
        <InputRef name="code" placeholder="código" />
        <Subtitle>Tipo (Valor em Porcentagem ou Valor Absoluto)</Subtitle>
        <Switch
          name="type"
          options={[
            { value: '%', label: 'Porcentagem' },
            { value: '$', label: 'Valor' },
          ]}
        />
        <Subtitle>Valor</Subtitle>
        <InputRef name="amount" placeholder="valor" />
        <Subtitle>Inicia em</Subtitle>
        <InputRef
          name="starts_in"
          placeholder="inicia em"
          style={{
            paddingRight: '2rem',
            color: colors.gray.dark01,
          }}
          type="date"
        />
        <Subtitle>Termina em</Subtitle>
        <InputRef
          name="ends_in"
          placeholder="termina em"
          style={{
            paddingRight: '2rem',
            color: colors.gray.dark01,
          }}
          type="date"
        />
        <Subtitle>
          Campanha (se sim, gera um cupom para todos os usuarios)
        </Subtitle>
        <Switch
          name="campaign"
          options={[
            { value: 'sim', label: 'sim' },
            { value: 'não', label: 'não' },
          ]}
        />
        <Button
          behavior="primary"
          text="criar cupom"
          type="submit"
          style={{ width: '100%', marginTop: '4rem' }}
        />
      </Form>
      <Table
        data={coupons}
        style={{ marginTop: '6rem' }}
      />
    </Container>
  );
};

export default Coupons;
