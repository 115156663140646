/* eslint-disable no-nested-ternary */
import { gql } from '@apollo/client'
import colors from '@monorepo-zazuu/shared/constants/colors'
import IAppointment from '@monorepo-zazuu/shared/interfaces/IAppointment'
import IProfessional from '@monorepo-zazuu/shared/interfaces/IProfessional'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineReload } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { FiEye } from 'react-icons/fi'
import { toast } from 'react-toastify'
import Text from '../../components/Text'
import client from '../../services/api-graphql'
import calculateDistanceBetweenHours from '../../utils/calculateDistanceBetweenHours'
import AppointmentStatusEnum from '../../utils/enums/AppointmentStatusEnum'
import limitString from '../../utils/limitString'
import ModalTruckDetails from './ModalTruckDetails'
import * as Styles from './styles'

interface ITruckProps {
  professional: IProfessional
  allAppointments: IAppointment[]
  filteredAppointments: IAppointment[]
}

const TruckPanel: React.FC = () => {
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date())
  const [actualHourString, setActualHourString] = useState('')
  const [modalDetailsVisible, setModalDetailsVisible] = useState(false)
  const [selectedTruck, setSelectedTruck] = useState<IAppointment[]>([])
  const [hasRegister, setHasRegister] = useState(false)
  const [loading, setLoading] = useState(false)
  const [truck, setTruck] = useState<ITruckProps[]>([])

  const loadAppointments = useCallback(async () => {
    try {
      setLoading(true)

      const start = format(new Date(), 'yyyy-MM-dd')

      const GET_APPOINTMENT = gql`
        query($filters: FiltersAppointmentInputType) {
          appointments(filters: $filters) {
            appointments {
              id
              professional {
                user {
                  name
                }
              }
              customer {
                user {
                  name
                }
              }
              pet {
                id
                name
                specie {
                  name
                }
                breed {
                  name
                  size
                }
                gender
                weight
                birthday
              }
              additionals {
                id
                name
              }
              professional_id
              updated_at
              status
              hour
              on_the_way_status_hour
              on_local_status_hour
              progress_status_hour
              procedure_completed_status_hour
              service_completed_status_hour
              observation_image_url
              address {
                city
                street
                number
                complement
                neighborhood
              }
              service {
                name
              }
              observations
            }
          }
          professionals {
            id
            user {
              id
              name
            }
          }
        }
      `

      const appointmentsData = await client.query({
        query: GET_APPOINTMENT,
        variables: {
          filters: {
            where: {
              from: start,
              to: start,
              statusNotEqual: AppointmentStatusEnum.Cancelado
            },
            orderBy: [
              {
                date: 'asc'
              },
              {
                hour: 'asc'
              }
            ]
          }
        }
      })

      const appointments = appointmentsData.data.appointments
        .appointments as IAppointment[]
      const professionals = appointmentsData.data
        .professionals as IProfessional[]

      // Nessa parte que desordena pelo updated_at e ordena pelo professional_id
      const tempTrucks = professionals.map(professional => {
        const findAppointments = appointments.filter(
          appointment => appointment.professional_id === professional.id
        )

        const filteredAppointments = findAppointments.filter(
          appointment =>
            appointment.status !==
            AppointmentStatusEnum['Atendimento finalizado']
        )

        return {
          professional: {
            id: professional.id,
            user: professional.user
          },
          allAppointments: findAppointments,
          filteredAppointments
        }
      })

      let appointmentCount = 0

      tempTrucks.forEach(truck => {
        if (truck.allAppointments.length >= 1) {
          appointmentCount += 1
        }
      })

      if (appointmentCount >= 1) setHasRegister(true)

      setTruck(tempTrucks)
      setActualHourString(`${new Date().getHours()}:${new Date().getMinutes()}`)

      setLoading(false)
    } catch (error) {
      toast(error.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error'
      })
    }
  }, [])

  useEffect(() => {
    loadAppointments()
  }, [loadAppointments])

  const updatePage = useCallback(() => {
    window.location.reload()

    setLastUpdateTime(new Date())
  }, [])

  const handleOpenDetails = useCallback(appointments => {
    setSelectedTruck(appointments)

    setModalDetailsVisible(true)
  }, [])

  const convertAppointmentStatusName = (status: number) => {
    let text = 'Sem registro'

    if (AppointmentStatusEnum[status]) text = AppointmentStatusEnum[status]

    if (!AppointmentStatusEnum[status]) text = 'Sem registro'

    if (AppointmentStatusEnum[status] === 'Pago') text = 'Não iniciado'

    return text
  }

  return (
    <Styles.Container>
      <Styles.ContainerHeader>
        <Styles.HorizontalDiv>
          <Styles.ButtonOption onClick={updatePage}>
            <AiOutlineReload />
            Atualizar
          </Styles.ButtonOption>

          {lastUpdateTime && (
            <Text
              test={`Última atualização em ${format(
                lastUpdateTime,
                "dd'/'MM'/'yyyy 'às' kk':'mm",
                {
                  locale: ptBR
                }
              )}`}
              size={16}
              color={colors.gray.dark01}
              weight="600"
              align="left"
              marginLeft={16}
            />
          )}
        </Styles.HorizontalDiv>

        <Styles.HorizontalDiv>
          <Styles.StatusLegend
            style={{
              backgroundColor: colors.gray.white,
              color: colors.gray.dark01
            }}
          >
            Normal
          </Styles.StatusLegend>

          <Styles.StatusLegend
            style={{ backgroundColor: colors.suport.warning }}
          >
            Atenção
          </Styles.StatusLegend>

          <Styles.StatusLegend style={{ backgroundColor: colors.suport.alert }}>
            Atrasado
          </Styles.StatusLegend>
        </Styles.HorizontalDiv>
      </Styles.ContainerHeader>

      {!loading ? (
        <>
          <Styles.Table>
            <thead>
              <tr>
                <th>
                  <Text
                    test="tosador"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="tutor"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="Horário"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="pet"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="ultima atualização"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="status"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="próximo"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="left"
                  />
                </th>

                <th>
                  <Text
                    test="ações"
                    size={16}
                    weight="600"
                    color={colors.gray.black}
                    align="center"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {truck.map(truckData => (
                <>
                  {truckData.filteredAppointments !== undefined &&
                  truckData.filteredAppointments.length >= 1 ? (
                    <>
                      <tr>
                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={
                              truckData.professional.user.name || 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={
                              limitString(
                                truckData.filteredAppointments[0]?.customer.user
                                  ?.name,
                                40
                              ) || 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData?.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={
                              truckData.filteredAppointments[0]?.hour ||
                              'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData?.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={
                              truckData.filteredAppointments[0]?.pet?.name ||
                              'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1].hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1].hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={
                              truckData.filteredAppointments[0]?.updated_at
                                ? format(
                                    new Date(
                                      truckData.filteredAppointments[0]?.updated_at
                                    ),
                                    "dd'/'MM'/'yyyy 'às' HH':'mm",
                                    {
                                      locale: ptBR
                                    }
                                  )
                                : 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData?.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={convertAppointmentStatusName(
                              truckData?.filteredAppointments[0]?.status
                            )}
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Text
                            test={
                              truckData.filteredAppointments[1]
                                ? `
                          ${
                            truckData.filteredAppointments[1]?.customer.user
                              ?.name
                          }
                          -
                          ${truckData.filteredAppointments[1]?.pet?.name}
                          🕒
                          ${
                            truckData.filteredAppointments[1]?.hour ||
                            'sem hora'
                          }
                        `
                                : 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd
                          style={{
                            backgroundColor: truckData?.filteredAppointments[1]
                              ?.hour
                              ? calculateDistanceBetweenHours(
                                  actualHourString,
                                  truckData.filteredAppointments[1]?.hour
                                ) < 0
                                ? '#E8505B70'
                                : calculateDistanceBetweenHours(
                                    actualHourString,
                                    truckData.filteredAppointments[1]?.hour
                                  ) < 20
                                ? '#FF8F3980'
                                : '#fff'
                              : '#fff'
                          }}
                        >
                          <Styles.ActionDiv
                            onClick={() =>
                              handleOpenDetails(truckData.allAppointments)
                            }
                          >
                            <FiEye />
                          </Styles.ActionDiv>
                        </Styles.StyledTd>
                      </tr>
                    </>
                  ) : truckData.filteredAppointments === undefined &&
                    truckData.allAppointments.length >= 1 ? (
                    <>
                      <Styles.FinishedTrucksDiv>
                        <Text
                          test="Trucks finalizados"
                          size={16}
                          weight="400"
                          color={colors.suport.alert}
                          align="center"
                        />
                      </Styles.FinishedTrucksDiv>

                      <tr>
                        <Styles.StyledTd>
                          <Text
                            test={
                              truckData.allAppointments[0]?.professional.user
                                ?.name || 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Text
                            test={
                              limitString(
                                truckData.allAppointments[0]?.customer.user
                                  ?.name,
                                40
                              ) || 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Text
                            test={
                              truckData.allAppointments[0]?.hour ||
                              'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Text
                            test={
                              truckData.allAppointments[0]?.pet?.name ||
                              'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Text
                            test={
                              truckData.allAppointments[0]?.updated_at
                                ? format(
                                    new Date(
                                      truckData.allAppointments[0]?.updated_at
                                    ),
                                    "dd'/'MM'/'yyyy 'às' HH':'mm",
                                    {
                                      locale: ptBR
                                    }
                                  )
                                : 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Text
                            test={convertAppointmentStatusName(
                              truckData?.allAppointments[0]?.status
                            )}
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Text
                            test={
                              truckData.allAppointments[1]
                                ? `
                          ${truckData?.allAppointments[1]?.customer.user?.name}
                          -
                          ${truckData?.allAppointments[1]?.pet?.name}
                          🕒
                          ${truckData?.allAppointments[1]?.hour || 'sem hora'}
                        `
                                : 'Sem registro'
                            }
                            size={16}
                            color={colors.gray.dark01}
                            align="left"
                          />
                        </Styles.StyledTd>

                        <Styles.StyledTd>
                          <Styles.ActionDiv
                            onClick={() =>
                              handleOpenDetails(truckData.allAppointments)
                            }
                          >
                            <FiEye />
                          </Styles.ActionDiv>
                        </Styles.StyledTd>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </tbody>
          </Styles.Table>

          {!hasRegister && (
            <Styles.NoDataDiv>
              <Text
                test="Sem registro até o momento"
                size={18}
                color={colors.gray.dark01}
                align="center"
              />
            </Styles.NoDataDiv>
          )}
        </>
      ) : (
        <Styles.LoadingDiv>
          <FaSpinner size={40} />
        </Styles.LoadingDiv>
      )}

      <ModalTruckDetails
        visible={!!modalDetailsVisible}
        handleCloseModal={() => setModalDetailsVisible(false)}
        appointments={selectedTruck}
      />
    </Styles.Container>
  )
}

export default TruckPanel
