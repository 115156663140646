import IBreed from '@monorepo-zazuu/shared/interfaces/IBreed';
import IServicesSpeciesSizes from '@monorepo-zazuu/shared/interfaces/IServicesSpeciesSizes';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { toast } from 'react-toastify';
import AsyncSelect from '../../components/AsyncSelect';
import api from '../../services/api';
import { calculateLabelPet, calculateSizePet } from '../../utils/calculateSize';
import listSizesPetsBySpecie from '../../utils/listSizesPets';
import {
  Button, ButtonCalculate, Container,
  Content,
  Left, OptionButton,
  Options,
  Right,
  Row, Subtitle,
  Text,
  Title,
} from './styles';

const Simulator: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [breeds, setBreeds] = useState<
    { id: string; label: string; size: string }[]
  >([]);
  const [servicesSizesPrices, setServicesSizesPrices] = useState<
    IServicesSpeciesSizes[]
  >([]);

  const [specie, setSpecie] = useState<'cachorro' | 'gato'>('cachorro');
  const [hasBreed, setHasBreed] = useState(true);
  const [weight, setWeight] = useState(1);

  useEffect(() => {
    formRef.current?.clearField('breed_id');
    setBreeds([]);
    setServicesSizesPrices([]);
  }, [specie, hasBreed]);

  const onSearchingBreed = async (word: string) => {
    const response = await api.get<IBreed[]>(`/breeds?specie=${specie}&name=${word}`);
    setBreeds(response.data.map((item: IBreed) => ({
      id: item.id,
      label: item.name,
      size: item.size,
    })));
    return response.data.map((breed) => ({ value: breed.id, label: breed.name }));
  };

  const onSearchingBreedDebounced = AwesomeDebouncePromise(
    onSearchingBreed,
    1000,
  );

  const handleCalculate = useCallback(async () => {
    try {
      const sizeLabel = calculateSizePet(weight);

      const response = await api.get(
        `/services/filters?specie=${specie}&size=${sizeLabel}`,
      );

      setServicesSizesPrices(response.data);
    } catch (error) {
      toast('Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [specie, weight]);

  useEffect(() => {
    formRef.current?.setFieldValue('breed', '');
  }, []);

  // const handleAddCart = (service: IService) => {
  //   addItem(service);
  //   history.push('/cart')
  // }

  const handleBreedSelected = (breed_id: string) => {
    setServicesSizesPrices([]);
    const breed = breeds.find((b) => b.id === breed_id);
    if (breed) {
      const sizeConverted = calculateLabelPet(breed.size);
      setWeight(sizeConverted);
    }
  };

  return (
    <Container>
      <Content>
        <Left>
          <Form onSubmit={() => { }} ref={formRef} style={{ width: '100%' }}>
            <Title style={{ textAlign: 'center' }}>calculadora de preço</Title>
            <Subtitle>Qual espécie do seu pet?</Subtitle>
            <Options>
              <OptionButton
                selected={specie === 'cachorro'}
                onClick={() => {
                  setSpecie('cachorro');
                  setServicesSizesPrices([]);
                  setWeight(1);
                }}
              >
                cachorro
              </OptionButton>
              <OptionButton
                selected={specie === 'gato'}
                onClick={() => {
                  setSpecie('gato');
                  setServicesSizesPrices([]);
                  setWeight(1);
                }}
              >
                gato
              </OptionButton>
            </Options>
            <Subtitle>Possui raça?</Subtitle>
            <Options>
              <OptionButton
                selected={hasBreed}
                onClick={() => setHasBreed(true)}
              >
                sim
              </OptionButton>
              <OptionButton
                selected={!hasBreed}
                onClick={() => setHasBreed(false)}
              >
                não
              </OptionButton>
            </Options>
            <Subtitle>Qual raça?</Subtitle>
            <AsyncSelect
              items={breeds}
              name="breed_id"
              icon={IoMdArrowDropdown}
              placeholder="digite uma raça"
              disabled={!hasBreed}
              loadOptions={onSearchingBreedDebounced}
              onSelectItem={(option) => handleBreedSelected(option.value)}
            />
            <Subtitle>Qual o peso?</Subtitle>
            <Row>
              {listSizesPetsBySpecie[specie].weights.map((w) => (
                <Button
                  key={w.size}
                  type="button"
                  selected={weight === w.size}
                  onClick={() => setWeight(w.size)}
                >
                  {`${w.min}kg - ${w.max}kg`}
                </Button>
              ))}
            </Row>

            <Row>
              <ButtonCalculate onClick={handleCalculate}>
                calcular
              </ButtonCalculate>
            </Row>
          </Form>
        </Left>

        <Right>
          <Title style={{ marginBottom: '2rem', alignSelf: 'center' }}>
            Serviços
          </Title>
          {servicesSizesPrices?.length === 0 && (
            <Text style={{ alignSelf: 'center' }}>
              Nenhum servico encontrado
            </Text>
          )}
          {/* <ServicesContainer>
            {servicesSizesPrices?.map(serviceSpecieSize => (
              <Card
                key={serviceSpecieSize.service.id}
                style={{
                  borderColor: colors.gray.white,
                }}
              >
                {serviceSpecieSize.service.image_url && <img src={serviceSpecieSize.service.image_url} alt="arugan" />}
                <CardTitle background={!serviceSpecieSize.service.image_url}>{serviceSpecieSize.service.title}</CardTitle>
                <Species style={{ marginTop: !serviceSpecieSize.service.image_url ? '1rem' : 0 }}>
                  {serviceSpecieSize.specie.name.toLowerCase() === 'cachorro' ? (
                    <div key={specie}>
                      <FaDog />
                    </div>
                  ) : <div key={specie}><FaCat /></div>}
                </Species>
                <CardSubtitle>{serviceSpecieSize.service.subtitle}</CardSubtitle>
                {serviceSpecieSize.service.image_url && (
                <ButtonPlus onClick={() => setSelectedServicePlus((old) => !old)}>
                  ver mais
                </ButtonPlus>
                )}
                <Collapse isOpened={!serviceSpecieSize.service.image_url || selectedServicePlus}>
                  <CardComplement>{Array.isArray(serviceSpecieSize.service.description) ? serviceSpecieSize.service.description.map((cp) => (<span key={cp}>{cp}</span>)) : serviceSpecieSize.service.description}</CardComplement>
                  <CardComplement style={{ fontSize: '1.2rem' }}>
                    {serviceSpecieSize.service.complement}
                  </CardComplement>
                </Collapse>
                {serviceSpecieSize.service.discount && (
                <Off>
                  <p>{serviceSpecieSize.service.discount}% OFF</p>
                </Off>
                )}
                {serviceSpecieSize.service.discount ? (
                  <Text style={{ marginTop: 'auto' }}>
                    <p>{convertToCurrencyFormat(String(serviceSpecieSize.price))}</p>&nbsp;<p>{convertToCurrencyFormat(String((serviceSpecieSize.price * (1 - serviceSpecieSize.service.discount / 100)).toFixed(2)))}</p>
                  </Text>
                ) : (
                  <Text style={{ marginTop: 'auto' }}>
                    <p style={{ textDecoration: 'none', fontSize: '2rem' }}>{convertToCurrencyFormat(String(serviceSpecieSize.price))}</p>
                  </Text>
                )}
                <ButtonBuy
                  onClick={() => handleAddCart(serviceSpecieSize.service)}
                  style={{ marginTop: '1rem' }}
                >
                  comprar
                </ButtonBuy>
              </Card>
            ))}
          </ServicesContainer> */}
        </Right>
      </Content>
    </Container>
  );
};

export default Simulator;
