import { gql } from '@apollo/client';
import IOrder from '@monorepo-zazuu/shared/interfaces/IOrder';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import InputRef from '../../components/InputRef';
import client from '../../services/api-graphql';
import convertToCurrencyFormat from '../../utils/convertToCurrencyFormat';
import TypePaymentEnum from '../../utils/enums/TypePaymentEnum';
import SelectToPay from './SelectToPay';
import {
  Container,
  ContainerListCustomers,
  ListOrders,
  ListOrdersHeader,
  ListOrdersRow,
  Message,
  Row,
  Td,
  TextHeader,
  Title,
} from './styles';

interface IDataOrders {
  from: Date;
  to: Date;
  cpf: string;
}

const Orders: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (data: IDataOrders): Promise<void> => {
    setLoading(true);
    try {
      if (data.from.toString() === '' && data.to.toString() === '') {
        setLoading(false);
        toast('Escolha pelo menus um filtro', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'warning',
        });
        return;
      }

      const schema1 = Yup.object().shape({
        from: Yup.date().when('to', (to: any, schema: any) => (to !== undefined ? schema.required('data final necessaria') : schema.nullable().default(undefined))),
        to: Yup.date().nullable().default(undefined),
      });
      const schema2 = Yup.object().shape({
        from: Yup.date().nullable().default(undefined),
        to: Yup.date().when('from', (from: any, schema: any) => (from !== undefined ? schema.required('data final necessaria') : schema.nullable().default(undefined))),
      });

      await schema1.validate({ from: data.from.toString() === '' ? undefined : data.from, to: data.to.toString() === '' ? undefined : data.to }, { abortEarly: false });
      await schema2.validate({ from: data.from.toString() === '' ? undefined : data.from, to: data.to.toString() === '' ? undefined : data.to }, { abortEarly: false });

      const results = await client.query({
        query: gql`
          query($filters: FiltersOrderInputType) {
            orders(filters: $filters) {
              orders {
                id
                created_at
                customer {
                  user {
                    name
                    cpf
                  }
                }
                transaction {
                  id
                  status
                  amount
                  type
                }
              }
            }
          }
        `,
        variables: {
          filters: {
            where: {
              from: data.from,
              to: data.to,
              status: 'W',
            },
          },
        },
      });
      setOrders(results.data.orders.orders);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.errors) {
        Object.entries(error.errors).forEach((erro: any) => {
          toast(erro[1], {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: 'warning',
          });
        });
      } else {
        toast(error?.response?.data?.message || 'Erro no servidor', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
      }
    }
  };

  const handleRemoveOrder = useCallback((order_id: string) => {
    setOrders((oldOrders) => oldOrders?.filter((o) => o.id !== order_id));
  }, []);

  const handleUpdateList = useCallback((order: IOrder) => {
    setOrders((oldOrders) => oldOrders.map((od) => (od.id === order.id ? { ...od, ...order } : od)));
  }, []);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSearch}>
        <Title>Pedidos</Title>
        <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <InputRef
            name="from"
            style={{ paddingRight: '2rem' }}
            placeholder="data inicial"
            type="date"
          />
          <InputRef
            name="to"
            containerStyle={{ marginLeft: '2rem' }}
            style={{ paddingRight: '2rem' }}
            placeholder="data final"
            type="date"
          />
        </Row>
        <Button
          behavior="primary"
          type="submit"
          text="Buscar"
          loading={loading}
          style={{ width: '100%' }}
        />
      </Form>
      <ContainerListCustomers>
        <ListOrders>
          <caption>
            <Title>Lista de clientes</Title>
          </caption>
          <thead>
            <ListOrdersHeader>
              <TextHeader>nome</TextHeader>
              <TextHeader>cpf</TextHeader>
              <TextHeader>valor</TextHeader>
              <TextHeader>data</TextHeader>
              <TextHeader>pagamento</TextHeader>
              <TextHeader>alterar / definir meio de pagamento</TextHeader>
              <TextHeader>confirmar</TextHeader>
              <TextHeader>gerar link</TextHeader>
            </ListOrdersHeader>
          </thead>
          <tbody>
            {orders?.map((order) => (
              <ListOrdersRow key={order.id}>
                <Td>{order.customer.user.name}</Td>
                <Td>{order.customer.user.cpf}</Td>
                <Td>{order?.transaction ? convertToCurrencyFormat(order.transaction.amount / 100) : 'sem valor'}</Td>
                <Td>{format(new Date(order.created_at), 'dd/MM/yyyy HH:mm')}</Td>
                <Td>{order?.transaction?.type ? TypePaymentEnum[order.transaction.type] : 'definir'}</Td>
                <SelectToPay order_id={order.id} typePaymentDefault={order?.transaction?.type || TypePaymentEnum.definir} removeFromList={() => handleRemoveOrder(order.id)} updateFromList={handleUpdateList} />
              </ListOrdersRow>
            ))}
          </tbody>
        </ListOrders>
      </ContainerListCustomers>
      {(orders?.length === 0 && !loading) && <Message>nenhum pedido encontrado</Message>}
    </Container>
  );
};

export default Orders;
