import React, { useCallback, useEffect, useState } from 'react';
import { FaDog, FaSpinner } from 'react-icons/fa';
import { FiAward, FiEdit } from 'react-icons/fi';
import { GiWeight } from 'react-icons/gi';
import { IoIosFemale, IoIosMale } from 'react-icons/io';
import { RiCake2Line } from 'react-icons/ri';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';

import pataGreenImg from '@monorepo-zazuu/shared/assets/icons/pata-green.svg';
import pataWhiteImg from '@monorepo-zazuu/shared/assets/icons/pata-light-0.svg';
import dogDefault from '@monorepo-zazuu/shared/assets/images/dog-default.png';
import colors from '@monorepo-zazuu/shared/constants/colors';
import IAppointment from '@monorepo-zazuu/shared/interfaces/IAppointment';
import IPet from '@monorepo-zazuu/shared/interfaces/IPet';

import ModalAddPet from '../../components/ModalAddPet';

import api from '../../services/api';
import { searchAppointments } from '../../services/api-graphql-calls';

import {
  AppointmentsContainer, ButtonEditPet, CardAppointment, Container,
  ListAppointments, LoadingContainer, PetDetailsContainer,
  PetDetailsContainerColumn, PetName, PetsRow, PetsRowButton, Row, SubTitle,
  Text, TextErro, Title,
} from './styles';

const DashboardCustomer: React.FC = () => {
  const [petSelected, setPetSelected] = useState<IPet>();
  const [pets, setPets] = useState<IPet[]>([]);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [loading, setLoading] = useState(true);

  const [modalAddPetVisibility, setModalAddPetVisibility] = useState(false);
  const [modalEditPetVisibility, setModalEditPetVisibility] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const response2 = await api.get('/pets');
      const petsDataFormatted = response2.data.map((pet: IPet) => ({
        ...pet,
        avatar_url: pet.avatar_url || dogDefault,
        age: pet.birthday
          ? formatDistanceToNow(parseISO(pet.birthday), {
            locale: ptBR,
          })
          : undefined,
      }));

      setPets(petsDataFormatted);
      setPetSelected(petsDataFormatted[0]);

      const appointments = await searchAppointments();
      setAppointments(
        appointments.map((app) => {
          const dateSplited = app.date.split('-');
          return {
            ...app,
            date: `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]} `,
            start_hour: app.hour ? app.hour : 'indefinida',
          };
        }),
      );

      setLoading(false);
    } catch (error) {
      toast('Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) {
    return (
      <LoadingContainer>
        <FaSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <PetsRow>
        <PetsRowButton
          onClick={() => setModalAddPetVisibility(true)}
          style={{ marginRight: '2rem' }}
        >
          <img src={pataGreenImg} alt="pata" />

          adicionar pet
        </PetsRowButton>

        {modalAddPetVisibility && (
          <ModalAddPet
            closeModal={() => setModalAddPetVisibility(false)}
            visible={modalAddPetVisibility}
            onSaveNewPet={(pet) => loadData()}
          />
        )}

        {modalEditPetVisibility && (
          <ModalAddPet
            closeModal={() => setModalEditPetVisibility(false)}
            visible={modalEditPetVisibility}
            onSaveNewPet={(pet) => loadData()}
            pet={petSelected}
          />
        )}

        {pets.map((pet) => (
          <PetsRowButton
            key={pet.id}
            selected={petSelected?.id === pet.id}
            onClick={() => setPetSelected(pet)}
          >
            <img
              src={petSelected?.id === pet.id ? pataWhiteImg : pataGreenImg}
              alt="pata"
            />
            {pet.name}
          </PetsRowButton>
        ))}
      </PetsRow>
      <PetDetailsContainer visible={pets.length > 0}>
        <img src={petSelected?.avatar_url} alt="https://www.instagram.com/soyandrelo/" />
        <PetDetailsContainerColumn>
          <Row>
            <PetName>{petSelected?.name}</PetName>
            {petSelected?.gender === 'M' ? <IoIosMale /> : <IoIosFemale />}
          </Row>
          <Row>
            <FaDog />
            <Text>{petSelected?.breed?.name || 'sem raça'}</Text>
          </Row>
          <Row>
            <GiWeight />
            <Text>{petSelected?.weight}kg</Text>
          </Row>
          <Row>
            <FiAward />
            <Text>{petSelected?.age || 'Sem idade'}</Text>
          </Row>
          <Row>
            <RiCake2Line />
            <Text>
              {petSelected?.birthday
                ? format(parseISO(petSelected.birthday), "'Dia' dd 'de' MMMM", {
                  locale: ptBR,
                })
                : 'Sem data de aniversário'}
            </Text>
          </Row>
        </PetDetailsContainerColumn>
        <ButtonEditPet onClick={() => setModalEditPetVisibility(true)}>
          <FiEdit />
        </ButtonEditPet>
      </PetDetailsContainer>
      <AppointmentsContainer>
        <Title>Próximos agendamentos</Title>
        <ListAppointments>
          {appointments.map((appointment) => (
            <CardAppointment
              key={appointment.id}
              status="TODO"
            >
              <div>
                <SubTitle weight="bold">
                  {appointment.service.name}
                </SubTitle>
                <SubTitle weight="bold">
                  {`${appointment?.pet?.name || 'pet excluído'}`}
                </SubTitle>
              </div>
              <div>
                <SubTitle weight="normal">{appointment.date}</SubTitle>
                <SubTitle weight="normal">{`${appointment.hour || appointment.period}`}</SubTitle>
              </div>
              <Text style={{ alignSelf: 'center', color: colors.suport.warning }}>
                {appointment.status === 1
                  && 'aguardando pagamento'}
              </Text>
            </CardAppointment>
          ))}
          {appointments.length === 0 && (
            <TextErro>Nenhum agendamento marcado</TextErro>
          )}
        </ListAppointments>
      </AppointmentsContainer>
    </Container>
  );
};

export default DashboardCustomer;
