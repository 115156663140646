import styled from 'styled-components';

export const Content = styled.div`
  text-align: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;
