interface IMasks {
  [key: string]: (value: string) => string;
}

const cep = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{5})(\d)/, '$1-$2')
  .replace(/(-\d{3})\d+?$/, '$1');

const cpf = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

const phone = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1) $2')
  .replace(/(\d{4})(\d)/, '$1-$2')
  .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
  .replace(/(-\d{4})\d+?$/, '$1');

const birthday = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\/\d{4})\d+?$/, '$1');

const cardNumber = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{4})(\d)/, '$1 $2')
  .replace(/(\d{4})(\d)/, '$1 $2')
  .replace(/(\d{4})(\d)/, '$1 $2')
  .replace(/(\d{4})(\d)/, '$1$2')
  .replace(/(\d{7})(\d)/, '$1');

const cardValidate = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})\d+?$/, '$1');

const hours = (value: string): string => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1:$2');

const masks = {
  cep,
  cpf,
  phone,
  birthday,
  cardNumber,
  cardValidate,
  hours,
} as IMasks;

export default masks;
