import styled from 'styled-components';

import colors from '@monorepo-zazuu/shared/constants/colors';

// import calculateDistanceBetweenHours from '../../utils/calculateDistanceBetweenHours';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem;
`;

export const ContainerHeader = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonOption = styled.button`
  background-color: ${colors.secondary.default};
  color: ${colors.gray.white};
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 700;
  transition: all 0.2s ease-in-out;

  > svg {
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    filter: brightness(95%);
    transform: scale(1.01);
  }

  @media(max-width: 500px) {
    margin-bottom: 16px;
  }
`;

export const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StatusLegend = styled.div`
  width: 100px;
  padding: 8px 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid ${colors.gray.light02};
  cursor: default;
`;

export const Table = styled.table`
  background: ${colors.gray.white};
  border: 2px solid ${colors.gray.dark03};
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;

  th {
    background: ${colors.secondary.default};
    padding: 1rem;
    white-space: nowrap;
    color: ${colors.gray.white};
    border: 2px solid ${colors.gray.dark03};
  }

  td {
    white-space: nowrap;
    background: ${colors.gray.white}; // condição para estado "normal, atenção,, atrasado"
    border: 2px solid ${colors.gray.dark03};
    color: ${colors.gray.dark01};
    padding: 1rem;
    text-align: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button:first-child {
        margin-right: 1rem;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 2.2rem;
        }
      }
    }
  }
`;

export const LoadingDiv = styled.div`
  @keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  position: absolute;
  top: 50%;
  right: 50%;
  color: ${colors.primary.default};
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const NoDataDiv = styled.div`
  background-color: ${colors.gray.light03};
  padding: 1rem;
  margin-top: 2rem;
  border: 1px solid ${colors.gray.dark03};
`;

export const FinishedTrucksDiv = styled.div`
  width: 100%;
  /* border-top: 1px solid ${colors.gray.light01}; */
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const ActionDiv = styled.div`
  cursor: pointer;

  &:hover {
    & > svg {
      transition: all 0.2s ease-in-out;
      transform: scale(1.3);
      color: ${colors.gray.black};
    }
  }
`;

export const StyledTd = styled.td``;

/* ${(props) => console.log(props)} */

/* opacity: ${(props) => (props.selected ? 1 : 0)}; */

/* backgroundColor: truck?.appointments[1]?.hour
  ? calculateDistanceBetweenHours(actualHourString, truck.appointments[1].hour) < 0 ? '#E8505B70'
    : calculateDistanceBetweenHours(actualHourString, truck.appointments[1].hour) < 20 ? '#FF8F3980'
      : '#fff'
  : '#fff', */

// distanceHours={
//   truck.appointments[1].hour ? (
//     calculateDistanceBetweenHours(
//       actualHourString, truck.appointments[1].hour,
//     )
//   ) : 0
// }
