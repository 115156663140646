import React from 'react';
import AddCouponToCustomer from '../pages/AddCouponToCustomer';
import Calendar from '../pages/Calendar';
import CalendarDetails from '../pages/CalendarDetails';
import Coupons from '../pages/Coupons';
import DashboardCustomer from '../pages/Dashboard';
import Dashboard from '../pages/DashboardAdmin';
import ForgotPassword from '../pages/ForgotPassword';
import Orders from '../pages/Orders';
import Reports from '../pages/Reports';
import SignIn from '../pages/SignIn';
import SignUpCustomer from '../pages/SignUpCustomer';
import Simulator from '../pages/Simulator';
import Trucks from '../pages/Trucks';
import UpdateProfile from '../pages/UpdateProfile';
import ServicesManager from '../pages/ServicesManager';
import TruckPanel from '../pages/TruckPanel';
import Route from './Route';
import GeneratorRoutes from '../pages/GeneratorRoutes';
import FinancialReports from '../pages/FinancialReports';
import AnamneseControl from '../pages/AnamneseControl';

const Routes: React.FC = () => (
  <>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot-password" exact component={ForgotPassword} />

    <Route path="/signup-customer" exact component={SignUpCustomer} isPrivate />
    <Route path="/update-profile" exact component={UpdateProfile} isPrivate />
    <Route path="/dashboard" exact component={Dashboard} isPrivate />
    <Route
      path="/dashboard-customer"
      exact
      component={DashboardCustomer}
      isPrivate
    />
    <Route path="/simulator" exact component={Simulator} isPrivate />
    <Route path="/reports" exact component={Reports} isPrivate />
    <Route path="/coupons" exact component={Coupons} isPrivate />
    <Route
      path="/add-cupon-to-customer"
      exact
      component={AddCouponToCustomer}
      isPrivate
    />
    <Route path="/orders" exact component={Orders} isPrivate />
    <Route path="/trucks" exact component={Trucks} isPrivate />
    <Route path="/calendar" exact component={Calendar} isPrivate />
    <Route path="/calendar-details" exact component={CalendarDetails} isPrivate />
    <Route path="/services-manager" exact component={ServicesManager} isPrivate />
    <Route path="/trucks-panel" exact component={TruckPanel} isPrivate />
    <Route path="/generator-routes" exact component={GeneratorRoutes} isPrivate />
    <Route path="/financial-reports" exact component={FinancialReports} isPrivate />

    <Route path="/anamnese-control" exact component={AnamneseControl} isPrivate />
  </>
);

export default Routes;
