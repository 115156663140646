import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;

  .react-select__menu-list {
    width: 100%;
  }
  .react-select__option {
    border-radius: 5rem;
    width: 100%;
    background: ${colors.gray.light02};

    :hover {
      background: ${colors.secondary.default} !important;
    }
  }

  & > div {
    display: flex;
    width: 100%;

    & > div {
      display: flex;
      width: 100%;
      min-height: 4.8rem;

      cursor: pointer;

      background: ${colors.gray.light02};
      border-radius: 3rem;

      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 150%;
      color: ${colors.gray.dark02};

      &::placeholder {
        color: ${colors.gray.dark01};
      }
    }
  }
`;
