import styled from 'styled-components';

export const ContainerApp = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;
