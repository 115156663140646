import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.header<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: row;
  width: 100%;
  height: 6.4rem;

  justify-content: center;
  align-items: center;

  background-color: ${colors.gray.white};

  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.6);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.6);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.6);

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 0 5%;

    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      a > div {
        display: flex;
        flex-direction: row;
      }

      p {
        font-family: 'Open Sans';
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 120%;

        color: ${colors.secondary.default};

        span {
          font-weight: normal;
        }
      }

      & > a {
        display: flex;
        align-items: center;
        margin-left: 3rem;

        background: none;
        border: none;

        svg {
          font-size: 3rem;
          color: ${colors.gray.dark01};
        }
      }
    }

    div > a {
      img {
        height: 5.4rem;
        background-color: ${colors.gray.white};
      }
    }
  }
`;

