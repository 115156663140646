import styled, { css } from 'styled-components';

import colors from '@monorepo-zazuu/shared/constants/colors';
import AppointmentStatusEnum from '../../../utils/enums/AppointmentStatusEnum';

export const Content = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Card = styled.button`
  background-color: ${colors.secondary.default};
  margin-top: 16px;
  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  width: 100%;
`;

export const CardHeader = styled.div<{ selected: boolean; status: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => (props.selected ? colors.secondary.default : colors.gray.light02)};

  ${(props) => props.status === AppointmentStatusEnum['Aguardando pagamento'] && css`
    background-color: ${colors.suport.warning};
  `}
  border: 1px solid ${(props) => (props.selected ? 'transparent' : colors.gray.light01)};
`;

export const CardContent = styled.div<{ selected: boolean }>`
  background-color: ${colors.gray.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  ${(props) => props.selected && css`
    border: 2px solid ${colors.secondary.default};
  `}
`;

export const CardAddress = styled.div`
  padding: 8px;
`;

export const Button = styled.button`
  margin-left: auto;
`;

export const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 8px 8px 8px;
`;

export const LoadingDiv = styled.div`
  @keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  position: absolute;
  top: 50%;
  right: 50%;
  color: ${colors.primary.default};
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const HeaderDivSeparatorLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
`;

export const HeaderDiv = styled.div`
  width: 33%;
  display: flex;
  align-items: flex-start;
`;

export const HeaderDivSeparatorRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
`;

export const CardStatus = styled.div`
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid ${colors.gray.light01};
  margin-bottom: 8px;
`;

export const ObservationImageImg = styled.img`
  height: 50%;
  width: 80%;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const ScrollView = styled.div`
  overflow-y: scroll;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
`;

export const ImageDiv = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 4px;
  background-color: ${colors.gray.light01};
  overflow: hidden;
  margin-top: 12px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  transition: 0.5s all ease-in-out;

  :hover {
    transform: scale(1.2);
  }
`;
