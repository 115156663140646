import React, { useCallback, useState, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import colors from '@monorepo-zazuu/shared/constants/colors';
import IAppointment from '@monorepo-zazuu/shared/interfaces/IAppointment';

import AppointmentStatusEnum from '../../../utils/enums/AppointmentStatusEnum';
import convertStatusToColorAppointment from '../../../utils/convertStatusToColorAppointment';
import limitString from '../../../utils/limitString';

import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import ProgressStep from './ProgressStep';

import * as Styles from './styles';

interface IProps {
  visible: boolean;
  handleCloseModal: () => void;
  appointments: IAppointment[];
}

interface IAppointmentFormatted extends IAppointment {
  statusStepsArray: {
    name: string;
    nameTranslate: string;
    value?: string;
  }[];
}

const ModalTruckDetails: React.FC<IProps> = ({
  visible, handleCloseModal, appointments,
}) => {
  const [
    collapseAppointmentIndexToOpen, setCollapseAppointmentIndexToOpen,
  ] = useState<string | undefined>(undefined);
  const [
    appointmentsFormatted, setAppointmentsFormatted,
  ] = useState<IAppointmentFormatted[]>([]);

  useEffect(() => {
    try {
      const tempAppointments = appointments.map((appointment): IAppointmentFormatted => {
        const statusStepsArray = [
          {
            name: 'on_the_way_status_hour',
            nameTranslate: 'A caminho',
            value: appointment.on_the_way_status_hour
              ? format(new Date(2022, 1, 1, Number(appointment.on_the_way_status_hour.split(':')[0]), Number(appointment.on_the_way_status_hour.split(':')[1])).getTime(), 'HH:mm')
              : undefined,
          },
          {
            name: 'on_local_status_hour',
            nameTranslate: 'No local',
            value: appointment.on_local_status_hour
              ? format(new Date(2022, 1, 1, Number(appointment.on_local_status_hour.split(':')[0]), Number(appointment.on_local_status_hour.split(':')[1])).getTime(), 'HH:mm')
              : undefined,
          },
          {
            name: 'progress_status_hour',
            nameTranslate: 'Em andamento',
            value: appointment.progress_status_hour
              ? format(new Date(2022, 1, 1, Number(appointment.progress_status_hour.split(':')[0]), Number(appointment.progress_status_hour.split(':')[1])).getTime(), 'HH:mm')
              : undefined,
          },
          {
            name: 'procedure_completed_status_hour',
            nameTranslate: 'Procedimento finalizado',
            value: appointment.procedure_completed_status_hour
              ? format(new Date(2022, 1, 1, Number(appointment.procedure_completed_status_hour.split(':')[0]), Number(appointment.procedure_completed_status_hour.split(':')[1])).getTime(), 'HH:mm')
              : undefined,
          },
          {
            name: 'service_completed_status_hour',
            nameTranslate: 'Atendimento finalizado',
            value: appointment.service_completed_status_hour
              ? format(new Date(2022, 1, 1, Number(appointment.service_completed_status_hour.split(':')[0]), Number(appointment.service_completed_status_hour.split(':')[1])).getTime(), 'HH:mm')
              : undefined,
          },
        ];

        return {
          ...appointment,
          statusStepsArray,
        };
      });

      setAppointmentsFormatted(tempAppointments);
    } catch (error) {
      toast(error.response?.data?.message || 'Erro no servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [appointments]);

  const handleToogleCollapse = useCallback((appointment_id: string) => {
    if (appointment_id === collapseAppointmentIndexToOpen) {
      setCollapseAppointmentIndexToOpen(undefined);

      return;
    }

    setCollapseAppointmentIndexToOpen(appointment_id);
  }, [collapseAppointmentIndexToOpen]);

  const validateDateFormat = useCallback((pet) => {
    const isValidDate = Date.parse(pet.birthday);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(isValidDate)) {
      toast(`Pet ${pet.name} com data de aniversário no formato errado. Favor, relatar erro.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });

      return false;
    }

    return true;
  }, []);

  return (
    <Modal visible={visible} closeModal={handleCloseModal}>
      <Styles.Content>
        <Text
          test={appointments[0]?.professional.user?.name}
          size={24}
          align="center"
          weight="600"
          marginTop={32}
        />

        {appointmentsFormatted.length >= 1 ? (
          <Styles.ScrollView>
            {appointmentsFormatted.map((appointment) => (
              <>
                <Styles.Card
                  key={appointment?.id}
                  onClick={() => handleToogleCollapse(appointment?.id)}
                >
                  <Styles.CardHeader
                    selected={collapseAppointmentIndexToOpen === appointment?.id}
                    status={appointment?.status}
                  >
                    <Styles.HeaderDivSeparatorLeft>
                      <Styles.HeaderDiv>
                        <Text
                          test={limitString(`Tutor: ${appointment?.customer.user?.name}`, 20)}
                          color={collapseAppointmentIndexToOpen === appointment?.id ? colors.gray.white : colors.gray.dark01}
                          weight="600"
                        />
                      </Styles.HeaderDiv>

                      <Styles.HeaderDiv>
                        <Text
                          test={limitString(`Pet: ${appointment?.pet?.name}`, 20)}
                          color={collapseAppointmentIndexToOpen === appointment?.id ? colors.gray.white : colors.gray.dark01}
                          marginLeft={16}
                          weight="600"
                        />
                      </Styles.HeaderDiv>

                      <Styles.HeaderDiv>
                        <Text
                          test={`🕒 ${appointment?.hour || 'SR'}`}
                          color={collapseAppointmentIndexToOpen === appointment?.id ? colors.gray.white : colors.gray.dark01}
                          marginLeft={16}
                          weight="600"
                        />
                      </Styles.HeaderDiv>
                    </Styles.HeaderDivSeparatorLeft>

                    <Styles.HeaderDivSeparatorRight>
                      <GoPrimitiveDot
                        size={24}
                        color={AppointmentStatusEnum[appointment?.status] ? convertStatusToColorAppointment(appointment?.status).color : colors.gray.dark02}
                      />

                      <Text
                        test={AppointmentStatusEnum[appointment?.status] ? AppointmentStatusEnum[appointment?.status] : 'Na fila'}
                        color={collapseAppointmentIndexToOpen === appointment?.id ? colors.gray.white : colors.gray.dark01}
                        marginLeft={4}
                        weight="600"
                      />
                    </Styles.HeaderDivSeparatorRight>

                    {collapseAppointmentIndexToOpen === appointment?.id
                      ? (
                        <FiChevronUp
                          size={24}
                          color={collapseAppointmentIndexToOpen === appointment?.id ? colors.gray.white : colors.gray.dark01}
                          style={{ marginLeft: 'auto' }}
                        />
                      )
                      : (
                        <FiChevronDown
                          size={24}
                          color={collapseAppointmentIndexToOpen === appointment?.id ? colors.gray.white : colors.gray.dark01}
                          style={{ marginLeft: 'auto' }}
                        />
                      )}
                  </Styles.CardHeader>

                  <Styles.CardContent
                    selected={collapseAppointmentIndexToOpen === appointment?.id}
                  >
                    <Collapse isOpened={collapseAppointmentIndexToOpen === appointment?.id}>
                      <Styles.CollapseContent>
                        {appointment.statusStepsArray.filter(
                          (status) => status.value !== null,
                        ).length >= 1 && (
                            <Styles.CardStatus>
                              <ProgressStep appointmentStatus={appointment.statusStepsArray} />
                            </Styles.CardStatus>
                          )}

                        <Text
                          test={
                            `${appointment?.pet?.specie?.name} |
                          ${appointment?.pet?.breed?.name || 'Sem raça'} |
                          ${appointment?.pet?.breed?.size ? `Porte ${appointment?.pet?.breed?.size} |` : ''}
                          ${appointment?.pet?.gender === 'M' ? 'Macho' : 'Fêmea'} |
                          ${validateDateFormat(appointment.pet) ? (
                              formatDistanceToNow(
                                parseISO(appointment.pet.birthday!), { locale: ptBR },
                              ).replace('cerca de', '') || 'Sem idade'
                            ) : (
                              'Sem idade'
                            )}`
                          }
                          color={colors.gray.dark02}
                          weight="600"
                          marginBottom={12}
                          marginTop={8}
                        />

                        <Text
                          test={`
                         ${appointment?.address?.street}
                         , ${appointment?.address?.number}
                         ${appointment.address.surname !== undefined && appointment.address.surname !== null ? `- ${appointment?.address?.surname}` : ''}
                         - ${appointment?.address?.neighborhood}
                       `}
                          color={colors.gray.dark02}
                          weight="600"
                          marginBottom={12}
                        />

                        <Text
                          test={`
                          Serviço escolhido - ${appointment?.service?.name}
                        `}
                          color={colors.gray.dark02}
                          weight="600"
                          marginBottom={12}
                        />

                        {appointment?.additionals?.map((additional) => (
                          <Text
                            key={additional.id}
                            test={`+ ${additional?.name}`}
                            color={colors.suport.green}
                            weight="600"
                            marginBottom={10}
                            size={14}
                          />
                        ))}

                        {appointment.observations && (
                          <Text
                            test={`Observações: ${appointment?.observations}`}
                            color={colors.gray.dark02}
                            weight="600"
                            marginBottom={12}
                            marginTop={12}
                            size={14}
                          />
                        )}

                        {appointment.observation_image_url && (
                          <Styles.ImageDiv>
                            <img src={appointment.observation_image_url} alt="Observação imagem" />
                          </Styles.ImageDiv>
                        )}

                        {/* source={item.pet?.avatar_url ? { uri: item.pet.avatar_url } : (item.pet.specie.name === 'cachorro' ? dogProfile : catProfile)} */}
                      </Styles.CollapseContent>
                    </Collapse>
                  </Styles.CardContent>
                </Styles.Card>
              </>
            ))}
          </Styles.ScrollView>
        ) : (
          <Styles.LoadingDiv>
            <FaSpinner size={40} />
          </Styles.LoadingDiv>
        )}
      </Styles.Content>
    </Modal>
  );
};

export default ModalTruckDetails;
