import React, { useEffect, useState } from 'react';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { useAuth } from '../../hooks/Auth';

import { Container, ButtonClose } from './styles';

const RefCodeAlert: React.FC = () => {
  const { customer } = useAuth();
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  }, [customer]);

  if (customer?.ref_code && show) {
    return (
      <Container>
        <a
          href={`https://wa.me/?text=${process.env.REACT_APP_APP_URL}/signup?ref_code=${customer.ref_code}`}
          target="_blank"
          rel="noopener noreferrer"
          data-action="share/whatsapp/share"
        >
          INDIQUE A ZAZUU.PET PARA SEUS AMIGOS: DÊ 10% OFF E GANHE R$10
        </a>
        <ButtonClose onClick={() => setShow(false)}>
          <AiOutlineCloseSquare />
        </ButtonClose>
      </Container>
    );
  }

  return null;
};

export default RefCodeAlert;
