import styled from 'styled-components';
import bgWaves from '@monorepo-zazuu/shared/assets/images/bg-waves.png';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  background-color: ${colors.gray.light02};
  background: url(${bgWaves}) repeat-x center bottom;
  background-size: 100vw 50vh;


  @media (max-width: 650px) {
    flex-direction: column;
  }

  @media (max-width: 500px) {
    padding-bottom: calc((100vw / 5) + 4rem);
  }

`;

export const HeaderMobile = styled.div`
  display: none;

  @media (max-width: 650px) {
    display: flex;

    width: 90%;
    align-self: center;

    justify-content: center;
    align-items: center;

    margin-top: 3rem;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  @media (max-width: 650px) {
    display: none;
  }

  a {
    text-decoration: none;

    margin-top: 2rem;

    font-family: 'Open Sans';
    font-size: 2.1rem;
    line-height: 120%;
    color: ${colors.secondary.default};

    svg {
      font-size: 2rem;
      margin-right: 1rem;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex: 1;

  @media (max-width: 650px) {
    display: none;
  }

  img {
    height: 6rem;
    margin-top: 0.6rem;
  }
`;

export const CenterSide = styled.div`
  flex: 4;
  display: flex;

  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  background: ${colors.gray.white};
  border-radius: 20px;

  padding: 3rem 3.5rem;
  margin: 3rem;
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 3.7rem;
  line-height: 120%;

  color: ${colors.gray.dark02};
`;

export const SubTitle = styled.p`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;

  color: ${colors.gray.dark01};

  margin: 2rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  & + div {
    margin-top: 2rem;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const LeftFields = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-right: 2rem;

  @media (max-width: 650px) {
    margin-right: 0;
  }
`;

export const RightFields = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-left: 1rem;

  @media (max-width: 650px) {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

export const TitleFields = styled.strong`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 150%;

  color: ${colors.primary.default};

  & > a {
    color: ${colors.secondary.default};
    text-decoration: none;
    font-size: 1.6rem;
  }
  @media (max-width: 650px) {
    font-size: 2rem;
  }
`;

export const InputWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
  border-radius: 5rem;

  align-items: center;

  svg {
    font-size: 2rem;
    color: ${colors.gray.dark01};
  }
`;

export const CepContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 5rem;

  margin-top: 2rem;

  height: 5.6rem;

  background: ${colors.primary.default};

  font-family: 'Open Sans';
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.light02};
  transition: background 0.2s;

  :hover {
    background: ${colors.primary.default};
  }

  svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: 650px) {
    margin-top: 3rem;
    height: 7.6rem;
  }
`;
