import styled from 'styled-components';
import CsvDownload from 'react-json-to-csv';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  overflow: unset;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 120%;
  color: ${colors.gray.dark01};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CsvDownloadStyled = styled(CsvDownload)`
  display: flex;
  width: 170px;
  padding: 1rem 2rem;
  color: ${colors.gray.white};
  background: ${colors.secondary.default};
  margin-top: 2rem;
  font-weight: bold;
  text-align: center;
`;
