import colors from '@monorepo-zazuu/shared/constants/colors';
import React from 'react';
import Button from '../../../../components/web/Button';
import Modal from '../../../../components/Modal';
import Text from '../../../../components/web/Text';
import Input from '../../../../components/web/Input';
import Select from '../../../../components/web/Select';
import * as Styles from './styles';
import { useState } from 'react';
import IItem from '@monorepo-zazuu/shared/interfaces/IItem';
import { useEffect } from 'react';
import client from '../../../../services/api-graphql';
import { gql } from '@apollo/client';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { Form, Formik } from 'formik';

interface IProps {
  visible: boolean;
  closeModal: () => void;
  handleUpdateGroup: (data: any) => void;
}

const ModalAddGroup: React.FC<IProps> = ({visible, closeModal, handleUpdateGroup}) => {
  const [items, setItems] = useState<IItem[]>([]);
  const itemsSelect = useMemo(() => items.map(item => ({value: item.id, label: item.name})), [items]);

  const loadItems = useCallback(async () => {
    const results = await client.query({
      query: gql`
        query {
          items {
            id
            name
            description
          }
        }
      `
    });
    setItems(results.data.items);
  }, [])

  useEffect(() => {
    loadItems();
  }, [loadItems])

  const handleSubmit = useCallback((values: any) => {
    handleUpdateGroup(values);
    closeModal();
  }, [closeModal, handleUpdateGroup]);

  return (
    <Modal visible={visible} closeModal={closeModal}>
      <Styles.Content>
        <Formik
          initialValues={{
            name: '',
            is_default: {
              value: true,
              label: 'Obrigatório'
            },
            items: []
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {props => (
            <Form>
              <Text
              test="Grupo de Complementos"
              size={24}
              color={colors.primary.default}
              align="left"
              weight="700"
              marginBottom={8}
            />
            <Styles.Row>
              <Styles.Column>
                <Text
                  test="Nome do Grupo"
                  size={18}
                  color={colors.primary.default}
                  align="left"
                  weight="700"
                  marginBottom={8}
                />
                <Input
                  value={props.values.name}
                  onChange={e => props.setFieldValue('name', e.target.value)}
                  placeholder="digite o nome do grupo"
                />
              </Styles.Column>
              <Styles.Column style={{marginLeft: 16}}>
                <Text
                  test="Tipos de Itens"
                  size={18}
                  color={colors.primary.default}
                  align="left"
                  weight="700"
                  marginBottom={8}
                />
                <Select
                  value={props.values.is_default}
                  onChange={value => props.setFieldValue('is_default', value)}
                  options={[
                    {
                      value: true,
                      label: 'Obrigatório'
                    },
                    {
                      value: false,
                      label: 'Opcional'
                    }
                  ]}
                  placeholder="obrigatório ou opcional"
                />
              </Styles.Column>
            </Styles.Row>
            <Styles.Divider />
            <Text
              test="Complementos"
              size={24}
              color={colors.primary.default}
              align="left"
              weight="700"
              marginBottom={8}
            />
            {props.values.items.map((item: any, index) => (
              <Styles.Row key={item.value} style={{marginTop: 8}}>
                <Styles.Column>
                  <Text
                    test="Nome do item"
                    size={18}
                    color={colors.primary.default}
                    align="left"
                    weight="700"
                    marginBottom={8}
                  />
                  <Select
                    value={props.values.items[index]}
                    onChange={value => props.setFieldValue(`items[${index}]`, value)}
                    placeholder="selecione o item"
                    options={itemsSelect}
                  />
                </Styles.Column>
              </Styles.Row>
            ))}
            <Button
              behavior="secondary"
              text="Adicionar item"
              styleContainer={{marginTop: 16}}
              onClick={() => props.setFieldValue('items', [...props.values.items, {}])}
            />
            <Styles.Row style={{marginTop: 32, justifyContent: 'center'}}>
              <Button
                behavior="secondary"
                text="Cancelar"
                onClick={closeModal}
              />
              <Button
                behavior="primary"
                text="Salvar"
                styleContainer={{marginLeft: 8}}
                onClick={props.submitForm}
              />
            </Styles.Row>
          </Form>
          )}
        </Formik>
      </Styles.Content>
    </Modal>
  )
}

export default ModalAddGroup;
