import colors from '@monorepo-zazuu/shared/constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Card = styled.div`
  display: flex;
  max-width: 360px;
  flex-direction: column;
  background-color: ${colors.gray.white};
  padding: 2rem 4rem;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 2px solid ${colors.gray.white};
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);

  @media (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 160px;
`;

export const Photo = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  resize: contain;
`;

export const Tag = styled.div`
  display: flex;
  background-color: ${colors.gray.light02};
  border-radius: 5px;
  padding: 5px 15px;

  margin: 1rem 1rem 0 0;

  :first-of-type {
    margin-left: 0;
  }
`;

export const Session = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.gray.white};
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 2rem;
  border: 2px solid ${colors.gray.white};
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.2);
  z-index: 0;

  :hover {
    border: 2px solid ${colors.secondary.default};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DayContainer = styled.button<{selected: boolean}>`
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.selected ? colors.secondary.default : colors.gray.white)};
  border: 1px solid ${colors.secondary.default};
  border-radius: 1rem;
  margin: 0 0.5rem;

  & > * {
    color: ${(props) => (props.selected ? colors.gray.white : colors.gray.dark01)};
  }

  :hover {
    background-color: ${colors.secondary.default};

    & > * {
      color: ${colors.gray.white};
    }
  }
`;

export const RowHoursLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-top: 2rem;
`;

export const CepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid ${colors.gray.dark01};
  border-radius: 1rem;
  margin-right: 1rem;
`;

export const RemoveButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
`;
