import IPet from '@monorepo-zazuu/shared/interfaces/IPet';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { AxiosResponse } from 'axios';
import { sub } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GiFemale, GiMale } from 'react-icons/gi';
import { toast } from 'react-toastify';
import api from '../../services/api';
import AsyncSelect from '../AsyncSelect';
import InputImage from '../InputImage';
import InputRef from '../InputRef';
import {
  Button,
  Content,
  Modal,
  ModalContent,
  OptionsButton,
  OptionsWrapper,
  Row,
  Step,
  StepLine,
  Steps,
  Title
} from './styles';

interface IFormData {
  name: string;
  breed_id: string;
  weight: string;
  age: { years: string; months: string };
  food: string;
  birthday: string;
  avatar_url: string;
}

const ModalAddPet: React.FC<{
  closeModal(): void;
  visible: boolean;
  onSaveNewPet(pet: IPet): void;
  pet?: IPet | undefined;
}> = ({ closeModal, visible, onSaveNewPet, pet }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormHandles>(null);

  const [currentStep, setCurrentStep] = useState(1);

  const [gender, setGender] = useState(pet ? pet.gender : 'M');
  const [selectedSpecie, setSelectedSpecie] = useState<'gato' | 'cachorro'>(
    pet ? (pet.specie.name.toLowerCase() as 'gato' | 'cachorro') : 'cachorro',
  );
  const [hasBreed, setHasBreed] = useState(pet ? !!pet?.breed : true);

  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (visible) {
          closeModal();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef, closeModal, visible, pet]);

  const handleNextStep = (): void => {
    const name = formRef?.current?.getFieldValue('name');
    const weight = formRef?.current?.getFieldValue('weight');
    const breed = formRef?.current?.getFieldValue('breed_id');

    if (name && weight && ((hasBreed && breed) || !hasBreed)) {
      setCurrentStep(old => old + 1);
    } else {
      if (!name) {
        formRef.current?.setFieldError('name', 'insira um nome');
      }

      if (hasBreed && !breed) {
        formRef.current?.setFieldError('breed_id', 'insira uma raca');
      }

      if (!weight) {
        formRef.current?.setFieldError('weight', 'insira um peso');
      }
    }
  };

  const handleChangePage = (page: number): void => {
    const name = formRef?.current?.getFieldValue('name');
    const weight = formRef?.current?.getFieldValue('weight');
    if (name && weight) {
      setCurrentStep(page);
    }
  };

  const onSearchingBreed = useCallback(
    async (word: string) => {
      const response = await api.get(
        `/breeds?specie=${selectedSpecie}&name=${word}`,
      );
      const options = response.data.map(
        (op: { id: string; name: string; size: string }) => ({
          value: op.id,
          label: op.name,
        }),
      );
      return [
        {
          value:
            selectedSpecie === 'gato'
              ? '00000000-0000-0000-0000-000000000000'
              : '11111111-1111-1111-1111-111111111111',
          label: 'Outros',
        },
        ...options,
      ];
    },
    [selectedSpecie],
  );

  useEffect(() => {
    formRef.current?.setFieldValue('breed_id', '');
  }, [selectedSpecie, hasBreed]);

  const onSearchingBreedDebounced = AwesomeDebouncePromise(
    onSearchingBreed,
    1000,
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      if (!data.birthday && !data.age.years && !data.age.months) {
        toast('Idade obrigatória', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
        return;
      }

      const dataForm = new FormData();

      if (pet) {
        dataForm.append('pet_id', pet.id);
      }

      if (data.avatar_url !== pet?.avatar_url && data.avatar_url) {
        dataForm.append('avatar', data.avatar_url);
      }

      dataForm.append('name', data.name);
      dataForm.append('specie', selectedSpecie);

      if (hasBreed) {
        dataForm.append('breed_id', data.breed_id);
      }

      dataForm.append('weight', data.weight.replace(',', '.'));
      dataForm.append('gender', gender);

      if (data.birthday) {
        dataForm.append('birthday', data.birthday);
      } else {
        const newDate = sub(new Date(), {
          years: Number(data.age.years || 0),
          months: Number(data.age.months || 0),
          days: 0,
        });

        dataForm.append('birthday', String(newDate));
      }

      dataForm.append('food', data.food);

      try {
        let response = undefined as AxiosResponse<IPet> | undefined;
        if (pet) {
          response = await api.put('/pets', dataForm);
        } else {
          response = await api.post('/pets', dataForm);
        }

        if (response) {
          onSaveNewPet({
            ...response.data,
          });

          toast('Pet salvo com sucesso', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: 'success',
          });
        }

        closeModal();
      } catch (error) {
        toast(error.response?.data?.message || 'Erro no servidor', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
      }
    },
    [gender, selectedSpecie, hasBreed, closeModal, onSaveNewPet, pet],
  );

  return (
    <Modal visible={visible}>
      <ModalContent ref={modalRef}>
        <Steps>
          <Step
            type="button"
            selected={currentStep === 1}
            onClick={() => handleChangePage(1)}
          >
            1
          </Step>
          <StepLine />
          <Step
            type="button"
            selected={currentStep === 2}
            onClick={() => handleChangePage(2)}
          >
            2
          </Step>
        </Steps>
        <Form onSubmit={handleSubmit} ref={formRef} initialData={pet}>
          <Content visible={currentStep === 1}>
            <Title>Qual o nome do seu Pet?</Title>
            <Row>
              <InputRef name="name" placeholder="nome" />
              <OptionsWrapper style={{ width: '60%', marginLeft: '1rem' }}>
                <OptionsButton
                  type="button"
                  selected={gender === 'M'}
                  onClick={() => setGender('M')}
                >
                  <GiMale />
                </OptionsButton>
                <OptionsButton
                  type="button"
                  selected={gender === 'F'}
                  onClick={() => setGender('F')}
                >
                  <GiFemale />
                </OptionsButton>
              </OptionsWrapper>
            </Row>
            <Row>
              <div style={{ width: '100%', marginTop: '4rem' }}>
                <Title>Qual espécie?</Title>
                <OptionsWrapper>
                  <OptionsButton
                    type="button"
                    selected={selectedSpecie === 'cachorro'}
                    onClick={() => setSelectedSpecie('cachorro')}
                  >
                    cachorro
                  </OptionsButton>
                  <OptionsButton
                    type="button"
                    selected={selectedSpecie === 'gato'}
                    onClick={() => setSelectedSpecie('gato')}
                  >
                    gato
                  </OptionsButton>
                </OptionsWrapper>
              </div>
              <div
                style={{ width: '100%', marginLeft: '1rem', marginTop: '4rem' }}
              >
                <Title>Possui raça?</Title>
                <OptionsWrapper>
                  <OptionsButton
                    type="button"
                    selected={hasBreed === true}
                    onClick={() => setHasBreed(true)}
                  >
                    sim
                  </OptionsButton>
                  <OptionsButton
                    type="button"
                    selected={hasBreed === false}
                    onClick={() => setHasBreed(false)}
                  >
                    não
                  </OptionsButton>
                </OptionsWrapper>
              </div>
            </Row>
            <Title style={{ marginTop: '4rem' }}>Qual raça?</Title>
            <AsyncSelect
              disabled={!hasBreed}
              name="breed_id"
              defaultValue={
                pet?.breed
                  ? {
                      value: pet?.breed.id,
                      label: pet?.breed.name,
                    }
                  : undefined
              }
              placeholder="digite uma raça"
              loadOptions={text => onSearchingBreedDebounced(text)}
            />
            <Title style={{ marginTop: '4rem' }}>Qual o peso?</Title>
            <InputRef name="weight" placeholder="peso aproximado" />
          </Content>
          <Content visible={currentStep === 2}>
            <Title>Idade (aproximada)</Title>
            <Row style={{ margin: 0 }}>
              <InputRef name="age.years" placeholder="anos" />
              <InputRef
                name="age.months"
                placeholder="meses"
                containerStyle={{ marginLeft: '1rem' }}
              />
            </Row>
            <Title style={{ marginTop: '3rem' }}>
              Data de aniversário (opcional)
            </Title>
            <InputRef
              name="birthday"
              placeholder="dia / mês / ano"
              type="date"
              style={{ paddingRight: '2rem', width: '100%' }}
            />
            <Title style={{ marginTop: '3rem' }}>
              Principal marca de ração utilizada? (opcional)
            </Title>
            <InputRef name="food" placeholder="marca da ração" />
            <Row style={{ marginTop: '3rem' }}>
              <Title>Pronto! Agora adicione uma fotinha do seu pet</Title>
              <InputImage name="avatar_url" />
            </Row>
          </Content>
          <Button type="button" onClick={() => formRef.current?.submitForm()} visible={currentStep === 2}>
            {pet ? 'editar pet' : 'salvar pet'}
          </Button>

          <Button
            type="button"
            onClick={handleNextStep}
            visible={currentStep < 2}
          >
            avançar
          </Button>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddPet;
