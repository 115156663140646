const ceps = [
  '012',
  '014',
  '040',
  '043',
  '045',
  '050',
  '054',
  '064',
  '01211',
  '02452',
  '02030',
  '02022',
  '02021',
  '02019',
  '02018',
  '02017',
  '02016',
  '02013',
  '02012',
  '02011',
  '02009',
  '01333',
  '01332',
  '01331',
  '01330',
  '01329',
  '01328',
  '01327',
  '01323',
  '01222',
  '01321',
  '01313',
  '01312',
  '01311',
  '01310',
  '01309',
  '01308',
  '01307',
  '01306',
  '01304',
  '01303',
  '01302',
  '01301',
  '01244',
  '01243',
  '01240',
  '01239',
  '01238',
  '01234',
  '01233',
  '01232',
  '01231',
  '01230',
  '01229',
  '01228',
  '01225',
  '01224',
  '01217',
  '01156',
  '01155',
  '01154',
  '01151',
  '01140',
  '01138',
  '01135',
  '01155',
  '01151',
  '01326',
  '04602',
  '01247',
  '01326',
  '01332',
  '05303',
  '05311',
  '05511',
  '05611',
  '05516',
  '05603',
  '01333',
  '05539',
  '04216',
  '04522',
  '04521',
  '04530',
  '04748',
  '04754',
  '04757',
  '04710',
  '04622',
  '04624',
  '04601',
  '04616',
  '04609',
  '04613',
  '04635',
  '05674',
  '04126',
  '04116',
  '04726',
  '04725',
  '04724',
  '04727',
  '04728',
  '05585',
  '05603',
  '05604',
  '05605',
  '05606',
  '05607',
  '05608',
  '05609',
  '05610',
  '05611',
  '05612',
  '05613',
  '05613',
  '05614',
  '05615',
  '05616',
  '05617',
  '05618',
  '05621',
  '05622',
  '05638',
  '05650',
  '05651',
  '05652',
  '05653',
  '05654',
  '05658',
  '05670',
  '05679',
  '05671',
  '05672',
  '05673',
  '05674',
  '05675',
  '05676',
  '05678',
  '05683',
  '05684',
  '05685',
  '05686',
  '05687',
  '05726',
  '05727',
  '05711',
  '05641',
  '05518',
  '05314',
  '05315',
  '05319',
  '04634',
  '04619',
  '04721',
  '02036',
  '04605',
  '40734',
  '05512',
  '05511',
  '05510',
  '05509',
  '05507',
  '05506',
  '05505',
  '05503',
  '05502',
  '05501',
  '04696',
  '04672',
  '04671',
  '04663',
  '04662',
  '04648',
  '04647',
  '04646',
  '04645',
  '04644',
  '04643',
  '04642',
  '04641',
  '04640',
  '04639',
  '04638',
  '04637',
  '04636',
  '04627',
  '04626',
  '04624',
  '04623',
  '04622',
  '04605',
  '04604',
  '04603',
  '04532',
  '02728',
  '13285',
  '13281',
  '01533',
  '04610',
  '05688',
  '12918',
  '05642',
  '02733',
  '04747',
  '047100'
];

export default ceps;
