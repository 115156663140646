import IProfessional from '@monorepo-zazuu/shared/interfaces/IProfessional';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import gql from 'graphql-tag';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import InputRef from '../../components/InputRef';
import client from '../../services/api-graphql';
import ModalAddProfessional from './ModalAddProfessional';
import {
  Card,
  CardAppointment,
  Container, Group, Groups, ProfessionalsList, Row, Title,
} from './styles';

interface IDataReports {
  date: string;
}

interface IAppointments {
  id: string;
  customer: {
    id: string;
    user: {
      id: string;
      name: string;
    };
  };
  pet: {
    id: string;
    name: string;
  };
  service: {
    id: string;
    name: string;
  };
  address: {
    cep?: string;
    state?: string;
    city?: string;
    neighborhood?: string;
    street?: string;
    number?: string;
    complement?: string;
    surname?: string;
  };
  period: string;
  professional?: {
    id: string;
    user: {
      id: string;
      name: string;
    };
  };
  hour: string;
}

interface IGroups {
  truck_id: number;
  appointments: IAppointments[];
}

const GeneratorRoutes: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<IProfessional[]>([]);
  const [professionalsSelectedIds, setProfessionalsSelectedIds] = useState<string[]>([]);
  const [modalAddProfessionalToGroup, setModalAddProfessionalToGroup] = useState(false);
  const [groupSelected, setGroupSelected] = useState(-1);
  const [groups, setGroups] = useState<IGroups[]>([]);

  useEffect(() => {
    const loadProfessionals = async () => {
      try {
        const results = await client.query({
          query: gql`
            query {
              professionals {
                id
                user {
                  name
                  status
                  roles {
                    name
                  }
                }
              }
            }
          `,
        });

        const filteredProfessionals = results.data.professionals.filter(
          (professional: IProfessional) => professional.user.status === true,
        );

        setProfessionals(filteredProfessionals);
      } catch (error) {
        toast('Erro ao listar profissionais', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        });
      }
    };
    loadProfessionals();
  }, []);

  const handleSubmit = async (data: IDataReports): Promise<void> => {
    if (!data.date) {
      toast('Escolha uma data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'warning',
      });
      return;
    }
    if (professionalsSelectedIds.length === 0) {
      toast('Escolha pelo menos 1 profissional', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      setGroups([]);
      const results = await client.query({
        query: gql`
          query($date: String!, $trucksNumber: Int!) {
            generateRoutes(date: $date, trucks_number: $trucksNumber) {
              truck_id
              appointments {
                id
                period
                hour
                customer {
                  id
                  user {
                    id
                    name
                  }
                }
                pet {
                  id
                  name
                }
                service {
                  id
                  name
                }
                professional {
                  id
                  user {
                    name
                    roles {
                      id
                      name
                    }
                  }
                }
                address {
                  cep
                  state
                  city
                  neighborhood
                  street
                  number
                  complement
                  surname
                }
              }
            }
          }
        `,
        variables: {
          trucksNumber: professionalsSelectedIds.length,
          date: data.date,
        },
      });

      setGroups(results.data.generateRoutes);
      toast('Rotas carregadas', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const erroGRAPHQL = error as any;
      const payloadErro = erroGRAPHQL.graphQLErrors[0];
      toast(payloadErro.message || 'ERRO NO SERVIDOR', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  };

  const handleSelectProfessional = (id: string) => {
    if (professionalsSelectedIds.includes(id)) {
      setProfessionalsSelectedIds((old) => old.filter((o) => o !== id));
    } else {
      setProfessionalsSelectedIds((old) => ([...old, id]));
    }
  };

  const handleAddProfessionalToGroup = useCallback(async (professional: any) => {
    try {
      await client.mutate({
        mutation: gql`
          mutation($appointments: [AppointmentInputType!]!) {
            updateAppointments(appointments: $appointments)
          }
        `,
        variables: {
          appointments: groups[groupSelected].appointments.map((appointment) => ({
            id: appointment.id,
            professional_id: professional.id,
            hour: appointment.hour,
          })),
        },
      });
      const result = [...groups];
      result[groupSelected] = {
        truck_id: result[groupSelected].truck_id,
        appointments: groups[groupSelected].appointments.map((app: IAppointments) => ({
          ...app,
          professional,
        })),
      };
      setGroups(result);
      toast('Profissional vinculado com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'success',
      });
    } catch (error) {
      toast('Erro ao atualizar agendamentos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [groupSelected, groups]);

  return (
    <Container>
      <Title>Gerador de rotas</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <InputRef
            name="date"
            style={{ paddingRight: '2rem' }}
            placeholder="data inicial"
            type="date"
          />
        </Row>
        <ProfessionalsList>
          {professionals.map((professional) => (
            <Card key={professional.id} selected={!!professionalsSelectedIds.includes(professional.id)} type="button" onClick={() => handleSelectProfessional(professional.id)}>
              <div />
              <p>{professional.user.name} - {professional.user.roles.map((r) => r.name)}</p>
            </Card>
          ))}
        </ProfessionalsList>
        <Button
          behavior="primary"
          type="submit"
          text="Gerar"
          style={{ width: '100%' }}
          loading={loading}
        />
      </Form>
      <Groups>
        {groups.map((g, index) => (
          <Group key={index} onClick={() => { setModalAddProfessionalToGroup(true); setGroupSelected(index); }}>
            {g.appointments.map((appointment) => (
              <CardAppointment key={appointment.id}>
                <p>{appointment.customer.user.name}</p>
                <p>{appointment.pet.name}</p>
                <p>{appointment.hour}</p>
                <p>{appointment.service.name}</p>
                <p>{appointment.period}</p>
                <p>{appointment.address.cep}</p>
                <p style={{ marginTop: 16, color: 'red' }}>{appointment?.professional?.user?.name || 'sem profissional adicionado'}</p>
              </CardAppointment>
            ))}
          </Group>
        ))}
      </Groups>
      <ModalAddProfessional
        visible={modalAddProfessionalToGroup}
        handleCloseModal={() => { setModalAddProfessionalToGroup(false); setGroupSelected(-1); }}
        professionals={professionals}
        handleAddProfessional={(professional) => handleAddProfessionalToGroup(professional)}
      />
    </Container>
  );
};

export default GeneratorRoutes;
