import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Block = styled.div`
  background-color: ${colors.gray.white};
  margin-top: 24px;
  padding: 16px 0;

  -webkit-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.4);
  -moz-box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.4);
  box-shadow: 0px 0px 14px 1px rgba(92, 92, 92, 0.4);
`;

export const BlockHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 16px;
`;

export const BlockType = styled.div`
  background-color: ${colors.primary.light};
  padding: 8px 16px;
`;
