import colors from '@monorepo-zazuu/shared/constants/colors';
import ISelectValue from '@monorepo-zazuu/shared/interfaces/ISelectValue';
import React from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import Text from '../../../../components/web/Text';
import * as Styles from './styles';

interface Iprops {
  groups: {
    name: string;
    is_default: ISelectValue;
    items: ISelectValue[];
  }[];
  handleRemoveGroup:(group_id: string) => void;
  handleRemoveItemFromGroup:(item_id: string) => void;
}

const TableGroups: React.FC<Iprops> = ({ groups, handleRemoveGroup, handleRemoveItemFromGroup }) => {
  return (
    <Styles.Container>
      {groups.map(group => (
        <Styles.Table key={group.name}>
          <thead>
          <tr>
            <th style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Text
                test={group.name}
                size={18}
                weight="600"
                color={colors.gray.white}
              />
              <Text
                test={group.is_default ? 'obrigatório' : 'opcional'}
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Styles.Row>
                <div>
                  <button
                    type="button"
                    onClick={() => handleRemoveGroup(group.name)}
                  >
                    <FiEdit color={colors.gray.dark02} />
                  </button>
                </div>
              </Styles.Row>
            </th>
          </tr>
          </thead>
          <tbody>
          {group.items.map((item) => (
            <tr key={String(item.value)}>
              <td>
                <Text
                  test={String(item.label)}
                  size={18}
                  color={colors.gray.dark03}
                />
              </td>
              <td>
                <Styles.Row>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleRemoveItemFromGroup(String(item.value))}
                    >
                      <FiTrash2 color={colors.gray.dark02} />
                    </button>
                  </div>
                </Styles.Row>
              </td>
            </tr>
          ))}
          </tbody>
        </Styles.Table>
      ))}
    </Styles.Container>
  );
}


export default TableGroups;
