import styled from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

export const Content = styled.div`
  text-align: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.primary.default};
  margin-top: 24px;
  margin-bottom: 24px;
`;
